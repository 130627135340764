import React from 'react';
import AuthNav, { drawerWidth } from '../AuthNav';
import Footer from '../Footer';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import GuestNav from "../GuestNav";
import { connect } from 'react-redux'
import clsx from 'clsx';

const propTypes = {
  children: PropTypes.element.isRequired
};

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100vh',
    width: '100%',
  },
  navi: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

const AppLayout = ({ children, classes, free, navi: { open }, ...rest }) => {
  return (
    <div className={classes.root}>
      { free ? <GuestNav /> : <AuthNav />}
      <div className={clsx(classes.main, open && classes.navi)}>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            {children}
          </Container>
        </main>
        <Footer />
      </div>
    </div>
  );
};

AppLayout.propTypes = propTypes;
const mapStateToProps = state => ({
  navi: state.navi
})
const mapDispatchToProps = ({})

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(AppLayout))
