
import { API_BASE } from '../constraints/commons'
import { getMyAxios } from '../helpers/auth'

export const GET_PAYJPS = 'GET_PAYJPS'
export const GET_PAYJP = 'GET_PAYJP'
export const STORE_PAYJP = 'STORE_PAYJP'
export const UPDATE_PAYJP = 'UPDATE_PAYJP'
export const ERROR_PAYJP = 'ERROR_PAYJP'
export const SET_EDIT_PAYJP = 'SET_EDIT_PAYJP'
export const REMOVE_EDIT_PAYJP = 'REMOVE_EDIT_PAYJP'
export const SET_PAYJP_SEARCH = 'SET_PAYJP_SEARCH'

const _getTargets = async values => {
  return await getMyAxios().get(`${API_BASE}/v1/payjp/api`, { params: values })
}

export const setEditTarget = item => {
  return { type: SET_EDIT_PAYJP, item }
}

export const removeEditTarget = () => {
  return { type: REMOVE_EDIT_PAYJP }
}

export const getTargets = values => async dispatch => {
  try {
    const response = await _getTargets(values)
    const { data: { status, info: items, ...paginate } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_PAYJP, error: `payjp API情報一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_PAYJPS, items, paginate })
        break
      default:
        dispatch({ type: ERROR_PAYJP, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_PAYJP, error })
  }
}

export const getTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().get(`${API_BASE}/v1/payjp/api/${id}`)
    const { data: { status, info: item } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_PAYJP, error: `payjp API情報の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_PAYJP, item })
        break
      default:
        dispatch({ type: ERROR_PAYJP, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_PAYJP, error })
  }
}

export const storeTarget = (values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/payjp/api`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_PAYJP, error: `payjp API情報の作成に失敗しました。[${status.code}]` })
        break
      case 102:
        dispatch({ type: ERROR_PAYJP, error: `既にpayjp API情報が登録済みです。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_PAYJP, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_PAYJP, error })
  }
}

export const updateTarget = (id, values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().patch(`${API_BASE}/v1/payjp/api/${id}`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_PAYJP, error: `payjp API情報の更新に失敗しました。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_PAYJP, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_PAYJP, error })
  }
}

export const setSearch = (values) => async dispatch => {
  dispatch({ type: SET_PAYJP_SEARCH, search: values })
}
