
export const defaultLocalization = {
  header: {
    actions: '操作',
  },
  toolbar: {
    searchTooltip: '検索',
      searchPlaceholder: '検索',
  },
  pagination: {
    labelRowsSelect: '行',
      firstAriaLabel: '先頭',
      firstTooltip: '先頭',
      lastAriaLabel: '末尾',
      lastTooltip: '末尾',
      nextAriaLabel: '次ページ',
      nextTooltip: '次ページ',
      previousAriaLabel: '前ページ',
      previousTooltip: '前ページ',
  },
}

export const defaultOption = {
  actionsColumnIndex: -1,
  pageSize: 50,
  pageSizeOptions: [5, 10, 20, 50, 100],
  emptyRowsWhenPaging: false,
  padding: 'dense',
}