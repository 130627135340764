
import { API_BASE } from '../constraints/commons'
import { getMyAxios } from '../helpers/auth'

export const GET_BANKS = 'GET_BANKS'
export const GET_BANK = 'GET_BANK'
export const STORE_BANK = 'STORE_BANK'
export const DELETE_BANK = 'DELETE_BANK'
export const UPDATE_BANK = 'UPDATE_BANK'
export const ERROR_BANK = 'ERROR_BANK'
export const SET_EDIT_BANK = 'SET_EDIT_BANK'
export const REMOVE_EDIT_BANK = 'REMOVE_EDIT_BANK'
export const SET_BANK_SEARCH = 'SET_BANK_SEARCH'
export const GET_BANK_DICTIONARY = 'GET_BANK_DICTIONARY'

export const setEditTarget = item => {
  return { type: SET_EDIT_BANK, item }
}

export const removeEditTarget = () => {
  return { type: REMOVE_EDIT_BANK }
}

const _getTargets = async values => {
  return await getMyAxios().get(`${API_BASE}/v1/bank/account`, { params: values })
}

export const getTargets = values => async dispatch => {
  try {
    const response = await _getTargets(values)
    const { data: { status, info: items, ...paginate } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_BANK, error: `口座情報一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_BANKS, items, paginate })
        break
      default:
        dispatch({ type: ERROR_BANK, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_BANK, error })
  }
}

export const getTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().get(`${API_BASE}/v1/bank/account/${id}`)
    const { data: { status, info: item } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_BANK, error: `口座情報の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_BANK, item })
        break
      default:
        dispatch({ type: ERROR_BANK, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_BANK, error })
  }
}

export const storeTarget = (values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/bank/account`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_BANK, error: `口座情報の登録に失敗しました。[${status.code}]` })
        break
      case 102:
        dispatch({ type: ERROR_BANK, error: `既に口座情報が登録されています。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_BANK, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_BANK, error })
  }
}

export const updateTarget = (id, values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().patch(`${API_BASE}/v1/bank/account/${id}`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_BANK, error: `口座情報の情報更新に失敗しました。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_BANK, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_BANK, error })
  }
}

export const deleteTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().delete(`${API_BASE}/v1/bank/account/${id}`)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_BANK, error: `口座情報の削除に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: DELETE_BANK, id })
        break
      default:
        dispatch({ type: ERROR_BANK, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_BANK, error })
  }
}

export const setSearch = (values) => async dispatch => {
  dispatch({ type: SET_BANK_SEARCH, search: values })
}
