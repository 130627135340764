import React from 'react';
import store from '../../store';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PATH_HOME } from './Path';
import {setError} from "../../actions/error";
import {connect} from "react-redux";
import { getIntendedUrl } from "../../helpers/auth";
import _ from "lodash"

const propTypes = {
  // component: PropTypes.func.isRequired,
  rest: PropTypes.object,
  location: PropTypes.object
};

class GuestRoute extends React.Component {
  componentDidMount() {
    this.props.setError(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.setError(null);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const to = _.isEmpty(getIntendedUrl()) ? PATH_HOME : getIntendedUrl();
    return (
      <Route
        {...rest}
        render={props => {
          const { auth: { authenticated } } = store.getState();
          return !authenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: to,
                state: { from: props.location }
              }}
            />
          );
        }
        }
      />
    );
  }
}

GuestRoute.propTypes = propTypes;
GuestRoute.displayName = 'Guest Route';

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = ({ setError });

export default connect(mapStateToProps, mapDispatchToProps)(GuestRoute);
