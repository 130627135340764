import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiBackdrop from '@material-ui/core/Backdrop'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

export default ({open}) => {
  const classes = useStyles()
  return (
    <MuiBackdrop className={classes.backdrop} open={open}>
      <CircularProgress color="primary" />
    </MuiBackdrop>
  );
}
