
import { API_BASE } from '../constraints/commons'
import { setToken, getToken, getMyAxios } from "../helpers/auth";
import _ from "lodash"
import axios from "axios"

export const SET_PERSONAL_TOKEN = 'SET_PERSONAL_TOKEN';
export const SET_TOKEN = 'SET_TOKEN';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_USER = 'SET_USER';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const AUTH_ERROR = 'AUTH_ERROR';

const ERROR_MESSAGE_UNAUTHENTICATED = 'ログインできませんでした。メールアドレスとパスワードを確認して下さい。'

export const requestSignIn = (values, callback = () => { }) => async dispatch => {
  try {
    const response = await axios.post(`${API_BASE}/v1/login`, values);
    const { data: { status, token = undefined } } = response;
    if (status.code === 0) {
      dispatch({ type: SET_PERSONAL_TOKEN, token })
      setToken(token)
      requestOAuth(values, callback)(dispatch)
    } else if (status.code === 401) {
      dispatch({ type: AUTH_ERROR, error: ERROR_MESSAGE_UNAUTHENTICATED })
    } else {
      dispatch({ type: AUTH_ERROR, error: status.message })
    }
  } catch (error) {
    dispatch({ type: AUTH_ERROR, error })
  }
};

export const requestOAuth = (values, callback) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/oauth/token/grant_type/password`, values);
    const { data: { status, ...data } } = response;
    if (status.code === 0) {
      dispatch({ type: SET_TOKEN, token: data.access_token, data });
      setToken(data.access_token);
      tryAuth(callback)(dispatch)
    } else if (status.code === 401) {
      dispatch({ type: AUTH_ERROR, error: ERROR_MESSAGE_UNAUTHENTICATED })
    } else {
      dispatch({ type: AUTH_ERROR, error: status.message })
    }
  } catch (error) {
    dispatch({ type: AUTH_ERROR, error })
  }
};

export const requestSignOut = callback => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/logout`);
    const { data: { status } } = response;
    if (status.code === 0) {
      dispatch({ type: SIGN_OUT });
      clearAuth(dispatch);
      callback();
    } else {
      dispatch({ type: AUTH_ERROR, error: status.message })
    }
  } catch (error) {
    dispatch({ type: AUTH_ERROR, error })
  }
}

export const setUser = user => {
  return { type: SET_USER, user }
}

export const setAuthenticated = authenticated => {
  return { type: SET_AUTHENTICATED, authenticated }
}

export const setMe = callback => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/user/me`)
    const { data: { status, info: user } } = response;
    if (status.code === 0) {
      dispatch(setUser(user))
      dispatch(setAuthenticated(true))
      callback()
    } else {
      clearAuth(dispatch)
      dispatch({ type: AUTH_ERROR, error: ERROR_MESSAGE_UNAUTHENTICATED })
    }
  } catch (error) {
    clearAuth(dispatch)
    dispatch({ type: AUTH_ERROR, error })
  }
}

export const tryAuth = callback => async dispatch => {
  const token = getToken()
  if (_.isEmpty(token)) {
    // tokenなし
    // 認証情報をすべて削除
    clearAuth(dispatch)
    callback()
  } else {
    // tokenあり
    setToken(token)
    setMe(callback)(dispatch)
  }
}

const clearAuth = dispatch => {
  setToken(null)
  dispatch(setUser(null))
  dispatch(setAuthenticated(false))
}

export const leaveService = (values) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/user/leave`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: AUTH_ERROR, error: `退会に失敗しました。[${status.code}]` })
        break
      case 0:
        clearAuth(dispatch)
        break
      default:
        dispatch({ type: AUTH_ERROR, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: AUTH_ERROR, error })
  }
}