import React from 'react'
import { updateTarget, getTarget } from "../../../../actions/lpmaster"
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import { formRender } from './Store'
import Backdrop from '../../Backdrop';
import { connect } from 'react-redux'
import { isAdmin } from "../../../../helpers/common"
import { PAGE_NAME as PAGE_HOME } from "../../Home"
import { PAGE_NAME as PAGE_PARENT } from "./Index"
import { PATH_LPMASTER, PATH_HOME } from '../../../router/Path'
import { Breadcrumbs } from '@material-ui/core'
import LinkRouter from '../../../router/LinkRouter'

const PAGE_NAME = '更新'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
  leftSpace: {
    paddingLeft: '1em'
  },
})

class Update extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      backdrop: {
        open: false
      }
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
    const { id } = this.props.match.params
    if (id) {
      this.props.getTarget(id)
    }
  }

  async onSubmit(values) {
    const { auth: { user } } = this.props
    const formData = new FormData()
    let keys = [
      'logical_name', 'file'
    ]
    if (isAdmin(user)) {
      keys.push('scope')
    }
    for (const key of keys) {
      if (values[key]) formData.append(key, values[key])
    }
    formData.append('_method', 'patch')
    const { id } = this.props.match.params
    this.setState({ backdrop: { open: true } })
    await this.props.updateTarget(id, formData, () => { this.props.history.push(PATH_LPMASTER) })
  }

  render() {
    const { classes, targets: { item }, auth: { user } } = this.props
    const { backdrop: { open } } = this.state
    if (item) {
      item.scope += ''
    }
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <LinkRouter color="inherit" to={PATH_LPMASTER}>
            {PAGE_PARENT}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Form
          onSubmit={this.onSubmit}
          validate={validate(user)}
          initialValues={item}
          render={formRender(classes, user, true)}
        />
        <Backdrop open={open} />
      </React.Fragment>
    )
  }
}

const validate = user => values => {
  const errors = {}
  const [required] = [true]
  const inputs = [
    { name: 'logical_name', required, max: 250 },
    { name: 'file' },
  ]

  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      default:
        break
    }
  }
  return errors
}

const mapStateToProps = state => ({ targets: state.lpmaster, err: state.error, auth: state.auth })
const mapDispatchToProps = ({ getTarget, updateTarget })

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(Update))
