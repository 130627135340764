import React from 'react'
import { updateTarget, getTarget } from "../../../../actions/oem"

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'

import { connect } from 'react-redux'
import { PAGE_NAME as PAGE_HOME } from "../../Home"
import { PAGE_NAME as PAGE_OEM } from "./Index"
import { PATH_ADMIN_OEM, PATH_HOME } from '../../../router/Path'
import { Breadcrumbs, Grid } from '@material-ui/core'
import LinkRouter from '../../../router/LinkRouter'

const PAGE_NAME = '更新'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
})

class Update extends React.Component {
  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
    const { id } = this.props.match.params
    if (id) {
      this.props.getTarget(id)
    }
  }

  async onSubmit(values) {
    const { id } = this.props.match.params
    await this.props.updateTarget(id, values, () => { this.props.history.push(PATH_ADMIN_OEM) })
  }

  render() {
    const { classes, oem: { item } } = this.props
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <LinkRouter color="inherit" to={PATH_ADMIN_OEM}>
            {PAGE_OEM}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Form
          onSubmit={this.onSubmit}
          validate={validate}
          initialValues={item}
          render={formRender(classes)}
        />
      </React.Fragment>
    )
  }
}

const formRender = classes => ({ form, handleSubmit, pristine, submitting, invalid }) => {
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <Grid container justify="center" className={classes.root} spacing={2}>
        <Grid item container direction="column" xs md={8}>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="name"
              label="管理名"
              name="name"
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="note"
              label="備考"
              id="note"
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="inquiry_email"
              label="問い合わせ用メールアドレス"
              id="inquiry_email"
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="inquiry_email_from_name"
              label="問い合わせ用メールアドレスの送信者名"
              id="inquiry_email_from_name"
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="send_email"
              label="送信用メールアドレス"
              id="send_email"
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="send_email_from_name"
              label="送信用メールアドレスの送信者名"
              id="send_email_from_name"
              disabled
            />
          </Grid>
          <Grid item container justify="flex-end" className={classes.btnRoot} spacing={2}>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                fullWidth
                disabled={pristine || submitting}
                onClick={form.reset}
              >
                リセット
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={pristine || submitting || invalid}
              >
                保存
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const validate = values => {
  const errors = {}
  const [required,] = [true,]
  const inputs = [
    { name: 'name', required, max: 200 },
    { name: 'note', max: 200 },
  ]
  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      default:
        break
    }
  }

  return errors
}

const mapStateToProps = state => ({ oem: state.oem, err: state.error })
const mapDispatchToProps = ({ getTarget, updateTarget })

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withStyles(styles)(Update)
)
