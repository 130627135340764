import {
  GET_LPS as GET_ITEMS,
  GET_LP as GET_ITEM,
  SET_EDIT_LP as SET_EDIT,
  DELETE_LP as DELETE,
  REMOVE_EDIT_LP as REMOVE_EDIT,
  SET_LP_SEARCH as SET_SEARCH,
  STORE_LP as STORE,
  UPDATE_LP as UPDATE,
  GET_LP_DICTIONARY as DICTIONARY,
  ERROR_LP_DETAIL as ERROR_DETAIL,
  ERROR_LP_DETAIL_REMOVE as ERROR_REMOVE,
} from '../actions/lp'

const defaultState = {
  search: {
    sort: [],
    name: '',
    user_id: [],
    lp_master_id: [],
  },
  paginate: {
    limit: 100,
    offset: 0,
    count: 0,
  },
  items: [],
  item: null,
  dictionary: [],
  errors: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EDIT:
      return { ...state, item: action.item };
    case REMOVE_EDIT:
      return { ...state, item: null };
    case GET_ITEMS:
      return { ...state, paginate: action.paginate, items: action.items };
    case GET_ITEM:
      return { ...state, item: action.item };
    case SET_SEARCH:
      const { search: _search } = action
      const search = _search ?? defaultState.search
      return { ...state, search: { ...state.search, ...search } }
    case DELETE:
      return { ...state, items: state.items.filter(v => v.id !== action.id) };
    case DICTIONARY:
      return { ...state, dictionary: action.items };
    case ERROR_DETAIL:
      return { ...state, errors: action.errors }
    case ERROR_REMOVE:
      return { ...state, errors: [] }
    case STORE:
    case UPDATE:
    default:
      return state;
  }
}