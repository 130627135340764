import React from 'react';
import _ from "lodash"
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  getTargets, setEditTarget, setSearch
} from '../../../../actions/oem'
import { connect } from 'react-redux'
import { getUpdatePath, PATH_HOME, PATH_ADMIN_OEM_UPDATE } from "../../../router/Path";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import LinkRouter from "../../../router/LinkRouter";
import Grid from "@material-ui/core/Grid";
import MaterialTable from 'material-table'
import { defaultLocalization, defaultOption } from "../../../../helpers/material-table";
import { PAGE_NAME as PAGE_HOME } from "../../Home";
import { Paper } from '@material-ui/core';
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import Button from '@material-ui/core/Button'
import { Select } from 'mui-rff';
import { MenuItem } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const PAGE_NAME = 'OEM管理';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '70%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(1),
  },
  formPaper: {
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort_num: 1,
    }
  }

  componentDidMount() {
    this.editOnClick = this.editOnClick.bind(this);
    this.materialTableData = this.materialTableData.bind(this);
    this.onSearch = this.onSearch.bind(this)
    this.addSearch = this.addSearch.bind(this)
    this.onSearch(this.props.oem.search);
    defaultOption.search = false
    defaultOption.sorting = false
    defaultOption.draggable = false
  }

  editOnClick(event, rowData) {
    this.props.setEditTarget(rowData);
    this.props.history.push(getUpdatePath(rowData.id, PATH_ADMIN_OEM_UPDATE));
  }

  materialTableData() {
    return this.props.oem.items
  }

  addSearch() {
    this.setState({ sort_num: this.state.sort_num + 1 })
  }

  makeSearchParam(values) {
    const _values = _.cloneDeep(values)
    this.props.setSearch(values)
    const keys = [
      'name', 'web_domain', 'api_domain',
    ]
    for (const key of keys) {
      if (!_values[key]) delete _values[key]
    }
    if (_.isEmpty(_values['sort'])) {
      delete _values['sort']
    } else {
      let s = []
      for (const sort of _values['sort']) {
        if (sort && 'order' in sort && 'key' in sort) {
          s.push(`${sort.key}=${sort.order}`)
        }
      }
      if (s.length) _values['sort'] = s.join(',')
      else delete _values['sort']
    }
    return _values
  }

  onSearch(values) {
    this.props.getTargets(this.makeSearchParam(values))
  }

  render() {
    const { classes, oem: { search } } = this.props;
    const { sort_num } = this.state;
    const reset = () => {
      this.props.setSearch(null)
      this.setState({ sort_num: 1 })
    }
    const actions = [
      {
        icon: 'edit',
        tooltip: '編集',
        onClick: this.editOnClick
      },
    ]
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Grid container justify="center" direction="column" className={classes.root}>
          <Grid item xs>
            <Form
              onSubmit={this.onSearch}
              initialValues={search}
              validate={validate}
              render={formRender(classes, reset, sort_num, this.addSearch)}
            />
          </Grid>
        </Grid>
        <MaterialTable
          columns={[
            { title: 'ID', field: 'id' },
            { title: '管理名', field: 'name' },
            { title: 'サイト名', field: 'site_name' },
            { title: 'WEBドメイン', field: 'web_domain' },
            { title: '備考', field: 'note' },
          ]}
          components={{
            Container: props => {
              return (
                <Paper {...props} elevation={0} variant="outlined" />
              )
            }
          }}
          data={this.materialTableData()}
          title='OEM一覧'
          actions={actions}
          options={{
            ...defaultOption,
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          }}

          localization={defaultLocalization}
        />
      </React.Fragment>
    );
  }
}

const formRender = (classes, reset, sort_num, add) => ({ handleSubmit, submitting, invalid }) => {
  return (
    <Paper className={classes.formPaper} elevation={0} variant="outlined">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>検索条件</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form method="post" className={classes.form} onSubmit={handleSubmit}>
            <Grid item container direction="column" spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="name"
                    label="管理名（部分一致）"
                    name="name"
                  />
                </Grid>
              </Grid>
              {
                _.map([...Array(sort_num).keys()].map(i => ++i), item => {
                  return (
                    <Grid item container spacing={2} key={`sort${item}`} alignItems="center">
                      <Grid item xs>
                        <Select name={`sort[${item}][key]`} label={`ソート条件${item}:列`} formControlProps={{ margin: 'dense', variant: "outlined" }}>
                          {
                            [{ name: 'ID', val: 'id' },
                            { name: '管理名', val: 'name' },
                            { name: 'WEBドメイン', val: 'web_domain' },
                            { name: 'APIドメイン', val: 'api_domain' },
                            { name: '備考', val: 'note' },
                            ].map((row, i) => (
                              <MenuItem value={row.val} key={`col${item}${i}`}>{row.name}</MenuItem>
                            ))
                          }
                        </Select>
                      </Grid>
                      <Grid item xs>
                        <Select name={`sort[${item}][order]`} label={`ソート条件${item}:並び順`} formControlProps={{ margin: 'dense', variant: "outlined" }}>
                          <MenuItem value="asc">昇順</MenuItem>
                          <MenuItem value="desc">降順</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs>
                        {
                          (item === 1 && sort_num < 5) &&
                          <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            onClick={add}
                          >ソート追加</Button>
                        }
                      </Grid>
                    </Grid>
                  )
                })
              }
              <Grid item container justify="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={submitting}
                    onClick={reset}
                  >
                    リセット
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={submitting || invalid}
                  >
                    検索
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}

const validate = values => {
  const errors = {}
  const inputs = [
    { name: 'name', max: 200 },
  ]

  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      default:
        break
    }
  }

  return errors
}


const mapStateToProps = state => ({ oem: state.oem, auth: state.auth });
const mapDispatchToProps = ({ getTargets, setEditTarget, setSearch });

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withStyles(styles)(Index)
);
