import {
  SET_PERSONAL_TOKEN,
  SET_TOKEN,
  SIGN_OUT,
  SET_USER,
  SET_AUTHENTICATED,
} from '../actions/auth'

import {setIntendedUrl} from '../helpers/auth'

const initialState = {
  personalToken: null,
  token: null,
  authenticated: false,
  user: null,
}

export default (auth = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return Object.assign({}, auth, { authenticated: action.authenticated });
    case SET_USER:
      return Object.assign({}, auth, { user: action.user });
    case SET_PERSONAL_TOKEN:
      return Object.assign({}, auth, { personalToken: action.token });
    case SET_TOKEN:
      return Object.assign({}, auth, { token: action.token });
    case SIGN_OUT:
      setIntendedUrl(null)
      return Object.assign({}, auth, initialState);
    default:
      return auth;
  }
}