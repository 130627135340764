import React from 'react'
import { updateTarget, getTarget } from "../../../actions/mailtemplate"
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import _ from 'lodash'
import { connect } from 'react-redux'
import { sleep } from "../../../helpers/common"
import { PAGE_NAME as PAGE_HOME } from "../Home"
import { PAGE_NAME as PAGE_PARENT } from "./Index"
import { validate, formRender } from "./Store"
import { PATH_MAIL_TEMPLATE, PATH_HOME } from '../../router/Path'
import { Breadcrumbs } from '@material-ui/core'
import LinkRouter from '../../router/LinkRouter'

const PAGE_NAME = '更新'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
})

class Update extends React.Component {
  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
    const { id } = this.props.match.params
    if (id) {
      this.props.getTarget(id)
    }
  }

  async onSubmit(values) {
    const { id } = this.props.match.params
    const _values = {
      name: '',
      from_name: '',
      from_address: '',
      subject: '',
      contents: '',
      ...values
    }
    await this.props.updateTarget(id, _values, () => { this.props.history.push(PATH_MAIL_TEMPLATE) })
    await sleep(1000)
  }

  render() {
    const { classes, targets: { item } } = this.props
    const _item = _.cloneDeep(item)
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <LinkRouter color="inherit" to={PATH_MAIL_TEMPLATE}>
            {PAGE_PARENT}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Form
          onSubmit={this.onSubmit}
          validate={validate()}
          initialValues={_item}
          render={formRender(classes, true)}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  targets: state.mailtemplate, auth: state.auth
})
const mapDispatchToProps = ({
  getTarget, updateTarget
})

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(Update))
