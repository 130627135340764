import React from 'react'
import { verifyEmail, sendVerifyEmail } from "../../../actions/user"

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import MailIcon from '@material-ui/icons/Mail';
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import { connect } from 'react-redux'
import _ from "lodash"
import { sleep } from "../../../helpers/common"

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
  reset: {
    margin: theme.spacing(3, 0, 2),
  },
})

class EmailVerify extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      success: false,
      mail: {
        sending: false,
        message: '',
      },
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.verifyEmail = this.verifyEmail.bind(this)
    this.sendVerifyEmail = this.sendVerifyEmail.bind(this)
    const { auth: { user: { email_verified_at } } } = this.props
    if (_.isEmpty(email_verified_at)) this.verifyEmail()
  }

  async verifyEmail() {
    const { token } = this.props.match.params
    await this.props.verifyEmail({ token }, (success) => { this.setState({ loading: false, success }) })
    await sleep(1000)
  }

  async sendVerifyEmail() {
    const { auth: { user: { id } } } = this.props
    this.setState({ mail: { sending: true, message: '送信中です。' } })
    await this.props.sendVerifyEmail(id, () => { this.setState({ mail: { sending: true, message: '送信しました。' } }) })
    await sleep(3000)
    this.setState({ mail: { sending: false, message: '' } })
  }

  render() {
    const { classes, auth: { user: { email_verified_at } } } = this.props
    const { loading, success, mail: { sending, message } } = this.state
    const verified = !_.isEmpty(email_verified_at)
    return (
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <MailIcon />
        </Avatar>
        <Typography component="div" variant="h5">
          メールアドレス確認
        </Typography>
        {
          (!verified && loading) &&
          <CircularProgress />
        }
        {
          (verified || success) &&
          <Typography>
            認証に成功しました。
          </Typography>
        }
        <Typography>
          {message}
        </Typography>
        <Button
          type="button"
          variant="text"
          onClick={this.sendVerifyEmail}
          disabled={sending}
        >
          メールを再送信する
      </Button>
      </div>
    )
  }
}

const mapStateToProps = state => ({ auth: state.auth, err: state.error })
const mapDispatchToProps = ({ verifyEmail, sendVerifyEmail })

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(EmailVerify))
