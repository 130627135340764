import {
  GET_MAIL_TEMPLATES as GET_ITEMS,
  GET_MAIL_TEMPLATE as GET_ITEM,
  SET_EDIT_MAIL_TEMPLATE as SET_EDIT,
  DELETE_MAIL_TEMPLATE as DELETE,
  REMOVE_EDIT_MAIL_TEMPLATE as REMOVE_EDIT,
  SET_MAIL_TEMPLATE_SEARCH as SET_SEARCH,
  STORE_MAIL_TEMPLATE as STORE,
  UPDATE_MAIL_TEMPLATE as UPDATE,
  GET_MAIL_TEMPLATE_DICTIONARY as DICTIONARY,
} from '../actions/mailtemplate'

const defaultState = {
  search: {
    sort: [],
    user_id: [],
  },
  paginate: {
    limit: 100,
    offset: 0,
    count: 0,
  },
  items: [],
  item: null,
  dictionary: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EDIT:
      return { ...state, item: action.item };
    case REMOVE_EDIT:
      return { ...state, item: null };
    case GET_ITEMS:
      return { ...state, paginate: action.paginate, items: action.items };
    case GET_ITEM:
      return { ...state, item: action.item };
    case SET_SEARCH:
      const { search: _search } = action
      const search = _search ?? defaultState.search
      return { ...state, search: { ...state.search, ...search } }
    case DICTIONARY:
      return { ...state, dictionary: action.items };
    case DELETE:
      return { ...state, items: state.items.filter(v => v.id !== action.id) };
    case UPDATE:
    case STORE:
    default:
      return state;
  }
}