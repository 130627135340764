
import { API_BASE } from '../constraints/commons'
import { getMyAxios } from '../helpers/auth'

export const GET_RELEASE_LP = 'GET_RELEASE_LP'
export const ERROR_RELEASE_LP = 'ERROR_RELEASE_LP'

export const getPage = id => async dispatch => {
  try {
    const response = await getMyAxios().get(`${API_BASE}/v1/lp/contents/${id}`)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_RELEASE_LP, error: `ページが見つかりませんでした。[${status.code}]` })
        break
      case 0:
        const { data: { status: __, info: { url, favicon, title }, ...paginate } } = response
        dispatch({ type: GET_RELEASE_LP, url, paginate, favicon, title })
        break
      default:
        dispatch({ type: ERROR_RELEASE_LP, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_RELEASE_LP, error })
  }
}
