
import { API_BASE } from '../constraints/commons'
import { getMyAxios } from '../helpers/auth'

export const GET_ANALYTICS = 'GET_ANALYTICS'
export const SET_ANALYTICS_SEARCH = 'SET_ANALYTICS_SEARCH'
export const ERROR_ANALYTICS = 'ERROR_ANALYTICS'

export const setSearch = (values) => async dispatch => {
  dispatch({ type: SET_ANALYTICS_SEARCH, search: values })
}

export const getTargets = values => async dispatch => {
  try {
    const response = await getMyAxios().get(`${API_BASE}/v1/lp/analytics/get`, { params: values })
    const { data: { status, date_list: items, date_sum: total, ...paginate } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_ANALYTICS, error: `成果一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_ANALYTICS, items, paginate, total })
        break
      default:
        dispatch({ type: ERROR_ANALYTICS, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_ANALYTICS, error })
  }
}

