import React from 'react';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { WEB_HOME_URL, APP_NAME } from '../../constraints/commons'

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={WEB_HOME_URL}>
        { APP_NAME }
      </Link>{' '}
      {/* {new Date().getFullYear()} */}
      {' All rights reserved.'}
    </Typography>
  );
}
