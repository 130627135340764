
import { API_BASE } from '../constraints/commons'
import { getMyAxios } from '../helpers/auth'

export const GET_USERS = 'GET_USERS'
export const GET_USER = 'GET_USER'
export const STORE_USER = 'STORE_USER'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const ERROR_USER = 'ERROR_USER'
export const SET_EDIT_USER = 'SET_EDIT_USER'
export const REMOVE_EDIT_USER = 'REMOVE_EDIT_USER'
export const SET_SEARCH = 'SET_SEARCH'
export const GET_USER_DICTIONARY = 'GET_USER_DICTIONARY'

export const setEditTarget = item => {
  return { type: SET_EDIT_USER, user: item }
}

export const removeEditTarget = () => {
  return { type: REMOVE_EDIT_USER }
}

const _getTargets = async values => {
  return await getMyAxios().get(`${API_BASE}/v1/user`, { params: values })
}

export const getTargets = values => async dispatch => {
  try {
    const response = await _getTargets(values)
    const { data: { status, info, ...paginate } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_USER, error: `ユーザー一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_USERS, users: info, paginate })
        break
      default:
        dispatch({ type: ERROR_USER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_USER, error })
  }
}

export const getTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().get(`${API_BASE}/v1/user/${id}`)
    const { data: { status, info: user } } = response
    if (status.code === 0) {
      dispatch({ type: GET_USER, user })
    } else {
      dispatch({ type: ERROR_USER, error: 'ユーザー情報の取得に失敗しました。' })
    }
  } catch (error) {
    dispatch({ type: ERROR_USER, error })
  }
}

export const storeTarget = (values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/user`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
      case 102:
        dispatch({ type: ERROR_USER, error: `ユーザー作成に失敗しました。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_USER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_USER, error })
  }
}

export const updateTarget = (id, values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().patch(`${API_BASE}/v1/user/${id}`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 102:
        dispatch({ type: ERROR_USER, error: `ユーザーの情報更新に失敗しました。[${status.code}]` })
        break
      case 101:
        dispatch({ type: ERROR_USER, error: `指定のメールアドレスは他のユーザーに使用されています。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_USER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_USER, error })
  }
}

export const deleteTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().delete(`${API_BASE}/v1/user/${id}`)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_USER, error: `ユーザー削除に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: DELETE_USER, id })
        break
      default:
        dispatch({ type: ERROR_USER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_USER, error })
  }
}

export const verifyEmail = (values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/user/email/verify`, values)
    const { data: { status } } = response
    let result = false
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 102:
        dispatch({ type: ERROR_USER, error: `メールアドレスの確認に失敗しました。[${status.code}]` })
        break
      case 101:
        dispatch({ type: ERROR_USER, error: `URLの有効期限が切れています。[${status.code}]` })
        break
      case 0:
        result = true
        break
      default:
        dispatch({ type: ERROR_USER, error: `予期せぬエラーが発生しました。` })
        break
    }
    callback(result)
  } catch (error) {
    dispatch({ type: ERROR_USER, error })
    callback(false)
  }
}

export const sendVerifyEmail = (id, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/user/${id}/email/verify/send`)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_USER, error: `メールの送信に失敗しました。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_USER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_USER, error })
  }
}

export const requestPasswordReset = (values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/user/password/reset/send`, values)
    const { data: { status } } = response
    let result = false
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_USER, error: `パスワード変更依頼に失敗しました。[${status.code}]` })
        break
      case 0:
        result = true
        break
      default:
        dispatch({ type: ERROR_USER, error: `予期せぬエラーが発生しました。` })
        break
    }
    callback(result)
  } catch (error) {
    dispatch({ type: ERROR_USER, error })
    callback(false)
  }
}

export const passwordReset = (values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/user/password/reset`, values)
    const { data: { status } } = response
    let result = false
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 102:
        dispatch({ type: ERROR_USER, error: `パスワード変更に失敗しました。[${status.code}]` })
        break
      case 101:
        dispatch({ type: ERROR_USER, error: `URLの有効期限が切れています。[${status.code}]` })
        break
      case 0:
        result = true
        break
      default:
        dispatch({ type: ERROR_USER, error: `予期せぬエラーが発生しました。` })
        break
    }
    callback(result)
  } catch (error) {
    dispatch({ type: ERROR_USER, error })
    callback(false)
  }
}

export const setSearch = (values) => async dispatch => {
  dispatch({ type: SET_SEARCH, search: values })
}

export const getDictionary = (values) => async dispatch => {
  try {
    const response = await _getTargets(values)
    const { data: { status, info } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_USER, error: `ユーザー一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_USER_DICTIONARY, users: info })
        break
      default:
        dispatch({ type: ERROR_USER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_USER, error })
  }
}