
// 未認証
export const PATH_LOGIN = '/login';
export const PATH_USER_STORE = '/user/store';
export const PATH_USER_EMAIL_VERIFY = '/user/email/verify/:token';
export const PATH_USER_PASSWORD_RESET_EMAIL = '/user/password/reset/send';
export const PATH_USER_PASSWORD_RESET = '/user/password/reset/:token';

// ログイン必須
export const PATH_HOME = '/user/dashboad';
export const PATH_USER_UPDATE = '/user/edit';
export const PATH_BANK_UPDATE = '/user/bank/edit';
export const PATH_SETTLEMENT_UPDATE = '/user/settlement/edit';
export const PATH_LP = '/user/lp';
export const PATH_LP_STORE = '/user/lp/store';
export const PATH_LP_UPDATE = '/user/lp/:id';
export const PATH_LP_UPLOAD = '/user/lp/upload';
export const PATH_ANALYTICS = '/user/analytics';
export const PATH_LPMASTER = '/user/lp/management';
export const PATH_LPMASTER_STORE = '/user/lp/management/store';
export const PATH_LPMASTER_UPDATE = '/user/lp/management/:id';

// いつでも閲覧可能
export const PATH_LEAVED = '/leaved';
export const PATH_LP_PAGE = '/lp/:id';

// 管理者
export const PATH_ADMIN_USER = '/admin/user';
export const PATH_ADMIN_USER_STORE = '/admin/user/store';
export const PATH_ADMIN_USER_UPDATE = '/admin/user/:id';
export const PATH_MAIL_TEMPLATE = '/admin/mail/template';
export const PATH_MAIL_TEMPLATE_STORE = '/admin/mail/template/store';
export const PATH_MAIL_TEMPLATE_UPDATE = '/admin/mail/template/:id';
// 管理者かつOEM管理者
export const PATH_ADMIN_OEM = '/admin/oem';
export const PATH_ADMIN_OEM_UPDATE = '/admin/oem/:id';

export const getUpdatePath = (id, path) => path.replace(':id', id);