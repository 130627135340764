
import { API_BASE } from '../constraints/commons'
import { getMyAxios } from '../helpers/auth'

export const GET_OEMS = 'GET_OEMS'
export const GET_OEM = 'GET_OEM'
export const UPDATE_OEM = 'UPDATE_OEM'
export const ERROR_OEM = 'ERROR_OEM'
export const SET_EDIT_OEM = 'SET_EDIT_OEM'
export const REMOVE_EDIT_OEM = 'REMOVE_EDIT_OEM'
export const SET_OEM_SEARCH = 'SET_OEM_SEARCH'

export const setEditTarget = item => {
  return { type: SET_EDIT_OEM, oem: item }
}

export const removeEditTarget = () => {
  return { type: REMOVE_EDIT_OEM }
}

const _getTargets = async values => {
  return await getMyAxios().get(`${API_BASE}/v1/oem`, { params: values })
}

export const getTargets = values => async dispatch => {
  try {
    const response = await _getTargets(values)
    const { data: { status, info, ...paginate } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_OEM, error: `OEM一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_OEMS, items: info, paginate })
        break
      default:
        dispatch({ type: ERROR_OEM, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_OEM, error })
  }
}

export const getTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().get(`${API_BASE}/v1/oem/${id}`)
    const { data: { status, info: item } } = response
    if (status.code === 0) {
      dispatch({ type: GET_OEM, item })
    } else {
      dispatch({ type: ERROR_OEM, error: `OEM情報の取得に失敗しました。${status.code}` })
    }
  } catch (error) {
    dispatch({ type: ERROR_OEM, error })
  }
}

export const updateTarget = (id, values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().patch(`${API_BASE}/v1/oem/${id}`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 102:
        dispatch({ type: ERROR_OEM, error: `OEMの情報更新に失敗しました。[${status.code}]` })
        break
      case 101:
        dispatch({ type: ERROR_OEM, error: `同じ名前のものが既に存在しています。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_OEM, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_OEM, error })
  }
}

export const setSearch = (values) => async dispatch => {
  dispatch({ type: SET_OEM_SEARCH, search: values })
}
