import React from 'react';
import { passwordReset } from "../../../actions/user";
import Copyright from "../Copyright"

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'

import { connect } from 'react-redux'
import { REGEX_PASSWORD } from '../../../constraints/commons'
import { sleep } from "../../../helpers/common"
import { PATH_LOGIN } from '../../router/Path';


const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class PasswordReset extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      result: false,
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit(values) {
    const { token } = this.props.match.params
    values['token'] = token
    await this.props.passwordReset(values, (result) => { this.setState({ result }) });
    await sleep(500)
  }

  render() {
    const { classes } = this.props;
    const { result } = this.state
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            パスワード再設定
          </Typography>
          <Form
            onSubmit={this.onSubmit}
            validate={validate}
            render={formRender(classes)}
          />
          {
            result &&
            <Typography color={"primary"} variant="h6">
              パスワードを変更いたしました。<br />ログインページよりログインをお願い致します。
            </Typography>
          }
          <Button
            type="button"
            variant="text"
            onClick={() => { this.props.history.push(PATH_LOGIN) }}
          >
            ログインはこちら
          </Button>
        </div>
        <Box mt={2}>
          <Copyright />
        </Box>
      </Container>
    )
  }
}

const formRender = classes => ({ form, handleSubmit, pristine, submitting, invalid }) => {
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="password"
        label="パスワード"
        name="password"
        type="password"
        autoComplete="password"
        autoFocus
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="password_confirm"
        label="パスワード（再入力）"
        name="password_confirm"
        type="password"
        autoComplete="password"
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={pristine || submitting || invalid}
        className={classes.submit}
      >
        パスワードを変更する
    </Button>
    </form>
  )
}

const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = '入力してください';
  } else if (!REGEX_PASSWORD.test(values.password)) {
    errors.password = '8文字以上の英数字で記号は、アンダースコア(_)、ハイフン(-)、ドット(.)、アットマーク(@)のみ使用できます';
  } else if (values.password !== values.password_confirm) {
    errors.password = 'パスワード（再入力と一致しません）';
  }
  if (!values.password_confirm) errors.password_confirm = '入力して下さい'

  return errors
};

const mapStateToProps = state => ({ err: state.error });
const mapDispatchToProps = ({ passwordReset });

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(PasswordReset))
