import React from 'react'
import { uploadTarget, removeErrors } from "../../../actions/lp"
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Field, Form } from 'react-final-form'

import { connect } from 'react-redux'
import { PAGE_NAME as PAGE_HOME } from "../Home"
import { PAGE_NAME as PAGE_PARENT } from "./Index"
import { PATH_HOME, PATH_LP } from '../../router/Path'
import { Breadcrumbs, Grid, List, ListItem, ListItemText, ListSubheader } from '@material-ui/core'
import renderFile from '../../renderFile'
import LinkRouter from '../../router/LinkRouter'
import _ from 'lodash'
import { downloadLpBatchTemplate } from '../../../helpers/common'

const PAGE_NAME = 'CSVアップロード'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
  leftSpace: {
    paddingLeft: '1em'
  },
})

class Upload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      backdrop: {
        open: false
      }
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
    this.props.removeErrors()
  }

  async onSubmit(values) {
    const cb = () => {
      this.props.history.push(PATH_LP)
    }
    const formData = new FormData()
    let keys = [
      'file'
    ]
    for (const key of keys) {
      formData.append(key, values[key])
    }
    await this.props.uploadTarget(formData, cb)
  }

  render() {
    const { classes, auth: { user }, lp: { errors } } = this.props
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <LinkRouter color="inherit" to={PATH_LP}>
            {PAGE_PARENT(user)}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Form
          onSubmit={this.onSubmit}
          validate={validate(user)}
          render={formRender(classes, user)}
        />
        {
          !_.isEmpty(errors) &&
          <React.Fragment>
            <List subheader={<ListSubheader>エラー発生行</ListSubheader>}>
              {
                errors.map((e, i) =>
                  <React.Fragment>
                    <ListItem>
                      <ListItemText primary={`${e.row}行目 E${e.code}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`${e.data}`} />
                    </ListItem>
                  </React.Fragment>
                )
              }
            </List>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

export const formRender = (classes, user, update = false) => ({ form, handleSubmit, pristine, submitting, invalid }) => {
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <Grid container justify="center" className={classes.root} spacing={2}>
        <Grid item container direction="column" xs md={8}>
          <Grid item xs>
            <div className={classes.radioRoot}>
              <Field
                label="CSVファイル"
                id="file"
                name="file"
                accept="text/csv"
                required
                component={renderFile}
              />
            </div>
          </Grid>
          <Grid item xs>
            <Button color="primary" onClick={downloadLpBatchTemplate}>サンプルCSVダウンロード</Button>
            <Typography>
              ▼CSVファイルの各項目の説明<br />
              CSVファイルのヘッダー行（1行目）の各項目に【必須】がある列は記載が必須です。<br />
              ■1列名：【必須】LP発行対象のユーザーID<br />
              LPを発行したいユーザーIDを指定してください。<br />
              （ユーザーIDはユーザー管理の画面から確認出来ます）<br />
              <br />
              ■2列目：【必須】LPマスターID<br />
              LPマスター管理の画面を確認し、存在するLPマスターIDを指定してください。<br />
              <br />
              ■3列目：【必須】発行LP名<br />
              各ユーザー内で同じLP名は発行不可のため、必ず被りがない名前にしてください。<br />
              ユーザーが異なる場合は同じLP名で発行可能です。<br />
              <br />
              例1）ユーザー1で「テストLP」で発行、ユーザー2でも「テストLP」で発行<br />
              →ユーザーが別れているため問題ありません。<br />
              <br />
              例2）ユーザー1で「テストLP」で発行済みであり、再びユーザー1で「テストLP」で発行<br />
              →同じユーザー内でLP名に被りが出るため、同じLP名は発行不可です。<br />
              <br />
              ■8列目：連携する決済サービス（PayPal：paypal、Stripe：stripe、PAY.JP：payjp）<br />
              7列目「決済サービスと連携するか否か（連携する：1、連携しない：空）」で<br />
              1を指定している場合は記載必須です。<br />
              <br />
              ■9〜16列目：PayPal、Stripe、PAY.JPのAPI情報など<br />
              8列目で指定した決済サービスに応じて、「メモ」以外の列は記載必須です。<br />
              <br />
              ■18列目：【決済成功時のメール通知】メールテンプレートID<br />
              決済成功時のメール通知を行う場合で、メールテンプレートを使用する場合は<br />
              メールテンプレート管理の画面を確認し、存在するメールテンプレートIDを指定してください。<br />
              <br />
              ■19列目：メール送信者名<br />
              空の場合はシステムのデフォルトの送信者名が使用されます。<br />
              <br />
              ■20列目：メール送信アドレス<br />
              空の場合はシステムのデフォルトの送信アドレスが使用されます。<br />
              <br />
              ■21〜22列目：決済成功時のメール通知の件名と本文<br />
              17列目「決済成功時のメール通知（通知する：1、通知しない：空）」が1で且つ、<br />
              18列目「【決済成功時のメール通知】メールテンプレートID」が空の場合は記載必須です。<br />
              18列目が指定されている場合は、記載してもメールテンプレートの情報が優先されます。
            </Typography>
          </Grid>
          <Grid item container justify="flex-end" className={classes.btnRoot} spacing={2}>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                fullWidth
                disabled={pristine || submitting}
                onClick={form.reset}
              >
                リセット
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={pristine || submitting || invalid}
              >
                登録
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const validate = user => values => {
  const errors = {}
  const [required,] = [true,]
  const inputs = [
    { name: 'file', required },
  ]

  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      default:
        break
    }
  }
  return errors
}

const mapStateToProps = state => ({ err: state.error, auth: state.auth, lp: state.lp })
const mapDispatchToProps = ({ uploadTarget, removeErrors })

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withStyles(styles)(Upload)
)
