import React from 'react'
import { updateTarget, storeTarget, deleteTarget } from "../../../actions/bank"
import { setMe } from "../../../actions/auth"

import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { sleep } from "../../../helpers/common"
import { PAGE_NAME as PAGE_HOME } from "../Home"
import { PATH_HOME } from '../../router/Path'
import { Breadcrumbs, Grid, MenuItem } from '@material-ui/core'
import { Radios, Select, TextField } from 'mui-rff'
import Button from '@material-ui/core/Button'
import LinkRouter from '../../router/LinkRouter'
import { withSnackbar } from 'notistack';
import YesNoAlert from "../../modal/YesNoAlert";
import _ from 'lodash';

const PAGE_NAME = '口座情報'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
})

class Update extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      saving: false,
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  async onSubmit(values) {
    const option = { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, autoHideDuration: 2000 }
    const cb = () => {
      this.props.enqueueSnackbar('更新しました', option)
    }
    const _values = {}
    const jpBank = true
    const inputs = [
      { name: 'jp_bank_account_symbol', jpBank },
      { name: 'jp_bank_account_number', jpBank },
      { name: 'jp_bank_account_holder', jpBank },
      { name: 'jp_bank_store_number', jpBank },
      { name: 'bank_code' },
      { name: 'bank_name' },
      { name: 'bank_branch_code' },
      { name: 'bank_branch_name' },
      { name: 'bank_account_type' },
      { name: 'bank_account_number' },
      { name: 'bank_account_holder' },
    ]
    const { jp_bank_flag } = values
    _values['jp_bank_flag'] = jp_bank_flag
    for (const { name, jpBank } of inputs) {
      if (jpBank && parseInt(jp_bank_flag) === 1) _values[name] = values[name]
      if (!jpBank && parseInt(jp_bank_flag) === 2) _values[name] = values[name]
    }
    const { auth: { user: { bank_account } } } = this.props
    if (!_.isEmpty(bank_account)) {
      const { id } = bank_account[0]
      await this.props.updateTarget(id, _values, cb)
    } else {
      await this.props.storeTarget(_values, cb)
    }
    await this.props.setMe(() => { })
    await sleep(1000)
  }

  handleDeleteClick() {
    this.setState({ open: true })
  }

  async handleDelete(result) {
    if (result) {
      this.setState({ saving: true })
      const { auth: { user: { bank_account } } } = this.props
      if (!_.isEmpty(bank_account)) {
        const { id } = bank_account[0]
        await this.props.deleteTarget(id)
      }
      await this.props.setMe(() => { })
      this.setState({ saving: false })
    }
    this.setState({ open: false })
  }

  render() {
    const { auth: { user: { bank_account } } } = this.props
    let id = undefined
    let item = {}
    if (!_.isEmpty(bank_account)) {
      id = bank_account[0].id
      item = _.cloneDeep(bank_account[0])
    }
    const { classes } = this.props
    const { open, saving } = this.state
    if ('jp_bank_flag' in item) {
      item.jp_bank_flag += ''
    }
    if (_.isEmpty(item)) {
      item['bank_account_type'] = '1'
    } else if ('bank_account_type' in item && !item.bank_account_type) {
      item['bank_account_type'] = '1'
    }
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Form
          onSubmit={this.onSubmit}
          validate={validate}
          initialValues={item}
          render={formRender(classes, saving || !!!id, this.handleDeleteClick)}
        />
        <YesNoAlert
          open={open}
          title={'確認'}
          message={'本当に削除しますか？'}
          handleClose={this.handleDelete}
        />
      </React.Fragment>
    )
  }
}

const validate = values => {
  const errors = {}
  const [required, jp_bank, kana] = [true, true, true]
  const inputs = [
    { name: 'jp_bank_account_symbol', required, jp_bank },
    { name: 'jp_bank_account_number', required, jp_bank },
    { name: 'jp_bank_account_holder', required, jp_bank, kana },
    { name: 'bank_code', required, },
    { name: 'bank_name', required, },
    { name: 'bank_branch_code', required, },
    { name: 'bank_branch_name', required, },
    { name: 'bank_account_type', required, },
    { name: 'bank_account_number', required, },
    { name: 'bank_account_holder', required, kana },
  ]

  const isJpBank = parseInt(values.jp_bank_flag) === 1
  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && input.jp_bank && isJpBank && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.kana && input.jp_bank && isJpBank && !val.match(/^[ｦ-ﾟ ]+$/):
        errors[key] = '半角カタカナで入力してください'
        break
      case input.required && !input.jp_bank && !isJpBank && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.kana && !input.jp_bank && !isJpBank && !val.match(/^[ｦ-ﾟ ]+$/):
        errors[key] = '半角カタカナで入力してください'
        break
      default:
        break
    }
  }
  return errors
}

const formRender = (classes, disabled, handleDeleteClick) => ({ form, handleSubmit, pristine, submitting, invalid, values }) => {
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <Grid container justify="center" className={classes.root} spacing={2}>
        <Grid item container direction="column" xs md={10} spacing={1}>
          <Grid item>
            <Radios
              label="銀行の種類"
              name="jp_bank_flag"
              required
              radioGroupProps={{ row: true }}
              data={[
                { label: 'ゆうちょ銀行', value: '1' },
                { label: 'その他の銀行', value: '2' },
              ]}
            />
          </Grid>
          {
            parseInt(values['jp_bank_flag']) === 1 &&
            <Grid item container spacing={1}>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="口座記号"
                  name="jp_bank_account_symbol"
                />
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="口座番号"
                  name="jp_bank_account_number"
                />
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="口座名義"
                  name="jp_bank_account_holder"
                  helperText="半角カタカナで入力してください"
                />
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="店番"
                  name="jp_bank_store_number"
                />
              </Grid>
            </Grid>
          }
          {
            parseInt(values['jp_bank_flag']) === 2 &&
            <React.Fragment>
              <Grid item container spacing={1}>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="銀行コード"
                    name="bank_code"
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="銀行名"
                    name="bank_name"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="支店番号"
                    name="bank_branch_code"
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="支店名"
                    name="bank_branch_name"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs={2}>
                  <Select
                    name="bank_account_type"
                    label="口座種別"
                    formControlProps={{ margin: 'normal', variant: "outlined" }}
                    required
                  >
                    <MenuItem value='1'>普通</MenuItem>
                    <MenuItem value='2'>当座</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="口座番号"
                    name="bank_account_number"
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="口座名義"
                    name="bank_account_holder"
                    helperText="半角カタカナで入力してください"
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          }
          <Grid item container justify="space-around" className={classes.btnRoot} spacing={2}>
            <Grid item container justify="flex-start" spacing={2} xs>
              <Grid item>
                <Button
                  type="button"
                  onClick={handleDeleteClick}
                  variant="contained"
                  disabled={disabled}
                >
                  口座情報を削除する
                </Button>
              </Grid>
            </Grid>
            <Grid item container justify="flex-end" spacing={2} xs>
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={pristine || submitting}
                  onClick={form.reset}
                >
                  リセット
              </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={pristine || submitting || invalid}
                >
                  保存
              </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const mapStateToProps = state => ({
  targets: state.bank, auth: state.auth
})
const mapDispatchToProps = ({ updateTarget, storeTarget, deleteTarget, setMe })

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withSnackbar(withStyles(styles)(Update))
)
