import React from 'react';
import _ from "lodash"
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  getTargets, setEditTarget, deleteTarget, setSearch
} from '../../../actions/mailtemplate'
import { getDictionary as getUserDictionary } from '../../../actions/user'
import { getDictionary as getLpMasterDictionary } from '../../../actions/mailtemplate'
import { connect } from 'react-redux'
import { getUpdatePath, PATH_HOME, PATH_MAIL_TEMPLATE_STORE, PATH_MAIL_TEMPLATE_UPDATE } from "../../router/Path";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import LinkRouter from "../../router/LinkRouter";
import Grid from "@material-ui/core/Grid";
import MaterialTable from 'material-table'
import DeleteIcon from '@material-ui/icons/Delete';
import { defaultLocalization, defaultOption } from "../../../helpers/material-table";
import { PAGE_NAME as PAGE_HOME } from "../Home";
import YesNoAlert from "../../modal/YesNoAlert";
import { Paper } from '@material-ui/core';
import { Form } from 'react-final-form'
import Button from '@material-ui/core/Button'
import { Select, TextField } from 'mui-rff';
import { MenuItem } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withSnackbar } from 'notistack'
import EditIcon from '@material-ui/icons/Edit';

export const PAGE_NAME = '決済成功時に通知するメールのテンプレート';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '70%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(1),
  },
  formPaper: {
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      deleteId: null,
      sort_num: 1,
    }
  }

  componentDidMount() {
    this.storeOnClick = this.storeOnClick.bind(this);
    this.editOnClick = this.editOnClick.bind(this);
    this.materialTableData = this.materialTableData.bind(this);
    this.deleteOnClick = this.deleteOnClick.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSearch = this.onSearch.bind(this)
    this.addSearch = this.addSearch.bind(this)

    this.onSearch(this.props.targets.search);
    defaultOption.search = false
    defaultOption.sorting = false
    defaultOption.draggable = false
  }

  storeOnClick() {
    this.props.history.push(PATH_MAIL_TEMPLATE_STORE);
  }

  editOnClick(event, rowData) {
    this.props.setEditTarget(rowData);
    this.props.history.push(getUpdatePath(rowData.id, PATH_MAIL_TEMPLATE_UPDATE));
  }

  deleteOnClick(event, rowData) {
    this.setState({ open: true, deleteId: rowData.id })
  }

  onDelete(result) {
    if (result) this.props.deleteTarget(this.state.deleteId)
    this.setState({ open: false, deleteId: null })
  }

  materialTableData() {
    return this.props.targets.items
  }

  addSearch() {
    this.setState({ sort_num: this.state.sort_num + 1 })
  }

  onSearch(values) {
    const _values = _.cloneDeep(values)
    this.props.setSearch(values)
    const keys = [
      'name'
    ]
    for (const key of keys) {
      if (!_values[key]) delete _values[key]
    }
    // 管理者のみなので全てのユーザー
    _values['user_id'] = -1
    if (_.isEmpty(_values['sort'])) {
      delete _values['sort']
    } else {
      let s = []
      for (const sort of _values['sort']) {
        if (sort && 'order' in sort && 'key' in sort) {
          s.push(`${sort.key}=${sort.order}`)
        }
      }
      _values['sort'] = s.join(',')
      if (s.length) _values['sort'] = s.join(',')
      else delete _values['sort']
    }
    this.props.getTargets(_values)
  }

  render() {
    const { classes, targets: { search }, dictionary } = this.props;
    const { open, sort_num } = this.state;
    const reset = () => {
      this.props.setSearch(null)
      this.setState({ sort_num: 1 })
    }
    let actions = [
      {
        icon: () => (<EditIcon color='inherit' />),
        tooltip: '編集',
        onClick: this.editOnClick,
      },
      {
        icon: 'add',
        tooltip: '追加',
        isFreeAction: true,
        onClick: this.storeOnClick
      },
      {
        icon: () => (<DeleteIcon color={'error'} />),
        tooltip: '削除',
        onClick: this.deleteOnClick,
      },
    ]

    let cellStyle = (minWidth) => ({ whiteSpace: 'break-spaces', wordBreak: 'break-all', minWidth })
    let nowrap = { whiteSpace: 'nowrap' };
    let columns = [
      { title: 'ID', field: 'id', cellStyle: nowrap, headerStyle: nowrap },
      { title: '管理名', field: 'name', cellStyle: nowrap, headerStyle: nowrap },
      { title: '送信者名', field: 'from_name', cellStyle: nowrap, headerStyle: nowrap },
      { title: '送信者メールアドレス', field: 'from_address', cellStyle: nowrap, headerStyle: nowrap },
      { title: '件名', field: 'subject', cellStyle: nowrap, headerStyle: nowrap },
      { title: '本文', field: 'contents', cellStyle: cellStyle('30em'), headerStyle: nowrap },
      { title: '最終更新日', field: 'updated_at', cellStyle: nowrap, headerStyle: nowrap },
    ]

    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Grid container justify="center" direction="column" className={classes.root}>
          <Grid item xs>
            <Form
              onSubmit={this.onSearch}
              initialValues={search}
              validate={validate}
              render={formRender(classes, reset, sort_num, this.addSearch, dictionary)}
            />
          </Grid>
        </Grid>
        <MaterialTable
          columns={columns}
          components={{
            Container: props => {
              return (
                <Paper {...props} elevation={0} variant="outlined" />
              )
            }
          }}
          data={this.materialTableData()}
          title={'決済成功時に通知するメールのテンプレート一覧'}
          actions={actions}
          options={defaultOption}
          localization={defaultLocalization}
        />
        <YesNoAlert
          open={open}
          title={'確認'}
          message={'本当に削除しますか？'}
          handleClose={this.onDelete}
        />
      </React.Fragment>
    );
  }
}

const formRender = (classes, reset, sort_num, add, dictionary) => ({ handleSubmit, submitting, invalid }) => {
  return (
    <Paper className={classes.formPaper} elevation={0} variant="outlined">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>検索条件</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form method="post" className={classes.form} onSubmit={handleSubmit}>
            <Grid item container direction="column" spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="name"
                    label={`管理名`}
                    name="name"
                  />
                </Grid>
              </Grid>
              {
                _.map([...Array(sort_num).keys()].map(i => ++i), item => {
                  return (
                    <Grid item container spacing={2} key={`sort${item}`} alignItems="center">
                      <Grid item xs>
                        <Select name={`sort[${item}][key]`} label={`ソート条件${item}:列`} formControlProps={{ margin: 'dense', variant: "outlined" }}>
                          {
                            [
                              { name: 'ID', val: 'id' },
                              { name: '管理名', val: 'name' },
                              { name: 'メール送信者名', val: 'from_name' },
                              { name: 'メール送信者のメールアドレス', val: 'from_address' },
                              { name: 'メール件名', val: 'subject' },
                              { name: 'メール本文', val: 'contents' },
                              { name: '更新日時', val: 'updated_at' },
                            ].map((row, i) => (
                              <MenuItem value={row.val} key={`col${item}${i}`}>{row.name}</MenuItem>
                            ))
                          }
                        </Select>
                      </Grid>
                      <Grid item xs>
                        <Select name={`sort[${item}][order]`} label={`ソート条件${item}:並び順`} formControlProps={{ margin: 'dense', variant: "outlined" }}>
                          <MenuItem value="asc">昇順</MenuItem>
                          <MenuItem value="desc">降順</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs>
                        {
                          (item === 1 && sort_num < 5) &&
                          <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            onClick={add}
                          >ソート追加</Button>
                        }
                      </Grid>
                    </Grid>
                  )
                })
              }
              <Grid item container justify="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={submitting}
                    onClick={reset}
                  >
                    リセット
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={submitting || invalid}
                  >
                    検索
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}

const validate = values => {
  const errors = {}
  const inputs = [

  ]
  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      default:
        break
    }
  }

  return errors
}


const mapStateToProps = state => ({
  targets: state.mailtemplate, auth: state.auth, dictionary: {
    user: state.user.dictionary
  }
});
const mapDispatchToProps = ({ getTargets, setEditTarget, deleteTarget, setSearch, getUserDictionary, getLpMasterDictionary });

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withSnackbar(withStyles(styles)(Index))
);
