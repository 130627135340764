import _ from "lodash"
import axios from "axios"

const API_TOKEN = 'API_TOKEN';
const INTENDED_URL = 'intended_url';

const setLocalStorageToken = token => {
  if (_.isEmpty(token)) {
    localStorage.removeItem(API_TOKEN);
    return;
  }
  localStorage.setItem(API_TOKEN, token);
};

export const getMyAxios = () => {
  const token = getToken()
  axios.defaults.headers.common['Authorization'] = _.isEmpty(token) ? null : `Bearer ${token}`
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  return axios
}

export const setToken = (token) => {
  setLocalStorageToken(token);
};

export const getToken = () => {
  return localStorage.getItem(API_TOKEN);
};

export const setIntendedUrl = url => {
  localStorage.setItem(INTENDED_URL, url);
};

export const getIntendedUrl = () => {
  return localStorage.getItem(INTENDED_URL);
};

export const authorityName = (val) => {
  return val === 1 ? '管理者' : '一般'
}