
import { API_BASE } from '../constraints/commons'
import { getMyAxios } from '../helpers/auth'

export const GET_MAIL_TEMPLATES = 'GET_MAIL_TEMPLATES'
export const GET_MAIL_TEMPLATE = 'GET_MAIL_TEMPLATE'
export const STORE_MAIL_TEMPLATE = 'STORE_MAIL_TEMPLATE'
export const DELETE_MAIL_TEMPLATE = 'DELETE_MAIL_TEMPLATE'
export const UPDATE_MAIL_TEMPLATE = 'UPDATE_MAIL_TEMPLATE'
export const ERROR_MAIL_TEMPLATE = 'ERROR_MAIL_TEMPLATE'
export const SET_EDIT_MAIL_TEMPLATE = 'SET_EDIT_MAIL_TEMPLATE'
export const REMOVE_EDIT_MAIL_TEMPLATE = 'REMOVE_EDIT_MAIL_TEMPLATE'
export const SET_MAIL_TEMPLATE_SEARCH = 'SET_MAIL_TEMPLATE_SEARCH'
export const GET_MAIL_TEMPLATE_DICTIONARY = 'GET_MAIL_TEMPLATE_DICTIONARY'

const _getTargets = async values => {
  return await getMyAxios().get(`${API_BASE}/v1/paid/notice/email/template`, { params: values })
}

export const setEditTarget = item => {
  return { type: SET_EDIT_MAIL_TEMPLATE, item }
}

export const removeEditTarget = () => {
  return { type: REMOVE_EDIT_MAIL_TEMPLATE }
}

export const getTargets = values => async dispatch => {
  try {
    const response = await _getTargets(values)
    const { data: { status, info: items, ...paginate } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `メールテンプレート一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_MAIL_TEMPLATES, items, paginate })
        break
      default:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_MAIL_TEMPLATE, error })
  }
}

export const getTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().get(`${API_BASE}/v1/paid/notice/email/template/${id}`)
    const { data: { status, info: item } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `メールテンプレートの取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_MAIL_TEMPLATE, item })
        break
      default:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_MAIL_TEMPLATE, error })
  }
}

export const storeTarget = (values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/paid/notice/email/template`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `メールテンプレートの作成に失敗しました。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_MAIL_TEMPLATE, error })
  }
}

export const updateTarget = (id, values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().patch(`${API_BASE}/v1/paid/notice/email/template/${id}`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `メールテンプレートの更新に失敗しました。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_MAIL_TEMPLATE, error })
  }
}

export const deleteTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().delete(`${API_BASE}/v1/paid/notice/email/template/${id}`)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `メールテンプレートの削除に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: DELETE_MAIL_TEMPLATE, id })
        break
      default:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_MAIL_TEMPLATE, error })
  }
}

export const setSearch = (values) => async dispatch => {
  dispatch({ type: SET_MAIL_TEMPLATE_SEARCH, search: values })
}

export const getDictionary = (values) => async dispatch => {
  try {
    const response = await _getTargets(values)
    const { data: { status, info: items } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `メールテンプレート一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_MAIL_TEMPLATE_DICTIONARY, items })
        break
      default:
        dispatch({ type: ERROR_MAIL_TEMPLATE, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_MAIL_TEMPLATE, error })
  }
}