import { API_BASE, WEB_HOME_URL } from '../constraints/commons'
import { getMyAxios } from "./auth"
import Encoding from 'encoding-japanese'
import { getUpdatePath, PATH_LP_PAGE } from '../components/router/Path'

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
export const isAdmin = user => user ? user.authority === 1 : false
export const isOemAdmin = user => user ? user.oem_authority === 1 : false

const toShiftJIS = (utf8String) => {
  const detected = Encoding.detect(utf8String)
  const unicodeList = []

  for (let i = 0; i < utf8String.length; i += 1) {
    unicodeList.push(utf8String.charCodeAt(i))
  }

  const sjisArray = Encoding.convert(unicodeList, {
    to: 'SJIS',
    from: detected
  })
  return new Uint8Array(sjisArray)
}

export const downloadUserCSV = async (values) => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/user/export`, values)
    const { data } = response
    if (response.headers['content-type'] === 'application/json') {
      alert('CSVダウンロードに失敗しました。')
    } else {
      const BLOB = new Blob([toShiftJIS(data)], { type: response.headers['content-type'] })
      const url = URL.createObjectURL(BLOB)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'users.csv') //ここらへんは適当に設定する
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(url)
    }
  } catch (error) {

  }
}

export const getLPURL = (id) => {
  return `${WEB_HOME_URL}${getUpdatePath(id, PATH_LP_PAGE)}`
}

export const downloadLpBatchTemplate = async () => {
  try {
    const response = await getMyAxios().get(`/csv/lp_batch_create.csv`)
    const { data } = response
    console.log(response)
    const BLOB = new Blob([toShiftJIS(data)], { type: response.headers['content-type'] })
    const url = URL.createObjectURL(BLOB)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'lp_batch_create.csv')
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
  } catch (error) {
    console.error(error)
  }
}
