import React from 'react'
import { storeTarget } from "../../../actions/lp"
import { getDictionary } from "../../../actions/lpmaster"
import { getDictionary as getUserDictionary } from '../../../actions/user'
import { getDictionary as getMailTemplate } from '../../../actions/mailtemplate'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import { Radios, Select, TextField } from 'mui-rff'
import createDecorator from 'final-form-calculate'
import {
  getTargets as getStripeList,
  setEditTarget as setEditStripe,
} from "../../../actions/stripe"
import {
  getTargets as getPayjpList,
  setEditTarget as setEditPayjp,
} from "../../../actions/payjp"
import { connect } from 'react-redux'
import { isAdmin } from "../../../helpers/common"
import { PAGE_NAME as PAGE_HOME } from "../Home"
import { PAGE_NAME as PAGE_PARENT } from "./Index"
import { PATH_HOME, PATH_LP, PATH_SETTLEMENT_UPDATE } from '../../router/Path'
import { Breadcrumbs, Grid, MenuItem } from '@material-ui/core'
import LinkRouter from '../../router/LinkRouter'
import { REGEX_EMAIL, REGEX_URL } from '../../../constraints/commons'
import _ from 'lodash'

const PAGE_NAME = '登録'
const payments = [
  { value: 'paypal', name: 'PayPal' },
  { value: 'stripe', name: 'Stripe' },
  { value: 'payjp', name: 'PAY.JP' },
]
const PAYPAL_SETTING_URL = 'https://docs.google.com/presentation/d/1QBnwRAU1-xwSqYeAZTmuFaxjub5LpiCmKyhUAeHthyE/edit?usp=sharing'
const STRIPE_SETTING_URL = 'https://docs.google.com/presentation/d/1alI8RNWTtO647NEVbVeRuQ_sDEWaezbUrKB_o1VrFXM/edit?usp=sharing'
const PAYJP_SETTING_URL = 'https://docs.google.com/presentation/d/1Ssz3sbIUiRigL_9vkW_vowAz7VMSz4aou_ZdVHqGrOI/edit?usp=sharing'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
})

export const noneCheck = items => {
  if (_.isEmpty(items)) return true
  if (!items[0].api_secret_key || !items[0].api_public_key) return true
  return false
}

export const getMailTemplateValue = (field, dictionary) => {
  return (id, allValues) => {
    const val = dictionary.mailtemplate.filter(v => v.id === id)
    if (val.length) return val[0][`${field}`]
    return ''
  }
}

class Store extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stored: false
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
    this.props.getDictionary({ include_public_of_other_users: 1 })
    this.props.getMailTemplate()
    const { auth: { user } } = this.props
    if (isAdmin(user)) {
      this.props.getUserDictionary()
    }
    this.load = this.load.bind(this)
    this.load()
  }

  async load() {
    await this.props.getStripeList()
    await this.props.getPayjpList()
    const { stripe: { items: stripe }, payjp: { items: payjp } } = this.props
    if (!_.isEmpty(stripe)) {
      this.props.setEditStripe(stripe[0])
    }
    if (!_.isEmpty(payjp)) {
      this.props.setEditPayjp(payjp[0])
    }
  }

  async onSubmit(values) {
    await this.props.storeTarget(values, () => { this.props.history.push(PATH_LP) })
  }

  render() {
    const { classes, dictionary, auth: { user }, stripe, payjp } = this.props
    const stripeIsNone = noneCheck(stripe.items)
    const payjpIsNone = noneCheck(payjp.items)
    const calculator = createDecorator(
      {
        field: 'mailtemplate',
        updates: {
          paid_notice_email_from_name: getMailTemplateValue('from_name', dictionary),
          paid_notice_email_from_address: getMailTemplateValue('from_address', dictionary),
          paid_notice_email_subject: getMailTemplateValue('subject', dictionary),
          paid_notice_email_contents: getMailTemplateValue('contents', dictionary),
        }
      }
    )
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <LinkRouter color="inherit" to={PATH_LP}>
            {PAGE_PARENT(user)}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Form
          onSubmit={this.onSubmit}
          decorators={[calculator]}
          initialValues={{
            payment_service_link_flag: '0',
            payment_service: 'paypal',
            payjp_payment_mode: '1',
            stripe_payment_mode: '1',
            paid_notice_email_notice_mode: '0',
          }}
          validate={validate(stripeIsNone, payjpIsNone, dictionary, isAdmin(user))}
          render={formRender(classes, dictionary, isAdmin(user), stripeIsNone, payjpIsNone)}
        />
      </React.Fragment>
    )
  }
}

export const formRender = (classes, dictionary, admin, stripeIsNone, payjpIsNone, update = false) => ({
  form, handleSubmit, pristine, submitting, invalid, values }) => {
  const { payment_service_link_flag, payment_service, payjp_payment_mode, paid_notice_email_notice_mode } = values
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <Grid container justify="center" className={classes.root} spacing={2}>
        <Grid item container direction="column" xs md={8} spacing={1}>
          {
            admin &&
            <Grid item>
              <Select
                name="user_id"
                id="user_id"
                label="所有者名"
                formControlProps={{ margin: 'normal', variant: "outlined" }}
                required
                disabled={update}
              >
                {
                  _.map(dictionary.user, (row, i) => (
                    <MenuItem value={row.id} key={`user_${row.id}`}>
                      {`${row.last_name} ${row.first_name}`}
                    </MenuItem>
                  ))
                }
              </Select>
            </Grid>
          }
          <Grid item>
            <Select
              name="lp_master_id"
              id="lp_master_id"
              label="LPマスター"
              formControlProps={{ margin: 'normal', variant: "outlined" }}
              required
              disabled={update}
            >
              {
                _.map(dictionary.lpmaster, (row, i) => (
                  <MenuItem value={row.id} key={`lp_master_${row.id}`}>
                    {row.logical_name}
                    {row.scope === 1 && '【公開】'}
                  </MenuItem>
                ))
              }
            </Select>
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label={admin ? 'LP名' : '案件名'}
              name="name"
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="myasp_url"
              label="置換したい「MyASP」のURL"
              id="myasp_url"
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="line_at_url"
              label="置換したい「LINE@」のURL"
              id="line_at_url"
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="other_url"
              label="置換したい「その他」のURL"
              id="other_url"
            />
          </Grid>

          <Grid item xs>
            <Radios
              name="payment_service_link_flag"
              required
              radioGroupProps={{ row: true }}
              data={[
                { label: '決済サービスと連携しない', value: '0' },
                { label: '決済サービスと連携する', value: '1' },
              ]}
            />
          </Grid>
          {
            payment_service_link_flag === '1' &&
            <React.Fragment>
              <Grid item xs>
                <Select
                  name="payment_service"
                  label="連携する決済サービス"
                  required
                  formControlProps={{ variant: "outlined" }}
                >
                  {
                    payments.map((row, i) => (
                      <MenuItem value={row.value} key={`payments_${i}`}>{`${row.name}`}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>
              {
                payment_service === 'paypal' &&
                <React.Fragment>
                  <Grid item xs>
                    <Typography component="div" variant="body2" style={{ marginTop: '4px' }}>
                      PayPal決済連携方法の詳細は<a href={PAYPAL_SETTING_URL} target="_blanck" rel="noopener noreferrer" >こちら</a>
                    </Typography>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      name="paypal_payment_url"
                      label="決済URL"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="https://..."
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="paypal_note"
                      label="メモ"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText="商品名など備忘録用にご使用ください。"
                    />
                  </Grid>
                  {
                    update &&
                    <React.Fragment>
                      <Grid item xs>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="paypal_product.success_url"
                          label="決済成功後のリダイレクトURL"
                          helperText="PayPalに登録されている内容が表示されます。表示専用です。"
                          disabled
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="paypal_product.cancel_url"
                          label="決済キャンセル後のリダイレクトURL"
                          helperText="PayPalに登録されている内容が表示されます。表示専用です。"
                          disabled
                        />
                      </Grid>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
              {
                payment_service === 'stripe' &&
                <React.Fragment>
                  {
                    stripeIsNone &&
                    <Grid item xs>
                      <Typography color="error" component="div" variant="body2">
                        APIの設定がされていません。
                        <LinkRouter color="primary" to={PATH_SETTLEMENT_UPDATE}>
                          こちら
                        </LinkRouter>
                        から設定をお願いします。
                      </Typography>
                    </Grid>
                  }
                  <Grid item xs>
                    <Typography component="div" variant="body2" style={{ marginTop: '4px' }}>
                      Stripe決済連携方法の詳細は<a href={STRIPE_SETTING_URL} target="_blanck" rel="noopener noreferrer" >こちら</a>
                    </Typography>
                    <Radios
                      name="stripe_payment_mode"
                      required
                      radioGroupProps={{ row: true }}
                      data={[
                        { label: '一括決済', value: '1' },
                        { label: '定期決済', value: '2' },
                      ]}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      name="stripe_api_id"
                      label="API ID"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="stripe_note"
                      label="メモ"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText="商品名など備忘録用にご使用ください。"
                    />
                  </Grid>
                </React.Fragment>
              }
              {
                payment_service === 'payjp' &&
                <React.Fragment>
                  {
                    payjpIsNone &&
                    <Grid item xs>
                      <Typography color="error" component="div" variant="body2">
                        APIの設定がされていません。
                        <LinkRouter color="primary" to={PATH_SETTLEMENT_UPDATE}>
                          こちら
                        </LinkRouter>
                        から設定をお願いします。
                      </Typography>
                    </Grid>
                  }
                  <Grid item xs>
                    <Typography component="div" variant="body2" style={{ marginTop: '4px' }}>
                      PAY.JP決済連携方法の詳細は<a href={PAYJP_SETTING_URL} target="_blanck" rel="noopener noreferrer" >こちら</a>
                    </Typography>
                    <Radios
                      name="payjp_payment_mode"
                      required
                      radioGroupProps={{ row: true }}
                      data={[
                        { label: '一括決済', value: '1' },
                        { label: '定期決済', value: '2' },
                      ]}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      name="payjp_api_id"
                      label={payjp_payment_mode === '1' ? 'プロダクトID' : 'プランID'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="payjp_note"
                      label="メモ"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText="商品名など備忘録用にご使用ください。"
                    />
                  </Grid>
                </React.Fragment>
              }
              {
                payment_service &&
                <React.Fragment>
                  <Grid item xs>
                    <Radios
                      name="paid_notice_email_notice_mode"
                      required
                      radioGroupProps={{ row: true }}
                      data={[
                        { label: '決済成功時にメール通知しない', value: '0' },
                        { label: '決済成功時にメール通知する', value: '1' },
                      ]}
                    />
                    <Typography component="p" variant="body2" color="error" style={{ marginTop: '4px' }}>
                      Stripe、PAY.JPのみ決済成功時にメール通知されます。<br />
                      PayPalは、PayPal側の仕様によりメールアドレスが取得できないためメール通知が行えません。<br />
                      「決済成功時にメール通知する」としている場合、連携する決済サービスに関わらず、決済成功画面でもメール内容が表示されます。
                    </Typography>

                  </Grid>
                  {
                    paid_notice_email_notice_mode === '1' &&
                    <React.Fragment>
                      {
                        !update &&
                        <Grid item>
                          <Select
                            name="mailtemplate"
                            id="mailtemplate"
                            label="メールテンプレート"
                            formControlProps={{ margin: 'normal', variant: "outlined" }}
                          >
                            {
                              _.map(dictionary.mailtemplate, (row, i) => (
                                <MenuItem value={row.id} key={`mailtemplate_${row.id}`}>
                                  {`${row.name}`}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </Grid>
                      }
                      <Grid item xs>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="paid_notice_email_from_name"
                          label="メール送信者名"
                          helperText="メール送信者名とメール送信アドレスの両方が空の場合、システムのデフォルトの送信者名とメールアドレスが使用されます。"
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="paid_notice_email_from_address"
                          label="メール送信アドレス"
                          helperText="メール送信者名とメール送信アドレスの両方が空の場合、システムのデフォルトの送信者名とメールアドレスが使用されます。"
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="paid_notice_email_subject"
                          label="メール件名"
                          required
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          multiline
                          rows={10}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="paid_notice_email_contents"
                          label="メール本文"
                          required
                        />
                      </Grid>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
          <Grid item container justify="flex-end" className={classes.btnRoot} spacing={2}>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                fullWidth
                disabled={pristine || submitting}
                onClick={form.reset}
              >
                リセット
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={pristine || submitting || invalid}
              >
                登録
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export const validate = (stripeIsNone, payjpIsNone, dictionary, admin) => values => {
  const errors = {}
  const [required, url] = [true, true]
  const inputs = [
    { name: 'name', required, max: 250 },
    { name: 'lp_master_id', required },
    { name: 'myasp_url', url },
    { name: 'line_at_url', url },
    { name: 'other_url', url },
    { name: 'paypal_payment_url', url },
  ]

  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      case input.url && val && !REGEX_URL.test(val):
        errors[key] = `URLを入力して下さい`
        break
      default:
        break
    }
  }

  if (admin) {
    if (!values.user_id) {
      errors['user_id'] = '選択してください'
    } else {
      // ユーザーが指定されている
      const { lpmaster } = dictionary
      if (_.isEmpty(_.filter(lpmaster, item => {
        // 選ばらているLPマスターは「公開」または「自身が所有している」
        return values.lp_master_id === item.id && (item.user.id === values.user_id || item.scope === 1)
      }))) {
        errors['lp_master_id'] = '選択されている所有者では使用できません'
      }
    }
  }

  const { payment_service_link_flag, payment_service, paypal_payment_url, stripe_api_id, payjp_api_id } = values
  if (payment_service_link_flag && payment_service_link_flag === '1') {
    if (!payment_service) {
      errors['payment_service'] = '必須項目を選択して下さい'
    } else if (payment_service === 'paypal' && !paypal_payment_url) {
      errors['paypal_payment_url'] = '必須項目を入力して下さい'
    } else if (payment_service === 'stripe') {
      if (!stripe_api_id) errors['stripe_api_id'] = '必須項目を入力して下さい'
      if (stripeIsNone) errors['payment_service'] = 'APIが設定されていないため登録できません'
    } else if (payment_service === 'payjp') {
      if (!payjp_api_id) errors['payjp_api_id'] = '必須項目を入力して下さい'
      if (payjpIsNone) errors['payment_service'] = 'APIが設定されていないため登録できません'
    }
  }

  const { paid_notice_email_notice_mode, paid_notice_email_subject, paid_notice_email_contents, paid_notice_email_from_address } = values
  if (paid_notice_email_notice_mode && paid_notice_email_notice_mode === '1') {
    if (!paid_notice_email_subject) errors['paid_notice_email_subject'] = '必須項目を入力して下さい'
    if (!paid_notice_email_contents) errors['paid_notice_email_contents'] = '必須項目を入力して下さい'
    if (paid_notice_email_from_address && !REGEX_EMAIL.test(values.paid_notice_email_from_address))
      errors['paid_notice_email_from_address'] = '不正なメールアドレスです'
  }

  return errors
}

const mapStateToProps = state => ({
  auth: state.auth, dictionary: {
    user: state.user.dictionary,
    lpmaster: state.lpmaster.dictionary,
    mailtemplate: state.mailtemplate.dictionary,
  },
  stripe: state.stripe, payjp: state.payjp,
})
const mapDispatchToProps = ({
  storeTarget, getDictionary, getUserDictionary, getStripeList, getPayjpList, setEditStripe, setEditPayjp, getMailTemplate
})

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(Store))
