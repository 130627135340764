import { SET_NAVI_OPEN } from "../actions/navi";

const initialState = {
  open: true,
}

export default (state = initialState, action) => {
  if (action.type === SET_NAVI_OPEN) {
    return { ...state, open: action.open }
  }
  return state
}