import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import AdminRoute from './AdminRoute';
import OemAdminRoute from "./OemAdminRoute";
import GuestRoute from './GuestRoute';
import PropTypes from 'prop-types'
import { SnackbarProvider } from 'notistack';

import { setLoading } from '../../actions/loading';
import { tryAuth } from '../../actions/auth';
import {
  PATH_HOME,
  PATH_LOGIN,
  PATH_USER_STORE,
  PATH_USER_EMAIL_VERIFY,
  PATH_USER_PASSWORD_RESET,
  PATH_USER_PASSWORD_RESET_EMAIL,
  PATH_ADMIN_USER,
  PATH_ADMIN_USER_STORE,
  PATH_ADMIN_USER_UPDATE,
  PATH_USER_UPDATE,
  PATH_LEAVED,
  PATH_LPMASTER,
  PATH_LPMASTER_STORE,
  PATH_LPMASTER_UPDATE,
  PATH_LP,
  PATH_LP_STORE,
  PATH_LP_UPDATE,
  PATH_ANALYTICS,
  PATH_ADMIN_OEM,
  PATH_ADMIN_OEM_UPDATE,
  PATH_BANK_UPDATE,
  PATH_SETTLEMENT_UPDATE,
  PATH_MAIL_TEMPLATE,
  PATH_MAIL_TEMPLATE_UPDATE,
  PATH_MAIL_TEMPLATE_STORE,
  PATH_LP_UPLOAD,
} from './Path';

import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import SignIn from "../pages/SignIn";
import NotFound from '../pages/404';
import Home from "../pages/Home";
import UserStore from "../pages/user/Store";
import UserUpdate from "../pages/user/Update";
import EmailVerify from "../pages/user/EmailVerify";
import PasswordResetRequest from "../pages/user/PasswordResetRequest";
import PasswordReset from '../pages/user/PasswordReset';
import BankUpdate from '../pages/bank/Update'
import SettlementUpdate from '../pages/settlement/Update'

import LPIndex from '../pages/lp/Index';
import LPStore from '../pages/lp/Store';
import LPUpdate from '../pages/lp/Update';
import LPUpload from '../pages/lp/Upload';
import LpMasterIndex from '../pages/lp/management/Index';
import LpMasterStore from '../pages/lp/management/Store';
import LpMasterUpdate from '../pages/lp/management/Update';

import AnalyticsIndex from '../pages/analytics/Index';

import MailTemplateIndex from '../pages/mailtemplate/Index';
import MailTemplateStore from '../pages/mailtemplate/Store';
import MailTemplateUpdate from '../pages/mailtemplate/Update';

import AdminUserIndex from '../pages/admin/user/Index';
import AdminUserStore from '../pages/admin/user/Store';
import AdminUserUpdate from '../pages/admin/user/Update';
import AdminOemIndex from '../pages/admin/oem/Index';
import AdminOemUpdate from '../pages/admin/oem/Update';

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { APP_NAME } from '../../constraints/commons';
import Leaved from '../pages/user/Leaved';
import { Helmet } from 'react-helmet';
import { Backdrop, CircularProgress } from '@material-ui/core';
import ErrorMessage from '../ErrorMessage';

const theme = responsiveFontSizes(createMuiTheme());

const propTypes = {
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  tryAuth: PropTypes.func.isRequired
};

class App extends Component {
  componentDidMount() {
    this.props.tryAuth(() => { this.props.setLoading(false) });
  }

  render() {
    const { err: { timeout, error }, loading } = this.props;
    if (loading) {
      return (
        <React.Fragment>
          <Helmet>
            <meta name="description" content={APP_NAME} />
            <title>{APP_NAME}</title>
          </Helmet>
          <CssBaseline />
          <Grid container alignItems={"center"} justify={"center"} style={{ minHeight: '100vh' }}>
            <Grid item>
              <Backdrop open={!!!(timeout || error)}>
                <CircularProgress />
              </Backdrop>
              {
                (timeout || error)
                &&
                <div>
                  <Typography component="div" variant={"h4"}>
                    セッションが切断されました。ログインし直して下さい。
                  </Typography>
                  <a href={PATH_LOGIN}>ログインはこちら</a>
                </div>
              }
            </Grid>
          </Grid>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Helmet>
          <meta name="description" content={APP_NAME} />
          <title>{APP_NAME}</title>
        </Helmet>
        <CssBaseline />
        <Router>
          <MuiThemeProvider theme={theme}>
            <SnackbarProvider max={5}>
              <ErrorMessage />
              <Switch>
                <GuestRoute exact path={PATH_LOGIN} component={SignIn} />
                <GuestRoute exact path={PATH_USER_STORE} component={UserStore} />
                <GuestRoute exact path={PATH_USER_PASSWORD_RESET_EMAIL} component={PasswordResetRequest} />
                <GuestRoute exact path={PATH_USER_PASSWORD_RESET} component={PasswordReset} />

                <Route exact path={PATH_LEAVED} component={Leaved} />

                <AuthRoute exact path={PATH_USER_EMAIL_VERIFY} component={EmailVerify} />
                <AuthRoute exact path={PATH_USER_UPDATE} component={UserUpdate} />
                <AuthRoute exact path={PATH_ANALYTICS} component={AnalyticsIndex} />
                <AuthRoute exact path={PATH_BANK_UPDATE} component={BankUpdate} />
                <AdminRoute exact path={PATH_SETTLEMENT_UPDATE} component={SettlementUpdate} />

                {/* LPマスター管理 */}
                <AdminRoute exact path={PATH_LPMASTER} component={LpMasterIndex} />
                <AdminRoute exact path={PATH_LPMASTER_STORE} component={LpMasterStore} />
                <AdminRoute exact path={PATH_LPMASTER_UPDATE} component={LpMasterUpdate} />

                {/* LP管理 */}
                <AuthRoute exact path={PATH_LP} component={LPIndex} />
                <AdminRoute exact path={PATH_LP_STORE} component={LPStore} />
                <AdminRoute exact path={PATH_LP_UPLOAD} component={LPUpload} />
                <AdminRoute exact path={PATH_LP_UPDATE} component={LPUpdate} />

                {/* ユーザー管理 */}
                <AdminRoute exact path={PATH_ADMIN_USER} component={AdminUserIndex} />
                <AdminRoute exact path={PATH_ADMIN_USER_STORE} component={AdminUserStore} />
                <AdminRoute exact path={PATH_ADMIN_USER_UPDATE} component={AdminUserUpdate} />

                {/* OEM管理 */}
                <OemAdminRoute exact path={PATH_ADMIN_OEM} component={AdminOemIndex} />
                <OemAdminRoute exact path={PATH_ADMIN_OEM_UPDATE} component={AdminOemUpdate} />

                {/* メールテンプレート管理 */}
                <AdminRoute exact path={PATH_MAIL_TEMPLATE} component={MailTemplateIndex} />
                <AdminRoute exact path={PATH_MAIL_TEMPLATE_STORE} component={MailTemplateStore} />
                <AdminRoute exact path={PATH_MAIL_TEMPLATE_UPDATE} component={MailTemplateUpdate} />

                <AuthRoute exact path={PATH_HOME} component={Home} />
                <Route component={NotFound} />
              </Switch>
            </SnackbarProvider>
          </MuiThemeProvider>
        </Router>
      </React.Fragment>
    );
  }
}

App.propTypes = propTypes;

const mapDispatchToProps = {
  setLoading,
  tryAuth
};

const mapStateToProps = ({ loading, error }) => ({ loading, err: error });

export default connect(mapStateToProps, mapDispatchToProps)(App);
