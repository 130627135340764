import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { withStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import { Avatar, Box, Typography } from '@material-ui/core';
import Copyright from './Copyright';

const propTypes = {
  authenticated: PropTypes.bool.isRequired
};

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.dark,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});


const NotFound = (props) => {
  const { classes } = props
  return (
    <Container component="main" maxWidth="lg">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <ErrorOutlineIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          ページが見つかりません
        </Typography>
        <Typography component="div" variant="body1">
          あなたは何も間違ったことはしていません。お探しのページを別の場所に移動させたかもしれません。
        </Typography>
      </div>
      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>
  )
}

NotFound.propTypes = propTypes;
const mapStateToProps = ({ auth: { authenticated } }) => ({ authenticated });
export default connect(mapStateToProps)(
  withStyles(styles)(NotFound)
);
