import React from 'react';

// import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';

import { connect } from 'react-redux'
import { Card, CardContent, Grid } from '@material-ui/core';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import _ from "lodash"
import { PATH_BANK_UPDATE } from '../router/Path';
import LinkRouter from '../router/LinkRouter'
import { APP_NAME, IS_LABASP } from '../../constraints/commons';

export const PAGE_NAME = 'ダッシュボード';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
  },
  sample: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  cardContent: {
    paddingTop: theme.spacing(3),
  },
  pre: {
    marginTop: 0
  }
});

const Home = props => {
  const { classes, auth: { user: { bank_account } } } = props
  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="primary">{PAGE_NAME}</Typography>
      </Breadcrumbs>
      <div className={classes.root}>
        <Grid container direction="column" spacing={1}>
          {
            _.isEmpty(bank_account) &&
            <Grid item>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Typography component="div" variant="body2">
                    報酬受取口座が未設定の為、現在報酬のお支払いができません。<LinkRouter color="primary" to={PATH_BANK_UPDATE}>こちら</LinkRouter>から口座情報のご登録をお願いします。
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          }
          <Grid item>
            <Card>
              <CardContent className={classes.cardContent}>
                <Typography component="h1" variant="h5" className={classes.title}>
                  {APP_NAME}をご利用いただきありがとうございます。
                </Typography>
                <Typography component="p" variant="body1">
                  「案件管理」からは、アフィリエイトできる案件一覧を見たり、<br />
                  自分専用のアフィリエイト用LPのURLを利用することが可能です。<br />
                  専用LPの発行には、アカウント制作から2～3営業日頂くことがございますので、<br />
                  予めご了承くださいませ。
                </Typography>
                <Typography component="p" variant="body1" style={{ paddingTop: '12px' }}>
                  「成果情報」からは、アフィリエイトで獲得した成果（仮確定）の情報を<br />
                  日別で見たり、期間を指定して検索したりすることが可能です。
                </Typography>
                {
                  IS_LABASP &&
                  <Typography component="p" variant="body1" style={{ paddingTop: '12px' }}>
                    詳しい利用方法に関しては
                    <a target="_blanck" rel="noopener noreferrer" href="https://ge-na.net/members/?p=20317">こちら</a>
                    をご確認ください。
                  </Typography>
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = ({});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(Home)
);
