import React from 'react'
import { storeTarget } from "../../../actions/user"

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Form } from 'react-final-form'
import { Checkboxes, TextField } from 'mui-rff'

import { connect } from 'react-redux'
import { IS_LABASP, REGEX_EMAIL, REGEX_PASSWORD } from '../../../constraints/commons'
import { PATH_LOGIN } from "../../router/Path"
import { sleep } from "../../../helpers/common"
import Copyright from "../Copyright"

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
  scroll: {
    padding: theme.spacing(2,2,2),
    height: '200px',
    overflowY: 'scroll',
    border: 'solid 1px rgb(224,224,224)',
  },
})

class Store extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stored: false
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit(values) {
    values['authority'] = 0
    values['verify_email_send'] = 1
    values['info_email_send'] = 1
    await this.props.storeTarget(values, () => { this.setState({ stored: true }) })
    await sleep(1000)
  }

  render() {
    const { classes } = this.props
    const { stored } = this.state
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <AccountCircleIcon />
          </Avatar>
          <Typography component="div" variant="h5">
            アカウント作成
          </Typography>
          {
            stored ?
              <Typography component="p" variant="h6">
                ご登録ありがとうございます！<br />
                確認メールを送信いたしましたので、ご確認をお願いいたします。
              </Typography>
              :
              <Form
                onSubmit={this.onSubmit}
                validate={validate}
                render={formRender(classes)}
              />
          }
          <Button
            type="button"
            variant="text"
            onClick={() => { this.props.history.push(PATH_LOGIN) }}
          >
            ログインはこちら
          </Button>
        </div>
        <Box mt={2}>
          <Copyright />
        </Box>
      </Container>
    )
  }
}

const formRender = classes => ({ form, handleSubmit, pristine, submitting, invalid }) => {
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
          />
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="last_name"
              label="姓"
              name="last_name"
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="first_name"
              label="名"
              name="first_name"
            />
          </Grid>
        </Grid>
        {
          IS_LABASP &&
          <React.Fragment>
            <Grid item>
              <div className={classes.scroll}>
                <Typography component="div" variant="h6" align="center">
                  <strong>顧客紹介規約</strong>
                </Typography>
                <Typography component="p" variant="body2">
                  本規約は、ARC.LAB PTE.LTD.（以下、「甲」という。）と甲の実施する本サービスについて顧客を紹介し本件勧誘行為を行おうとする者（以下、「乙」という。）との間で締結される本件サービスに係る顧客の紹介に係る契約（以下、「本契約」という。）の内容を定めたものです。
                </Typography>
                <Typography component="p" variant="body2">第1条    	（定義）</Typography>
                <Typography component="p" variant="body2">
                  1.  	本契約書において使用する用語の定義は、各条項に個別に定義するもののほか、下記各号のとおり定める。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑴ 	「GeNa」とは、甲が『GeNa』の名称で提供するオンラインサロンを主体とするコミュニケーションサービスをいう。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑵ 	「GeNaPLUS」とは、甲が『GeNaPLUS』の名称で提供するオンラインサロンを主体とするコミュニケーションサービスであって、GeNaの会員資格を前提とする上位版サービスをいう。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑶ 	「本件サービス」とは、GeNa又はGeNaPLUSをいう。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑷ 	「顧客」とは、甲との間で本件サービスに係る利用契約を締結し会員となったものをいう。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑸ 	「見込顧客」とは、顧客となろうとするものであって、本件勧誘行為以前に顧客であったことのないものをいう。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑹ 	「顧客等」とは顧客又は見込顧客をいう。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑺ 	「顧客契約」とは、甲と顧客の間で締結されるGeNa又はGeNaPLUSのサービスに係る利用契約をいう。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑻ 	「本件勧誘行為」とは、乙が行う顧客契約の締結を誘因する勧誘行為をいう。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑼ 	「GeNa利用規約」とは、GeNaにおいて、顧客に適用される利用規約等の一切をいう。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑽ 	「GeNaPLUS利用規約」とは、GeNaPLUSにおいて、顧客に適用される利用規約等の一切をいう。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑾ 	「本件利用規約」とは、GeNa利用規約又はGeNaPLUS利用規約をいう。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑿ 	「GeNa利用料金」とは、顧客が甲に対し、顧客契約においてGeNaサービスの対価として支払義務を負う月額の利用料金をいう。月額利用料金以外の報酬等は含まれない。
                </Typography>
                <Typography component="p" variant="body2">
                  ⒀ 	「GeNaPLUS利用料金」とは、顧客が甲に対し、顧客契約においてGeNaPLUSサービスの対価として支払義務を負う月額の利用料金をいう。月額利用料金以外の報酬等は含まれない。
                </Typography>
                <Typography component="p" variant="body2">
                  ⒁ 	「本件利用料金」とは、「GeNa利用料金」又は「GeNaPLUS利用料金」をいう。
                </Typography>
                <Typography component="p" variant="body2">
                  第2条    	（本規約の目的・適用・変更）
                </Typography>
                <Typography component="p" variant="body2">
                  1.  	本規約は、甲と乙との間で締結される本件サービスに係る顧客の紹介に係る契約の内容を定めたものであり、乙が甲に対して本契約の申込をした時点で、乙は、本規約の全ての条件を承諾したものとみなされる。本規約に承諾しない場合は、本契約を締結することはできない。
                </Typography>
                <Typography component="p" variant="body2">
                  2.  	本規約の内容は随時必要に応じて改定され、改定された本規約が弊社Webページ等に掲載された日の翌日から乙に対し改定後の本規約の内容が適用される。ただし、変更に係る期間の定めが明示されている場合に限っては、変更の効果は当該期間が経過した時点で発生する。
                </Typography>
                <Typography component="p" variant="body2">
                  第3条    	（本件勧誘行為）
                </Typography>
                <Typography component="p" variant="body2">
                  甲は、乙に対し、見込顧客に対し、本件勧誘行為を行うことを委託する。
                </Typography>
                <Typography component="p" variant="body2">
                  第4条    	（本件紹介料）
                </Typography>
                <Typography component="p" variant="body2">
                  1.  	下記各号の全てに該当する場合（以下、「本件紹介継続の場合」という）は、甲は、乙に対し、次条の定めに従い、当該顧客にかかる次項に定める紹介料（以下、「本件紹介料」という）を支払う。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑴ 	乙の本件勧誘行為によって乙専用として付与される申込ページから見込顧客が顧客契約を申し込み顧客となったこと（以下、その場合を「成約」といい、その場合の顧客を「成約顧客」という）
                </Typography>
                <Typography component="p" variant="body2">
                  ⑵ 	成約顧客において本件利用料金の未払がないこと
                </Typography>
                <Typography component="p" variant="body2">
                  ⑶ 	締日において成約顧客が顧客契約を継続中であること（顧客契約終了後、再度顧客契約を締結した場合は本号の要件を満たさない。）
                </Typography>
                <Typography component="p" variant="body2">
                  ⑷ 	本契約が終了（解除、解約を含む）していないこと
                </Typography>
                <Typography component="p" variant="body2">
                  2.  	本件紹介料の割合は下記各号のとおり定める。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑴ 	GeNaにかかる本件紹介継続の場合
                </Typography>
                <Typography component="p" variant="body2">
                  ア　GeNa会員継続３か月目まで：成約顧客のGeNa利用料金の１５％
                </Typography>
                <Typography component="p" variant="body2">
                  イ　GeNa会員継続４ヶ月目以降：成約顧客のGeNa利用料金の２０％
                </Typography>
                <Typography component="p" variant="body2">
                  ⑵ 	GeNaPLUSにかかる本件紹介継続の場合：成約顧客のGeNaPLUS利用料金の１０％
                </Typography>
                <Typography component="p" variant="body2">
                  3.  	前項に定める本件紹介料の割合は、本件紹介料の割合が変更された本規約が弊社Webページ等に掲載された日（以下、「本件変更日」という）から2週間が経過した日以降の紹介料の割合を甲の任意に変更することができる。
                </Typography>
                <Typography component="p" variant="body2">
                  4.  	前項による紹介料の変更の効果は、次項及び但し書の場合を除き本件変更日以前に顧客契約が締結された成約顧客にかかる紹介料には適用されず、本件変更日以降に顧客契約が締結された成約顧客にかかる紹介料にのみ適用される
                </Typography>
                <Typography component="p" variant="body2">
                  5.  	本条の内容は、本条の変更に係る内容が弊社Webページ等に掲載された日から２か月が経過した日から効力を有する変更として、甲の任意に変更することが出来る。
                </Typography>
                <Typography component="p" variant="body2">
                  第5条    	（本件紹介料の支払方法）
                </Typography>
                <Typography component="p" variant="body2">
                  1.  	甲は、乙に対し、本件紹介料から振込手数料その他の諸経費として支払１回あたり金５００円を差し引いた額を、当月末締め翌月末限り、乙の指定する銀行預金口座に振り込む方法にて支払う。
                </Typography>
                <Typography component="p" variant="body2">
                  2.  	前項にかかわらず、当月末時点の本件紹介料が消費税込合計５０００円未満の場合、当該本件紹介料の支払は翌月末以降に繰り越され、繰り越された本件紹介料と翌期以降に発生する本件紹介料とが合算され消費税込合計５０００円以上となった締日の翌月末限り支払われる。
                </Typography>
                <Typography component="p" variant="body2">
                  3.  	前各項にかかわらず、本契約が終了した場合、本契約終了時点で消費税込合計５０００円未満の本件紹介料は当然に消滅し、甲は乙に対し支払義務を負わない。
                </Typography>
                <Typography component="p" variant="body2">
                  6.  	本件紹介料の支払方法は、甲の乙に対する通知をもって、通知の日から２週間が経過した日以降の支払方法を甲の任意に変更することができる。
                </Typography>
                <Typography component="p" variant="body2">
                  第6条    	（確認事項）
                </Typography>
                <Typography component="p" variant="body2">
                  1.  	本契約は甲と乙との関係において、雇用、共同事業者、フランチャイズ、本人と代理人との関係などを創設するものではない。
                </Typography>
                <Typography component="p" variant="body2">
                  2.  	甲は、本契約締結後であっても、乙の承諾なく、本件サービス又は本件利用規約の内容等を変更し、本件サービスの全部又は一部の提供を終了することができる。
                </Typography>
                <Typography component="p" variant="body2">
                  3.  	本件利用規約において、本契約と矛盾若しくは抵触する定めがある場合は、本件利用規約の定めが本契約の定めに優先して適用される。
                </Typography>
                <Typography component="p" variant="body2">
                  4.  	甲が本契約において乙に与える本件勧誘行為その他の許諾は、日本国内に限定される非独占的な許諾にとどまり、甲は乙以外の任意の第三者と本契約類似の契約を締結することができる。
                </Typography>
                <Typography component="p" variant="body2">
                  5.  	乙は、あらかじめ甲の署名押印のある書面による承諾を得た場合を除いては、本契約等に基づく乙の地位又は権利の全部若しくは一部の譲渡、担保権の設定その他第三者に権利を処分する一切の行為（事業承継等組織変更を含む）をすることはできない。
                </Typography>
                <Typography component="p" variant="body2">
                  6.  	乙は、本件勧誘行為を第三者に再委託することはできない。
                </Typography>
                <Typography component="p" variant="body2">
                  第7条    	（本件勧誘行為における禁止行為）
                </Typography>
                <Typography component="p" variant="body2">
                  乙は、本件勧誘行為に際して、下記各号に定める行為を行ってはならない。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑴ 	顧客等に対し、本件利用規約と異なる内容を説明すること又は内容を正確に説明しないこと
                </Typography>
                <Typography component="p" variant="body2">
                  ⑵ 	顧客等に対し、本件利用規約と異なる条件で顧客契約を締結することが可能である旨誤信させ、又はそのおそれのある行為、表示、告知等を行うこと
                </Typography>
                <Typography component="p" variant="body2">
                  ⑶ 	顧客等に対し、収益の確約等、本件サービスによる効用を確約、保証し又は確約、保証する旨誤信させるおそれのある行為、表示、告知等を行うこと
                </Typography>
                <Typography component="p" variant="body2">
                  ⑷ 	顧客等に対し、乙が甲の従業員、共同事業者、フランチャイズ又は代理人であるかのような誤認を生ぜしめるおそれのある行為、表示、告知等を行うこと
                </Typography>
                <Typography component="p" variant="body2">
                  ⑸ 	甲の名誉、信用を毀損する行為、その他甲の営業を妨げる行為
                </Typography>
                <Typography component="p" variant="body2">
                  ⑹ 	法令等に違反する行為又はこれを助長する行為
                </Typography>
                <Typography component="p" variant="body2">
                  ⑺ 	顧客等から金銭その他の利益を受け取り又は顧客等に対し金銭その他の利益を供与する行為
                </Typography>
                <Typography component="p" variant="body2">
                  ⑻ 	信用状況・財政状況が悪く利用料金等の円滑な回収が不可能となることが明白なものを勧誘すること
                </Typography>
                <Typography component="p" variant="body2">
                  ⑼ 	その他、上記各号に類する行為
                </Typography>
                <Typography component="p" variant="body2">
                  第8条    	（競業避止義務）
                </Typography>
                <Typography component="p" variant="body2">
                  1.  	乙（子会社、分割会社等を含む）は、本契約期間中及び本契約終了後１年間にわたり、甲があらかじめ署名押印のある書面による許可をした場合を除いては、下記行為を行うことができない。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑴ 	本件サービスと類似のサービスを提供する事業を行うことその他甲と競合関係に立つ事業を行うこと。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑵ 	本件サービスと類似のサービスを提供する事業その他甲と競合関係に立つ事業を行う企業（以下、「競合企業」という。）と事業提携等を行うこと。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑶ 	競合企業に在籍、就職若しくは役員に就任すること。
                </Typography>
                <Typography component="p" variant="body2">
                  2.  	乙は、前項の義務を遵守するため、本契約に関与する自己の役員、従業員等（派遣社員、アルバイト、退職者等を含む）に対し、前項と同等の競業避止義務を課すものとし、当該役員、従業員等がこれに違反したときは、自らがこれに違反したものとみなされる。
                </Typography>
                <Typography component="p" variant="body2">
                  第9条    	（秘密保持義務）
                </Typography>
                <Typography component="p" variant="body2">
                  1.  	「秘密情報」とは、本件サービスに係るノウハウ、顧客情報、開発予定・開発中・完成後の別を問わない機器・プログラム等の技術情報・技術資料、販売計画・事業計画等第三者に漏洩されれば開示者の損失となる技術上・営業上その他の一切の情報であって、本契約の締結日から本契約が終了するまでの期間中に本契約に関して開示者から提供を受け又は自ら知りえた（以下、提供を受け又は自ら知ることを「開示等を受けた」という。また、開示等を受けた本契約当事者を「被開示者」という。）一切の情報の内、既に公知公用の情報、開示等を受けたあと、被開示者の責めによらず公知公用となった情報、開示等を受けた時に、被開示者において既に知得していた情報、開示を受けた後、正当な権限を有する第三者より守秘義務を負うことなしに入手した情報、法令により、更に守秘義務を負わせることなく且つ無制限に、公に開示することが義務づけられた情報、被開示者が、開示された情報に一切アクセスせず、それと無関係に開発、創作した情報又は甲及び乙が秘密情報から除かれることを相互に確認した情報を除いたものをいう。
                </Typography>
                <Typography component="p" variant="body2">
                  2.  	乙は、本契約の存続期間中は勿論、本契約が終了した後においても、本契約に基づき相手方から提供を受けた秘密情報及び自ら知りえた相手方の秘密情報について、これを厳重に管理し秘密を厳守し、相手方の書面による承諾を得ることなく、本契約の目的以外のために、これを自ら使用し、又は第三者に開示・漏洩・使用させてはならない。
                </Typography>
                <Typography component="p" variant="body2">
                  第10条 	（違反行為に対する停止措置等）
                </Typography>
                <Typography component="p" variant="body2">
                  1.  	甲は、乙に対し、随時、乙が本件勧誘行為等に使用する書面、電子データその他本件勧誘行為の履行状況に関する資料を提示するよう求めることができる。
                </Typography>
                <Typography component="p" variant="body2">
                  2.  	甲は、乙が前３条の義務に反し又は反するおそれがある場合その他甲が本件サービスの運用上問題があると認める場合、乙に対し、本件勧誘行為の停止または態様の変更を命じることができる。
                </Typography>
                <Typography component="p" variant="body2">
                  3.  	乙が前３条の義務を怠った場合又は前項の停止命令に反し本件勧誘行為を行った場合、乙は甲に対し当該債務不履行に基づく損害賠償とは別に金５０万円の違約金支払義務及びそれまでに受領した本件紹介料の返還義務を負い、甲は乙に対する本件紹介料の支払義務を免れる。
                </Typography>
                <Typography component="p" variant="body2">
                  4.  	乙が前３条の義務を怠った場合又は前項の停止命令に反し本件勧誘行為を行った場合、当該義務懈怠により発生した紛争の一切は、乙の責任において解決にあたり、当該義務懈怠に起因して甲が負担した金銭の一切は乙が負担する。
                </Typography>
                <Typography component="p" variant="body2">
                  第11条 	（費用）
                </Typography>
                <Typography component="p" variant="body2">
                  広告費、交通費、通信費、印刷費、租税その他本件勧誘行為に係る一切の経費は、乙が負担する。
                </Typography>
                <Typography component="p" variant="body2">
                  第12条 	（免責）
                </Typography>
                <Typography component="p" variant="body2">
                  1.  	甲は、顧客契約の解約、停止、終了、契約条件の変更又は本件サービスの提供不能、停止、終了その他の原因による本件利用料金の不発生、減額、機会喪失等に伴ういかなる損害に関しても乙及び第三者に対し一切の補償・損害賠償の責任を負わない。
                </Typography>
                <Typography component="p" variant="body2">
                  2.  	理由の如何を問わず本件利用料の支払がない場合（チャージバック等により本件利用料が顧客に返還された場合を含む）には本件紹介料は発生しない。
                </Typography>
                <Typography component="p" variant="body2">
                  3.  	その他、前項に類する場合において、甲は、乙及び第三者に対し一切の補償・損害賠償の責任を負わない。
                </Typography>
                <Typography component="p" variant="body2">
                  第13条 	（債務不履行）
                </Typography>
                <Typography component="p" variant="body2">
                  甲及び乙は、本契約に関して、特に免責を定める場合を除いては、自らの責めに帰すべき事由により相手方に損害を与えた場合、本契約の解除、終了にかかわらず、当該通常且つ直接の損害を賠償する。
                </Typography>
                <Typography component="p" variant="body2">
                  第14条 	（本契約の契約期間）
                </Typography>
                <Typography component="p" variant="body2">
                  1.  	本契約の有効期間（以下、「契約期間」という。）は、本契約締結の日から３か月間とする。
                </Typography>
                <Typography component="p" variant="body2">
                  2.  	前項の定めにかかわらず、甲又は乙の一方より相手方に対して契約期間満了日の前までに本契約を終了する旨の書面による意思表示がない場合、本契約は自動的に同条件で３か月間延長されるものとし、以後同様とする。
                </Typography>
                <Typography component="p" variant="body2">
                  3.  	本件サービスの提供が終了した場合、本件サービスの終了日をもって本契約は当然に終了する。
                </Typography>
                <Typography component="p" variant="body2">
                  4.  	本契約の初回の締結日から通算して６か月間成約がない場合又は乙にかかる最終成約から６か月間成約がない場合、６か月間が経過した日をもって本契約は当然に終了する。
                </Typography>
                <Typography component="p" variant="body2">
                  5.  	本契約の終了（解除、解約を含む）にかかわらず、本契約期間中の本件勧誘行為については、本契約の定めが適用される。
                </Typography>
                <Typography component="p" variant="body2">
                  第15条 	（甲による本契約の解除）
                </Typography>
                <Typography component="p" variant="body2">
                  1.  	甲は、下記各号のいずれかに該当したときは、何ら催告することなく直ちに本契約を解除することができる。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑴ 	第７条から第１０条に定める義務に違反した場合
                </Typography>
                <Typography component="p" variant="body2">
                  ⑵ 	顧客等から乙に関するクレームを受け、当該クレームの内容が相当と判断される場合
                </Typography>
                <Typography component="p" variant="body2">
                  ⑶ 	乙が本契約上の義務に著しく違反し甲乙の信頼関係が毀損された場合
                </Typography>
                <Typography component="p" variant="body2">
                  ⑷ 	本件サービスの提供が著しく困難になった場合
                </Typography>
                <Typography component="p" variant="body2">
                  ⑸ 	乙に支払の停止があったとき、仮差押、差押、競売、破産、民事再生手続、会社更生手続若しくは特別清算手続の申立があったとき又は租税滞納処分を受けたときその他これに類する信用状況・財政状況の著しい悪化又はそのおそれが認められるとき
                </Typography>
                <Typography component="p" variant="body2">
                  ⑹ 	その他前各号に類する場合
                </Typography>
                <Typography component="p" variant="body2">
                  2.  	甲は、下記各号のいずれかに該当したときは、催告の上、本契約を解除することができる。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑴ 	乙が本契約の義務に違反した場合
                </Typography>
                <Typography component="p" variant="body2">
                  ⑵ 	その他上記各号に類する場合
                </Typography>
                <Typography component="p" variant="body2">
                  第16条 	（反社会的勢力の排除）
                </Typography>
                <Typography component="p" variant="body2">
                  甲及び乙は、それぞれ相手方に対し、次の各号の事項を確約する。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑴ 	自らが、暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる者又はその構成（以下総称して「反社会的勢力」という）ではないこと。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑵ 	自らの役員（業務を執行する社員、取締役、執行役又はこれらに準ずる者をいう）が反社会的勢力ではないこと。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑶ 	反社会的勢力に自己の名義を利用させ、この契約を締結するものでないこと。
                </Typography>
                <Typography component="p" variant="body2">
                  ⑷ 	本物件の引渡し及び売買代金の全額の支払いのいずれもが終了するまでの間に、自ら又は第三者を利用して、この契約に関して相手方に対する脅迫的な言動又は暴力を用いる行為又は偽計又は威力を用いて相手方の業務を妨害し若しくは信用を毀損する行為をしないこと。
                </Typography>
                <Typography component="p" variant="body2">
                  第17条 	（専属的合意管轄）
                </Typography>
                <Typography component="p" variant="body2">
                  本契約に関連して訴訟、調停又は和解の必要が生じた場合には、東京地方裁判所又は東京簡易裁判所を専属管轄裁判所とする。
                </Typography>
                <Typography component="p" variant="body2">
                  第18条 	（分離可能性）
                </Typography>
                <Typography component="p" variant="body2">
                  本規約のいずれかの規定が関連法令に反する場合、当該規定は、その限りにおいて、当該乙との契約に限り適用されないものとする。ただし、この場合でも、本規約の他の規定や他の契約者との契約の効力には影響しないものとする。
                </Typography>
                <Typography component="p" variant="body2">
                </Typography>
                <Typography component="p" variant="body2" style={{ textAlign: 'right' }}>
                  制定　2020年12月1日
                </Typography>
              </div>
            </Grid>
            <Grid item container justify="center" spacing={0} style={{ paddingTop: '12px' }}>
              <Grid item>
                <Checkboxes
                  name="term"
                  required
                  data={[
                    { label: '利用規約に同意する', value: true }
                  ]}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        }
        <Grid item>
          <div style={{ paddingTop: '12px' }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={pristine || submitting || invalid}
              className={classes.submit}
            >
              登録
          </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

const validate = values => {
  const errors = {}
  const [required, email, password, term] = [true, true, true, true]
  const inputs = [
    { name: 'email', required, email },
    { name: 'password', required, password },
    { name: 'first_name', required, max: 100 },
    { name: 'last_name', required, max: 100 },
    { name: 'term', term },
  ]

  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.term && !val && IS_LABASP:
        errors[key] = 'チェックされていません'
        break
      case input.email && val && !REGEX_EMAIL.test(val):
        errors[key] = '不正なメールアドレスです'
        break
      case input.password && val && !REGEX_PASSWORD.test(val):
        errors[key] = '8文字以上の英数字で記号は、アンダースコア(_)、ハイフン(-)、ドット(.)、アットマーク(@)のみ使用できます'
        break
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      default:
        break
    }
  }

  return errors
}

const mapStateToProps = state => ({ err: state.error })
const mapDispatchToProps = ({ storeTarget })

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(Store))
