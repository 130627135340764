import React from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import Copyright from "./pages/Copyright";
import Container from "@material-ui/core/Container";

const styles = theme => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
});

const propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const Footer = props => {
  const { classes } = props;
  return (
    <div className={classes.footer}>
      <Container maxWidth="sm">
        <Copyright />
      </Container>
    </div>
  );
};

Footer.propTypes = propTypes;
const s = withStyles(styles)(Footer);
const w = withTheme(s);

export default connect(null, null)(withRouter(w));
