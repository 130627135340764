import React from 'react';
import _ from "lodash"
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  getTargets, setEditTarget, deleteTarget, setSearch
} from '../../../actions/lp'
import { getDictionary as getUserDictionary } from '../../../actions/user'
import { getDictionary as getLpMasterDictionary } from '../../../actions/lpmaster'
import { connect } from 'react-redux'
import { getUpdatePath, PATH_ADMIN_USER_UPDATE, PATH_HOME, PATH_LP_STORE, PATH_LP_UPDATE, PATH_LP_UPLOAD } from "../../router/Path";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import LinkRouter from "../../router/LinkRouter";
import Grid from "@material-ui/core/Grid";
import MaterialTable from 'material-table'
import DeleteIcon from '@material-ui/icons/Delete';
import { defaultLocalization, defaultOption } from "../../../helpers/material-table";
import { PAGE_NAME as PAGE_HOME } from "../Home";
import YesNoAlert from "../../modal/YesNoAlert";
import { IconButton, Paper, Tooltip } from '@material-ui/core';
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import Button from '@material-ui/core/Button'
import { Select } from 'mui-rff';
import { MenuItem } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import BackupIcon from '@material-ui/icons/Backup';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';
import CopyToClipBoard from 'react-copy-to-clipboard';
import { getLPURL, isAdmin } from '../../../helpers/common';
import { withSnackbar } from 'notistack'
import EditIcon from '@material-ui/icons/Edit';
import CodeIcon from '@material-ui/icons/Code';

export const PAGE_NAME = user => {
  return isAdmin(user) ? 'LP管理' : '案件管理'
};

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '70%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(1),
  },
  formPaper: {
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      deleteId: null,
      sort_num: 1,
    }
  }

  componentDidMount() {
    this.storeOnClick = this.storeOnClick.bind(this);
    this.uploadOnClick = this.uploadOnClick.bind(this);
    this.editOnClick = this.editOnClick.bind(this);
    this.materialTableData = this.materialTableData.bind(this);
    this.deleteOnClick = this.deleteOnClick.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSearch = this.onSearch.bind(this)
    this.addSearch = this.addSearch.bind(this)
    this.handleCopyLink = this.handleCopyLink.bind(this)
    this.handleCopyCode = this.handleCopyCode.bind(this)
    this.renderLPName = this.renderLPName.bind(this)
    this.renderButtons = this.renderButtons.bind(this)

    this.onSearch(this.props.targets.search);
    const { auth: { user } } = this.props
    if (isAdmin(user)) {
      this.props.getUserDictionary({})
      this.props.getLpMasterDictionary({})
    }
    defaultOption.search = false
    defaultOption.sorting = false
    defaultOption.draggable = false
  }

  storeOnClick() {
    this.props.history.push(PATH_LP_STORE);
  }

  uploadOnClick() {
    this.props.history.push(PATH_LP_UPLOAD);
  }

  editOnClick(event, rowData) {
    this.props.setEditTarget(rowData);
    this.props.history.push(getUpdatePath(rowData.id, PATH_LP_UPDATE));
  }

  deleteOnClick(event, rowData) {
    this.setState({ open: true, deleteId: rowData.id })
  }

  onDelete(result) {
    if (result) this.props.deleteTarget(this.state.deleteId)
    this.setState({ open: false, deleteId: null })
  }

  materialTableData() {
    return this.props.targets.items
  }

  addSearch() {
    this.setState({ sort_num: this.state.sort_num + 1 })
  }

  onSearch(values) {
    const _values = _.cloneDeep(values)
    this.props.setSearch(values)
    const keys = [
      'name'
    ]
    for (const key of keys) {
      if (!_values[key]) delete _values[key]
    }
    const { auth: { user } } = this.props
    if (isAdmin(user)) {
      _values['user_id'] = -1
      if (!_.isEmpty(values['user_id'])) _values['user_id'] = values['user_id'].join(',')
      if (!_.isEmpty(values['lp_master_id'])) _values['lp_master_id'] = values['lp_master_id'].join(',')
    }
    if (_.isEmpty(_values['sort'])) {
      delete _values['sort']
    } else {
      let s = []
      for (const sort of _values['sort']) {
        if (sort && 'order' in sort && 'key' in sort) {
          s.push(`${sort.key}=${sort.order}`)
        }
      }
      _values['sort'] = s.join(',')
      if (s.length) _values['sort'] = s.join(',')
      else delete _values['sort']
    }
    this.props.getTargets(_values)
  }

  handleCopyLink() {
    const option = { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, autoHideDuration: 2000 }
    this.props.enqueueSnackbar('コピーしました', option)
  }

  handleCopyCode() {
    const option = { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, autoHideDuration: 2000 }
    this.props.enqueueSnackbar('コピーしました', option)
  }

  renderUser(rowData) {
    if (!('user' in rowData && rowData.user)) {
      return (
        <div></div>
      )
    }
    return (
      <LinkRouter color="inherit" to={getUpdatePath(rowData.user.id, PATH_ADMIN_USER_UPDATE)}>
        {`${rowData.user.last_name} ${rowData.user.first_name}`}
      </LinkRouter>
    )
  }

  renderLPMaster(rowData) {
    if (!('lp_master' in rowData && rowData.lp_master)) {
      return (
        <div></div>
      )
    }
    return (
      <Typography>
        {`${rowData.lp_master.logical_name}`}
        {rowData.lp_master.scope === 1 && '【公開】'}
      </Typography>
    )
  }

  renderLPName(rowData) {
    return (
      <Typography component="div">{rowData.name}</Typography>
    )
  }

  renderButtons(rowData) {
    try {
      const { auth: { user } } = this.props
      return (
        <Grid container justify="flex-start" alignItems="center" spacing={1}>
          <Grid item>
            <CopyToClipBoard text={getLPURL(rowData.id_specific_string)}>
              <IconButton
                size="small"
                disabled={!isAdmin(user) && user.id !== rowData.user.id}
                onClick={this.handleCopyLink}
              >
                <Tooltip title={'LPのURLをコピーする'}>
                  <LinkIcon />
                </Tooltip>
              </IconButton>
            </CopyToClipBoard>
          </Grid>
          {
            isAdmin(user) &&
            <Grid item>
              <CopyToClipBoard text={rowData.analytics_tag}>
                <IconButton
                  size="small"
                  disabled={!isAdmin(user) && user.id !== rowData.user.id}
                  onClick={this.handleCopyCode}
                >
                  <Tooltip title={'MyASP用JavaScriptをコピーする'}>
                    <CodeIcon />
                  </Tooltip>
                </IconButton>
              </CopyToClipBoard>
            </Grid>
          }
        </Grid>
      )
    } catch {
      return (<div></div>)
    }
  }

  render() {
    const { classes, targets: { search }, auth: { user }, dictionary } = this.props;
    const { open, sort_num } = this.state;
    const reset = () => {
      this.props.setSearch(null)
      this.setState({ sort_num: 1 })
    }
    let actions = []

    let columns = [
      { title: 'ID', field: 'id' },
      { title: 'LPマスター名', render: this.renderLPMaster },
      { title: '案件名', render: this.renderLPName },
      { title: '最終更新日', field: 'updated_at' },
      { title: 'クリップボードにコピー', render: this.renderButtons },
    ]

    if (isAdmin(user)) {
      actions = [
        {
          icon: () => (<EditIcon color='inherit' />),
          tooltip: '編集',
          onClick: this.editOnClick,
        },
        {
          icon: 'add',
          tooltip: '追加',
          isFreeAction: true,
          onClick: this.storeOnClick
        },
        {
          icon: () => (<BackupIcon color='inherit' />),
          tooltip: 'CSVアップロード',
          isFreeAction: true,
          onClick: this.uploadOnClick
        },
        {
          icon: () => (<DeleteIcon color={'error'} />),
          tooltip: '削除',
          onClick: this.deleteOnClick,
        },
      ]
      columns = [
        { title: 'ID', field: 'id' },
        { title: 'ユーザー名', render: this.renderUser },
        { title: 'LPマスター名', render: this.renderLPMaster },
        { title: '発行LP名', render: this.renderLPName },
        { title: '最終更新日', field: 'updated_at' },
        { title: 'クリップボードにコピー', render: this.renderButtons, },
      ]
    }

    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME(user)}</Typography>
        </Breadcrumbs>
        <Grid container justify="center" direction="column" className={classes.root}>
          <Grid item xs>
            <Form
              onSubmit={this.onSearch}
              initialValues={search}
              validate={validate}
              render={formRender(classes, reset, sort_num, this.addSearch, isAdmin(user), dictionary)}
            />
          </Grid>
        </Grid>
        <MaterialTable
          columns={columns}
          components={{
            Container: props => {
              return (
                <Paper {...props} elevation={0} variant="outlined" />
              )
            }
          }}
          data={this.materialTableData()}
          title={isAdmin(user) ? 'LP一覧' : '案件一覧'}
          actions={actions}
          options={defaultOption}
          localization={defaultLocalization}
        />
        <YesNoAlert
          open={open}
          title={'確認'}
          message={'本当に削除しますか？'}
          handleClose={this.onDelete}
        />
      </React.Fragment>
    );
  }
}

const formRender = (classes, reset, sort_num, add, admin, dictionary) => ({ handleSubmit, submitting, invalid }) => {
  return (
    <Paper className={classes.formPaper} elevation={0} variant="outlined">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>検索条件</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form method="post" className={classes.form} onSubmit={handleSubmit}>
            <Grid item container direction="column" spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="name"
                    label={`${admin ? '発行LP名' : '案件名'}（部分一致）`}
                    name="name"
                  />
                </Grid>
                <Grid item xs>
                  {
                    (admin && !_.isEmpty(dictionary.user)) &&
                    <Select
                      name="user_id"
                      label="ユーザーID"
                      formControlProps={{ margin: 'dense', variant: "outlined" }}
                      multiple
                    >
                      {
                        dictionary.user.map((row, i) => (
                          <MenuItem value={row.id} key={`user_id_${row.id}`}>{`${row.last_name} ${row.first_name}`}</MenuItem>
                        ))
                      }
                    </Select>
                  }
                </Grid>
                <Grid item xs>
                  {
                    (admin && !_.isEmpty(dictionary.lp)) &&
                    <Select
                      name="lp_master_id"
                      label="LPマスターID"
                      formControlProps={{ margin: 'dense', variant: "outlined" }}
                      multiple
                    >
                      {
                        dictionary.lp.map((row, i) => (
                          <MenuItem value={row.id} key={`lp_master_${row.id}`}>{`${row.logical_name}`}</MenuItem>
                        ))
                      }
                    </Select>
                  }
                </Grid>
              </Grid>
              {
                _.map([...Array(sort_num).keys()].map(i => ++i), item => {
                  return (
                    <Grid item container spacing={2} key={`sort${item}`} alignItems="center">
                      <Grid item xs>
                        <Select name={`sort[${item}][key]`} label={`ソート条件${item}:列`} formControlProps={{ margin: 'dense', variant: "outlined" }}>
                          {
                            [
                              { name: 'ID', val: 'id' },
                              { name: '発行LP名', val: 'name' },
                              { name: '更新日時', val: 'updated_at' },
                            ].map((row, i) => (
                              <MenuItem value={row.val} key={`col${item}${i}`}>{row.name}</MenuItem>
                            ))
                          }
                        </Select>
                      </Grid>
                      <Grid item xs>
                        <Select name={`sort[${item}][order]`} label={`ソート条件${item}:並び順`} formControlProps={{ margin: 'dense', variant: "outlined" }}>
                          <MenuItem value="asc">昇順</MenuItem>
                          <MenuItem value="desc">降順</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs>
                        {
                          (item === 1 && sort_num < 5) &&
                          <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            onClick={add}
                          >ソート追加</Button>
                        }
                      </Grid>
                    </Grid>
                  )
                })
              }
              <Grid item container justify="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={submitting}
                    onClick={reset}
                  >
                    リセット
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={submitting || invalid}
                  >
                    検索
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}

const validate = values => {
  const errors = {}
  const inputs = [
    { name: 'name', max: 250 },
  ]
  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      default:
        break
    }
  }

  return errors
}


const mapStateToProps = state => ({
  targets: state.lp, auth: state.auth, dictionary: {
    lp: state.lpmaster.dictionary, user: state.user.dictionary
  }
});
const mapDispatchToProps = ({ getTargets, setEditTarget, deleteTarget, setSearch, getUserDictionary, getLpMasterDictionary });

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withSnackbar(withStyles(styles)(Index))
);
