import {
  GET_OEMS as GET_ITEMS,
  GET_OEM as GET_ITEM,
  SET_EDIT_OEM as SET_EDIT,
  REMOVE_EDIT_OEM as REMOVE_EDIT,
  SET_OEM_SEARCH as SET_SEARCH,
  UPDATE_OEM as UPDATE,
} from '../actions/oem'

const defaultState = {
  search: {
    sort: [],
    oem_id: [],
    name: '',
    web_domain: '',
    api_domain: '',
  },
  paginate: {
    limit: 100,
    offset: 0,
    count: 0,
  },
  items: [],
  item: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EDIT:
      return { ...state, item: action.item };
    case REMOVE_EDIT:
      return { ...state, item: null };
    case GET_ITEMS:
      return { ...state, paginate: action.paginate, items: action.items };
    case GET_ITEM:
      return { ...state, item: action.item };
    case SET_SEARCH:
      const { search: _search } = action
      const search = _search ?? defaultState.search
      return { ...state, search: { ...state.search, ...search } }
    case UPDATE:
    default:
      return state;
  }
}