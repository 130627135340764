import {
  GET_STRIPES as GET_ITEMS,
  GET_STRIPE as GET_ITEM,
  SET_EDIT_STRIPE as SET_EDIT,
  REMOVE_EDIT_STRIPE as REMOVE_EDIT,
  SET_STRIPE_SEARCH as SET_SEARCH,
  STORE_STRIPE as STORE,
  UPDATE_STRIPE as UPDATE,
} from '../actions/stripe'

const defaultState = {
  search: {
    sort: [],
    user_id: [],
    id: [],
  },
  paginate: {
    limit: 100,
    offset: 0,
    count: 0,
  },
  items: [],
  item: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EDIT:
      return { ...state, item: action.item };
    case REMOVE_EDIT:
      return { ...state, item: null };
    case GET_ITEMS:
      return { ...state, paginate: action.paginate, items: action.items };
    case GET_ITEM:
      return { ...state, item: action.item };
    case SET_SEARCH:
      const { search: _search } = action
      const search = _search ?? defaultState.search
      return { ...state, search: { ...state.search, ...search } }
    case UPDATE:
    case STORE:
    default:
      return state;
  }
}