import React from 'react'
import {
  updateTarget as updateStripe,
  storeTarget as storeStripe,
  getTargets as getStripeList,
  setEditTarget as setEditStripe,
} from "../../../actions/stripe"
import {
  updateTarget as updatePayjp,
  storeTarget as storePayjp,
  getTargets as getPayjpList,
  setEditTarget as setEditPayjp,
} from "../../../actions/payjp"
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { sleep } from "../../../helpers/common"
import { PAGE_NAME as PAGE_HOME } from "../Home"
import { PATH_HOME } from '../../router/Path'
import { Breadcrumbs, Grid } from '@material-ui/core'
import { TextField } from 'mui-rff'
import Button from '@material-ui/core/Button'
import LinkRouter from '../../router/LinkRouter'
import { withSnackbar } from 'notistack';
import _ from 'lodash';

const PAGE_NAME = '決済サービスAPI設定'
const STRIPE_URL = 'https://docs.google.com/presentation/d/17TLgAco6d6ZzPPJ_IG16uUEKYoOs_OCpIuT6Yj2Ncc8/edit?usp=sharing'
const PAYJP_URL = 'https://docs.google.com/presentation/d/14uzcjSHNaZcpGXxQAiGPBCcrAp8b6w6Y8wIAh1YhkDE/edit?usp=sharing'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
})

class Update extends React.Component {
  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
    this.load = this.load.bind(this)
    this.load()
  }

  async load() {
    await this.props.getPayjpList()
    await this.props.getStripeList()
    const { stripe: { items: stripe }, payjp: { items: payjp } } = this.props
    if (!_.isEmpty(stripe)) {
      this.props.setEditStripe(stripe[0])
    }
    if (!_.isEmpty(payjp)) {
      this.props.setEditPayjp(payjp[0])
    }
  }

  async onSubmit(values) {
    const option = { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, autoHideDuration: 2000 }
    const cb = message => () => {
      this.props.enqueueSnackbar(message, option)
    }

    const { stripe: { item: stripe }, payjp: { item: payjp } } = this.props
    let stripe_values = { api_public_key: '', api_secret_key: '' }
    let payjp_values = { api_public_key: '', api_secret_key: '' }
    if (values.stripe) {
      stripe_values = { ...stripe_values, ...values.stripe }
    }
    if (values.payjp) {
      payjp_values = { ...payjp_values, ...values.payjp }
    }

    if (!_.isEmpty(stripe)) {
      const { id } = stripe
      await this.props.updateStripe(id, stripe_values, cb('Stripeを保存しました'))
    } else {
      await this.props.storeStripe(stripe_values, cb('Stripeを保存しました'))
    }
    if (!_.isEmpty(payjp)) {
      const { id } = payjp
      await this.props.updatePayjp(id, payjp_values, cb('PAY.JPを保存しました'))
    } else {
      await this.props.storePayjp(payjp_values, cb('PAY.JPを保存しました'))
    }

    await this.load()

    await sleep(1000)
  }

  render() {
    const { stripe: { item: stripe }, payjp: { item: payjp }, classes } = this.props
    const item = { stripe, payjp }
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Form
          onSubmit={this.onSubmit}
          initialValues={item}
          render={formRender(classes)}
        />
      </React.Fragment>
    )
  }
}

const formRender = (classes) => ({ form, handleSubmit, pristine, submitting, invalid, values }) => {
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <Grid container justify="center" className={classes.root} spacing={2}>
        <Grid item container direction="column" xs md={8} spacing={1}>
          <Grid item>
            <Typography component="div" variant="h4">Stripe</Typography>
            <Typography component="div" variant="body2" style={{ marginTop: '4px' }}>
              Stripe API確認方法の詳細は<a href={STRIPE_URL} target="_blanck" rel="noopener noreferrer" >こちら</a>
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="API公開可能キー"
              name="stripe.api_public_key"
              placeholder="pk_…"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="APIシークレットキー"
              name="stripe.api_secret_key"
              placeholder="sk_…"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <Typography component="div" variant="h4">PAY.JP</Typography>
            <Typography component="div" variant="body2" style={{ marginTop: '4px' }}>
              PAY.JP API確認方法の詳細は<a href={PAYJP_URL} target="_blanck" rel="noopener noreferrer" >こちら</a>
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="API公開鍵"
              name="payjp.api_public_key"
              placeholder="pk_…"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="API秘密鍵"
              name="payjp.api_secret_key"
              placeholder="sk_…"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item container justify="space-around" className={classes.btnRoot} spacing={2}>
            <Grid item container justify="flex-start" spacing={2} xs>
              <Grid item>

              </Grid>
            </Grid>
            <Grid item container justify="flex-end" spacing={2} xs>
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={pristine || submitting}
                  onClick={form.reset}
                >
                  リセット
              </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={pristine || submitting || invalid}
                >
                  保存
              </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const mapStateToProps = state => ({
  stripe: state.stripe, payjp: state.payjp, auth: state.auth
})
const mapDispatchToProps = ({
  updateStripe, storeStripe, getStripeList, setEditStripe,
  updatePayjp, storePayjp, getPayjpList, setEditPayjp,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withSnackbar(withStyles(styles)(Update))
)
