
import { API_BASE } from '../constraints/commons'
import { getMyAxios } from '../helpers/auth'

export const GET_LPS = 'GET_LPS'
export const GET_LP = 'GET_LP'
export const STORE_LP = 'STORE_LP'
export const DELETE_LP = 'DELETE_LP'
export const UPDATE_LP = 'UPDATE_LP'
export const UPLOAD_LP = 'UPLOAD_LP'
export const ERROR_LP = 'ERROR_LP'
export const ERROR_LP_DETAIL = 'ERROR_LP_DETAIL'
export const ERROR_LP_DETAIL_REMOVE = 'ERROR_LP_DETAIL_REMOVE'
export const SET_EDIT_LP = 'SET_EDIT_LP'
export const REMOVE_EDIT_LP = 'REMOVE_EDIT_LP'
export const SET_LP_SEARCH = 'SET_LP_SEARCH'
export const GET_LP_DICTIONARY = 'GET_LP_DICTIONARY'

export const setEditTarget = item => {
  return { type: SET_EDIT_LP, item }
}

export const removeEditTarget = () => {
  return { type: REMOVE_EDIT_LP }
}

export const removeErrors = () => {
  return { type: ERROR_LP_DETAIL_REMOVE }
}

const _getTargets = async values => {
  return await getMyAxios().get(`${API_BASE}/v1/lp`, { params: values })
}

export const getTargets = values => async dispatch => {
  try {
    const response = await _getTargets(values)
    const { data: { status, info: items, ...paginate } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_LP, error: `LP一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_LPS, items, paginate })
        break
      default:
        dispatch({ type: ERROR_LP, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LP, error })
  }
}

export const getTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().get(`${API_BASE}/v1/lp/${id}`)
    const { data: { status, info: item } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_LP, error: `LP情報の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_LP, item })
        break
      default:
        dispatch({ type: ERROR_LP, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LP, error })
  }
}

export const storeTarget = (values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/lp`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 102:
        dispatch({ type: ERROR_LP, error: `LPの発行に失敗しました。[${status.code}]` })
        break
      case 101:
        dispatch({ type: ERROR_LP, error: `同じLP名が既に存在します。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_LP, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LP, error })
  }
}

export const updateTarget = (id, values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().patch(`${API_BASE}/v1/lp/${id}`, values)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 102:
        dispatch({ type: ERROR_LP, error: `発行したLPの情報更新に失敗しました。[${status.code}]` })
        break
      case 101:
        dispatch({ type: ERROR_LP, error: `同じLP名が既に存在します。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_LP, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LP, error })
  }
}

export const uploadTarget = (values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/lp/csv/upload`, values, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_LP, error: `CSVに異常があるレコードが存在するため処理を中止しました。[${status.code}]` })
        dispatch({ type: ERROR_LP_DETAIL, errors: response.data.info })
        break
      case 102:
        dispatch({ type: ERROR_LP, error: `LP発行に失敗したCSVレコードがあります。[${status.code}]` })
        dispatch({ type: ERROR_LP_DETAIL, errors: response.data.info })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_LP, error: `予期せぬエラーが発生しました。` })
        dispatch({ type: ERROR_LP_DETAIL, errors: response.data.info })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LP, error })
  }
}

export const deleteTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().delete(`${API_BASE}/v1/lp/${id}`)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_LP, error: `発行LPの削除に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: DELETE_LP, id })
        break
      default:
        dispatch({ type: ERROR_LP, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LP, error })
  }
}

export const setSearch = (values) => async dispatch => {
  dispatch({ type: SET_LP_SEARCH, search: values })
}

export const getDictionary = (values) => async dispatch => {
  try {
    const response = await _getTargets(values)
    const { data: { status, info: items } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_LP, error: `LP一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_LP_DICTIONARY, items })
        break
      default:
        dispatch({ type: ERROR_LP, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LP, error })
  }
}