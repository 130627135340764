import { combineReducers } from 'redux'
import auth from './auth'
import error from './error'
import loading from "./loading"
import user from "./user"
import lpmaster from "./lpmaster"
import lp from "./lp"
import lp_view from "./lp_view"
import analytics from "./analytics"
import oem from "./oem"
import bank from "./bank"
import stripe from "./stripe"
import payjp from "./payjp"
import navi from "./navi"
import mailtemplate from "./mailtemplate"

export default combineReducers({
  auth, error, loading, user, lpmaster, lp, lp_view, analytics, oem, bank,
  stripe, payjp, navi, mailtemplate,
})