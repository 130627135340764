import {
  GET_RELEASE_LP
} from '../actions/lp_view'

const defaultState = {
  paginate: {
    limit: 100,
    offset: 0,
    count: 0,
  },
  url: null,
  favicon: '',
  title: '',
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_RELEASE_LP:
      const { paginate, url, favicon, title } = action
      return { paginate, url, favicon, title }
    default:
      return state;
  }
}