import React from 'react'
import { storeTarget } from "../../../../actions/user"

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import { TextField, Radios } from 'mui-rff'

import { connect } from 'react-redux'
import { REGEX_EMAIL, REGEX_PASSWORD } from '../../../../constraints/commons'
import { PAGE_NAME as PAGE_HOME } from "../../Home"
import { PAGE_NAME as PAGE_USER } from "./Index"
import { PATH_ADMIN_USER, PATH_HOME } from '../../../router/Path'
import { Breadcrumbs, Grid } from '@material-ui/core'
import LinkRouter from '../../../router/LinkRouter'

const PAGE_NAME = '登録'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
})

class Store extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stored: false
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit(values) {
    await this.props.storeTarget(values, () => { this.props.history.push(PATH_ADMIN_USER) })
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <LinkRouter color="inherit" to={PATH_ADMIN_USER}>
            {PAGE_USER}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Form
          onSubmit={this.onSubmit}
          validate={validate}
          render={formRender(classes)}
        />
      </React.Fragment>
    )
  }
}

const formRender = classes => ({ form, handleSubmit, pristine, submitting, invalid }) => {
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <Grid container justify="center" className={classes.root} spacing={2}>
        <Grid item container direction="column" xs md={8}>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="メールアドレス"
              name="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="last_name"
                label="姓"
                name="last_name"
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="first_name"
                label="名"
                name="first_name"
              />
            </Grid>
          </Grid>
          <Grid item xs>
            <div className={classes.radioRoot}>
              <Radios
                label="権限"
                name="authority"
                required
                radioGroupProps={{ row: true }}
                data={[
                  { label: '管理者', value: '1' },
                  { label: '一般', value: '0' }
                ]}
              />
            </div>
          </Grid>
          <Grid item xs>
            <div className={classes.radioRoot}>
              <Radios
                label="メールアドレス確認用のメール"
                name="verify_email_send"
                required
                radioGroupProps={{ row: true }}
                data={[
                  { label: '送信する', value: '1' },
                  { label: '送信しない', value: '0' }
                ]}
              />
            </div>
          </Grid>
          <Grid item xs>
            <div className={classes.radioRoot}>
              <Radios
                label="作成したユーザー情報のメール"
                name="info_email_send"
                required
                radioGroupProps={{ row: true }}
                data={[
                  { label: '送信する', value: '1' },
                  { label: '送信しない', value: '0' }
                ]}
              />
            </div>
          </Grid>
          <Grid item container justify="flex-end" className={classes.btnRoot} spacing={2}>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                fullWidth
                disabled={pristine || submitting}
                onClick={form.reset}
              >
                リセット
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={pristine || submitting || invalid}
              >
                登録
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const validate = values => {
  const errors = {}
  const [required, email, password] = [true, true, true]
  const inputs = [
    { name: 'email', required, email },
    { name: 'password', required, password },
    { name: 'first_name', required, max: 100 },
    { name: 'last_name', required, max: 100 },
    { name: 'authority', required },
    { name: 'verify_email_send', required },
    { name: 'info_email_send', required },
  ]

  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.email && val && !REGEX_EMAIL.test(val):
        errors[key] = '不正なメールアドレスです'
        break
      case input.password && val && !REGEX_PASSWORD.test(val):
        errors[key] = '8文字以上の英数字で記号は、アンダースコア(_)、ハイフン(-)、ドット(.)、アットマーク(@)のみ使用できます'
        break
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      default:
        break
    }
  }

  return errors
}

const mapStateToProps = state => ({ err: state.error })
const mapDispatchToProps = ({ storeTarget })

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(Store))
