import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {PATH_HOME, PATH_LOGIN} from './router/Path';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Forward from '@material-ui/icons/Forward';
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from "@material-ui/icons/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import {requestSignOut} from "../actions/auth";
import {drawerWidth} from "./AuthNav";
import { APP_NAME } from "../constraints/commons"

const propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const styles = theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
});

class GuestNav extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: true
    };
  }

  componentDidMount() {
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.mainListItems = this.mainListItems.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout () {
    this.props.requestSignOut(() => this.props.history.push(PATH_LOGIN) );
  }

  handleDrawerOpen() {
    this.setState({open: true})
  }

  handleDrawerClose() {
    this.setState({open: false})
  }

  mainListItems() {
    return (
      <div>
        {/* <ListItem button onClick={() => this.props.history.push(PATH_FAQ)}>
          <ListItemIcon>
            <QuestionAnswerIcon />
          </ListItemIcon>
          <ListItemText primary="よくある質問" />
        </ListItem> */}
      </div>
    );
  }

  render () {
    const { classes, auth } = this.props;
    const { authenticated } = auth;
    const { open } = this.state;
    return (
      <React.Fragment>
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              { APP_NAME }
            </Typography>
            {
              authenticated &&
                <React.Fragment>
                  <Tooltip title="会員ページ">
                    <IconButton color="inherit" onClick={() => { this.props.history.push(PATH_HOME); }}>
                      <HomeIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="ログアウト">
                    <IconButton color="inherit" onClick={() => { this.props.requestSignOut(); }}>
                      <Forward />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
            }
            {
              !authenticated &&
              <Tooltip title="ログイン">
                <IconButton color="inherit" onClick={() => { this.props.history.push(PATH_LOGIN); }}>
                  <Forward />
                </IconButton>
              </Tooltip>
            }
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{this.mainListItems()}</List>
        </Drawer>
      </React.Fragment>
  );
  }
}

GuestNav.propTypes = propTypes;
const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = { requestSignOut };

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withRouter(
    withStyles(styles)(GuestNav)
  )
);