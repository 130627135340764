import React from 'react';
import { requestPasswordReset } from "../../../actions/user";
import Copyright from "../../pages/Copyright"

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'

import { connect } from 'react-redux'
import { REGEX_EMAIL } from '../../../constraints/commons'
import { PATH_USER_STORE, PATH_LOGIN } from "../../router/Path";
import { sleep } from "../../../helpers/common"


const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class PasswordResetRequest extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      message: ''
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit(values) {
    this.setState({ message: '' })
    await this.props.requestPasswordReset(values);
    await sleep(500)
    this.setState({ message: '再設定メールを送信しました。' })
  }

  render() {
    const { classes } = this.props;
    const { message } = this.state
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            パスワード再設定
          </Typography>
          <Form
            onSubmit={this.onSubmit}
            validate={validate}
            render={formRender(classes)}
          />
          <Typography color={"primary"} variant="h6">
            {message}
          </Typography>
          <Button
            type="button"
            variant="text"
            onClick={() => { this.props.history.push(PATH_LOGIN) }}
          >
            ログインはこちら
          </Button>
          <Button
            type="button"
            variant="text"
            onClick={() => { this.props.history.push(PATH_USER_STORE) }}
          >
            アカウントをお持ちでない方はこちら
          </Button>
        </div>
        <Box mt={2}>
          <Copyright />
        </Box>
      </Container>
    )
  }
}

const formRender = classes => ({ form, handleSubmit, pristine, submitting, invalid }) => {
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="メールアドレス"
        name="email"
        autoComplete="email"
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={pristine || submitting || invalid}
        className={classes.submit}
      >
        再設定メールを送信する
    </Button>
    </form>
  )
}

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'メールアドレスを入力してください';
  } else if (
    !REGEX_EMAIL.test(values.email)
  ) {
    errors.email = '不正なメールアドレスです';
  }

  return errors
};

const mapStateToProps = state => ({ err: state.error });
const mapDispatchToProps = ({ requestPasswordReset });

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(PasswordResetRequest))
