import React from 'react';
import _ from "lodash"
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  getTargets, setSearch
} from '../../../actions/analytics'
import { getDictionary as getLpMasterDictionary } from '../../../actions/lpmaster'
import { getDictionary as getLpDictionary } from '../../../actions/lp'
import { getDictionary as getUserDictionary } from '../../../actions/user'
import { connect } from 'react-redux'
import { PATH_HOME } from "../../router/Path";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import LinkRouter from "../../router/LinkRouter";
import Grid from "@material-ui/core/Grid";
import MaterialTable from 'material-table'
import { defaultLocalization, defaultOption } from "../../../helpers/material-table";
import { PAGE_NAME as PAGE_HOME } from "../Home";
import { Paper, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Form, Field } from 'react-final-form'
import Button from '@material-ui/core/Button'
import { Select } from 'mui-rff';
import { MenuItem } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import renderDateField from '../../renderDateField'
import { isAdmin } from '../../../helpers/common';
import { withSnackbar } from 'notistack'

export const PAGE_NAME = '成果情報';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '70%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(1),
  },
  formPaper: {
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const getFromat = (date_unit) => {
  return date_unit === 'month' ? 'YYYY-MM' :
    date_unit === 'year' ? 'YYYY' : 'YYYY-MM-DD'
}

const MyTableCell = withStyles(theme => {
  return {
    head: {
      padding: theme.spacing(1),
      borderLeft: '1px solid rgb(224, 224, 224, 1)',
    },
  }
})(TableCell)

const NoneBorderTableCell = withStyles(theme => {
  return {
    head: {
      padding: theme.spacing(1),
      borderLeft: '1px solid rgb(224, 224, 224, 1)',
      borderTop: '1px solid rgb(224, 224, 224, 1)',
    },
  }
})(TableCell)

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort_num: 1,
    }
  }

  componentDidMount() {
    this.materialTableData = this.materialTableData.bind(this);
    this.onSearch = this.onSearch.bind(this)
    this.onSearch(this.props.targets.search);
    const { auth: { user } } = this.props
    this.props.getLpMasterDictionary({})
    if (isAdmin(user)) {
      this.props.getLpDictionary({ user_id: -1 }) // 全ユーザー
      this.props.getUserDictionary({})
    } else {
      this.props.getLpDictionary({}) // 自身のみ
    }
    defaultOption.search = false
    defaultOption.sorting = false
    defaultOption.draggable = false
  }

  materialTableData() {
    const total = _.cloneDeep(this.props.targets.total)
    total['date'] = '合計'
    return _.concat(this.props.targets.items, total)
  }

  async onSearch(values) {
    const _values = _.cloneDeep(values)
    this.props.setSearch(values)
    const keys = [
      'device_type', 'lp_id', 'lp_master_id', 'user_id'
    ]
    for (const key of keys) {
      if (_.isEmpty(_values[key])) delete _values[key]
    }
    const { auth: { user } } = this.props
    if (isAdmin(user)) {
      _values['user_id'] = -1
    }
    const array_keys = [
      'user_id', 'lp_master_id', 'lp_id'
    ]
    for (const key of array_keys) {
      if (!_.isEmpty(values[key])) _values[key] = values[key].join(',')
    }
    const date_keys = [
      'date_from', 'date_to'
    ]
    for (const key of date_keys) {
      if (typeof values[key] === 'string') _values[key] = values[key]
      if (typeof values[key].format === 'function') _values[key] = values[key].format(getFromat(values['date_unit']))
    }

    if (_.isEmpty(_values['sort'])) {
      delete _values['sort']
    } else {
      let s = []
      for (const sort of _values['sort']) {
        if (sort && 'order' in sort && 'key' in sort) {
          s.push(`${sort.key}=${sort.order}`)
        }
      }
      _values['sort'] = s.join(',')
      if (s.length) _values['sort'] = s.join(',')
      else delete _values['sort']
    }
    await this.props.getTargets(_values)
  }

  render() {
    const { classes, targets: { search }, auth: { user }, dictionary } = this.props;
    const { sort_num } = this.state;
    const reset = () => {
      this.props.setSearch(null)
      this.setState({ sort_num: 1 })
    }
    const actions = []

    let columns = [
      { title: '日付', field: 'date' },
      { title: 'PV', field: 'pv', type: 'numeric' },
      { title: 'UU', field: 'uu', type: 'numeric' },
      { title: 'CV', field: 'all.cv', type: 'numeric' },
      { title: 'CVR(PV)', field: 'all.cvr_of_pv', type: 'numeric' },
      { title: 'CVR(UU)', field: 'all.cvr_of_uu', type: 'numeric' },
      { title: 'CV', field: 'myasp.cv', type: 'numeric' },
      { title: 'CVR(PV)', field: 'myasp.cvr_of_pv', type: 'numeric' },
      { title: 'CVR(UU)', field: 'myasp.cvr_of_uu', type: 'numeric' },
      { title: 'CV', field: 'paypal.cv', type: 'numeric' },
      { title: 'CVR(PV)', field: 'paypal.cvr_of_pv', type: 'numeric' },
      { title: 'CVR(UU)', field: 'paypal.cvr_of_uu', type: 'numeric' },
      { title: 'CV', field: 'stripe.cv', type: 'numeric' },
      { title: 'CVR(PV)', field: 'stripe.cvr_of_pv', type: 'numeric' },
      { title: 'CVR(UU)', field: 'stripe.cvr_of_uu', type: 'numeric' },
      { title: 'CV', field: 'payjp.cv', type: 'numeric' },
      { title: 'CVR(PV)', field: 'payjp.cvr_of_pv', type: 'numeric' },
      { title: 'CVR(UU)', field: 'payjp.cvr_of_uu', type: 'numeric' },
      { title: 'CV', field: 'line_at.cv', type: 'numeric' },
      { title: 'CVR(PV)', field: 'line_at.cvr_of_pv', type: 'numeric' },
      { title: 'CVR(UU)', field: 'line_at.cvr_of_uu', type: 'numeric' },
      { title: 'CV', field: 'other.cv', type: 'numeric' },
      { title: 'CVR(PV)', field: 'other.cvr_of_pv', type: 'numeric' },
      { title: 'CVR(UU)', field: 'other.cvr_of_uu', type: 'numeric' },
    ]

    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Grid container justify="center" direction="column" className={classes.root}>
          <Grid item xs>
            <Form
              onSubmit={this.onSearch}
              initialValues={search}
              validate={validate}
              render={formRender(classes, reset, sort_num, this.addSearch, isAdmin(user), dictionary)}
            />
          </Grid>
        </Grid>
        <MaterialTable
          columns={columns}
          components={{
            Container: props => {
              return (
                <Paper {...props} elevation={0} variant="outlined" />
              )
            },
            Header: props => {
              return (
                <TableHead>
                  <TableRow>
                    <NoneBorderTableCell colSpan={3}></NoneBorderTableCell>
                    <NoneBorderTableCell colSpan={3} align="center">全て</NoneBorderTableCell>
                    <NoneBorderTableCell colSpan={3} align="center">MyASP</NoneBorderTableCell>
                    <NoneBorderTableCell colSpan={3} align="center">PayPal</NoneBorderTableCell>
                    <NoneBorderTableCell colSpan={3} align="center">Stripe</NoneBorderTableCell>
                    <NoneBorderTableCell colSpan={3} align="center">PAY.JP</NoneBorderTableCell>
                    <NoneBorderTableCell colSpan={3} align="center">LINE@</NoneBorderTableCell>
                    <NoneBorderTableCell colSpan={3} align="center">その他</NoneBorderTableCell>
                  </TableRow>
                  <TableRow>
                    {
                      props.columns.map((item, i) => <MyTableCell align="center" key={`table_header_${i}`}>{item.title}</MyTableCell>)
                    }
                  </TableRow>
                </TableHead>
              )
            },
          }}
          data={this.materialTableData()}
          title='成果情報'
          actions={actions}
          options={{
            ...defaultOption,
            paging: false,
            cellStyle: {
              padding: 4,
              borderLeft: '1px solid rgb(224, 224, 224, 1)',
              whiteSpace: 'nowrap',
            },
          }}
          localization={defaultLocalization}
        />
      </React.Fragment>
    );
  }
}

const formRender = (classes, reset, sort_num, add, admin, dictionary) => ({ handleSubmit, submitting, invalid, values }) => {
  const dateFormat = getFromat(values.date_unit)
  return (
    <Paper className={classes.formPaper} elevation={0} variant="outlined">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>検索条件</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form method="post" className={classes.form} onSubmit={handleSubmit}>
            <Grid item container direction="column" spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs>
                  <Select
                    name="date_unit"
                    label="集計単位"
                    formControlProps={{ margin: 'dense', variant: "outlined" }}
                  >
                    {
                      [
                        { value: 'year', name: '年' },
                        { value: 'month', name: '月' },
                        { value: 'day', name: '日' },
                      ].map((row, i) => (
                        <MenuItem value={row.value} key={`date_unit_${i}`}>{`${row.name}`}</MenuItem>
                      ))
                    }
                  </Select>
                </Grid>
                <Grid item xs>
                  <Field
                    inputVariant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="date_from"
                    label="日付（From）"
                    name="date_from"
                    dateFormat={dateFormat}
                    component={renderDateField}
                  />
                </Grid>
                <Grid item xs>
                  <Field
                    inputVariant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="date_to"
                    label="日付（To）"
                    name="date_to"
                    dateFormat={dateFormat}
                    component={renderDateField}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs>
                  {
                    (!_.isEmpty(dictionary.lp)) &&
                    <Select
                      name="lp_id"
                      label="発行LP"
                      formControlProps={{ margin: 'dense', variant: "standard" }}
                      multiple
                      displayEmpty
                      inputLabelProps={{ shrink: true }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>全て選択</em>
                        }
                        return dictionary.lp.filter(i => selected.includes(i.id)).map(i => i.name).join(', ')
                      }}
                    >
                      {
                        dictionary.lp.map((row, i) => (
                          <MenuItem value={row.id} key={`lp_id_${i}`}>{`${row.name}`}</MenuItem>
                        ))
                      }
                    </Select>
                  }
                </Grid>
                <Grid item xs>
                  {
                    (admin && !_.isEmpty(dictionary.user)) &&
                    <Select
                      name="user_id"
                      label="ユーザーの姓名"
                      formControlProps={{ margin: 'dense', variant: "standard" }}
                      multiple
                      displayEmpty
                      inputLabelProps={{ shrink: true }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>全て選択</em>
                        }
                        return dictionary.user.filter(i => selected.includes(i.id)).map(i => `${i.last_name} ${i.first_name}`).join(', ')
                      }}
                    >
                      {
                        dictionary.user.map((row, i) => (
                          <MenuItem value={row.id} key={`user_id_${i}`}>{`${row.last_name} ${row.first_name}`}</MenuItem>
                        ))
                      }
                    </Select>
                  }
                </Grid>
                <Grid item xs>
                  {
                    (admin && !_.isEmpty(dictionary.lp_master)) &&
                    <Select
                      name="lp_master_id"
                      label="LPマスター"
                      formControlProps={{ margin: 'dense', variant: "standard" }}
                      multiple
                      displayEmpty
                      inputLabelProps={{ shrink: true }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>全て選択</em>
                        }
                        return dictionary.lp_master.filter(i => selected.includes(i.id)).map(i => i.logical_name).join(', ')
                      }}
                    >
                      {
                        dictionary.lp_master.map((row, i) => (
                          <MenuItem value={row.id} key={`lp_master_${i}`}>{`${row.logical_name}`}</MenuItem>
                        ))
                      }
                    </Select>
                  }
                </Grid>
              </Grid>
              <Grid item container justify="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={submitting}
                    onClick={reset}
                  >
                    リセット
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={submitting || invalid}
                  >
                    検索
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}

const validate = values => {
  const errors = {}
  const format = getFromat(values['date_unit'])
  const inputs = [
    { name: 'date_from', date: format },
    { name: 'date_to', date: format },
  ]
  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.date && !val:
        errors[key] = `日付を選択して下さい`
        break
      default:
        break
    }
  }
  const { date_from, date_to } = values
  if (date_from > date_to) {
    errors['date_from'] = 'FromはToより過去の日付を指定して下さい'
    errors['date_to'] = 'ToはFromより未来の日付を指定して下さい'
  }

  return errors
}


const mapStateToProps = state => ({
  targets: state.analytics, auth: state.auth, dictionary: {
    lp: state.lp.dictionary, lp_master: state.lpmaster.dictionary, user: state.user.dictionary
  }
});
const mapDispatchToProps = ({ getTargets, setSearch, getUserDictionary, getLpMasterDictionary, getLpDictionary });

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withSnackbar(withStyles(styles)(Index))
);
