import _ from "lodash"

const initialState = {
  error: null,
  timeout: false,
}

export default (state = { error: null, timeout: false }, action) => {
  if ('error' in action) {
    const { error } = action;
    if (_.isObject(error)) {
      return { error: error.message, timeout: Boolean(error.message.match(/401/)) };
    }
    if (_.isString(error)) {
      return { ...state, error };
    }
    if (_.isNull(error)) {
      return initialState;
    }
  }
  return state
}