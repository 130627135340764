import {
  GET_USER, GET_USERS, UPDATE_USER, DELETE_USER,
  STORE_USER, SET_EDIT_USER, REMOVE_EDIT_USER, SET_SEARCH,
  GET_USER_DICTIONARY,
} from '../actions/user'

const defaultState = {
  search: {
    sort: [],
    email: '',
    last_name: '',
    first_name: '',
    authority: null,
    frozen_at: 0,
  },
  paginate: {
    limit: 100,
    offset: 0,
    count: 0,
  },
  items: [],
  item: null,
}

export default (users = defaultState, action) => {
  switch (action.type) {
    case SET_EDIT_USER:
      return { ...users, item: action.user };
    case REMOVE_EDIT_USER:
      return { ...users, item: null };
    case GET_USERS:
      return { ...users, paginate: action.paginate, items: action.users };
    case GET_USER:
      return { ...users, item: action.user };
    case SET_SEARCH:
      const { search: _search } = action
      const search = _search ?? defaultState.search
      return { ...users, search: { ...users.search, ...search } }
    case DELETE_USER:
      return { ...users, items: users.items.filter(v => v.id !== action.id) };
    case GET_USER_DICTIONARY:
      return { ...users, dictionary: action.users };
    case STORE_USER:
    case UPDATE_USER:
    default:
      return users;
  }
}