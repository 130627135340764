import {
  GET_LPMASTERS as GET_ITEMS,
  GET_LPMASTER as GET_ITEM,
  SET_EDIT_LPMASTER as SET_EDIT,
  DELETE_LPMASTER as DELETE,
  REMOVE_EDIT_LPMASTER as REMOVE_EDIT,
  SET_LPMASTER_SEARCH as SET_SEARCH,
  STORE_LPMASTER as STORE,
  UPDATE_LPMASTER as UPDATE,
  GET_LPMASTER_DICTIONARY as DICTIONARY,
} from '../actions/lpmaster'

const defaultState = {
  search: {
    sort: [],
    logical_name: '',
    user_id: [],
    scope: [],
  },
  paginate: {
    limit: 100,
    offset: 0,
    count: 0,
  },
  items: [],
  item: null,
  dictionary: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EDIT:
      return { ...state, item: action.item };
    case REMOVE_EDIT:
      return { ...state, item: null };
    case GET_ITEMS:
      return { ...state, paginate: action.paginate, items: action.items };
    case GET_ITEM:
      return { ...state, item: action.item };
    case SET_SEARCH:
      const { search: _search } = action
      const search = _search ?? defaultState.search
      return { ...state, search: { ...state.search, ...search } }
    case DICTIONARY:
      return { ...state, dictionary: action.items };
    case DELETE:
      return { ...state, items: state.items.filter(v => v.id !== action.id) };
    case UPDATE:
    case STORE:
    default:
      return state;
  }
}