import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { connect } from 'react-redux'
import Copyright from "../Copyright"

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Leaved extends React.Component {
  componentDidMount() {
    // 非同期処理に自身をバインド
  }

  render() {
    const { classes } = this.props
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOpenIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ご利用ありがとうございました
          </Typography>
          <Typography component="div" variant="body1">
            退会処理が完了しました。
          </Typography>
        </div>
        <Box mt={2}>
          <Copyright />
        </Box>
      </Container>
    )
  }
}

const mapStateToProps = state => ({ err: state.error });
const mapDispatchToProps = ({ });

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(Leaved))
