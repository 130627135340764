import React from 'react';
import _ from "lodash"
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  getTargets, setEditTarget, deleteTarget, setSearch
} from '../../../../actions/user'
import { connect } from 'react-redux'
import { getUpdatePath, PATH_HOME, PATH_ADMIN_USER_STORE, PATH_ADMIN_USER_UPDATE } from "../../../router/Path";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import LinkRouter from "../../../router/LinkRouter";
import Grid from "@material-ui/core/Grid";
import MaterialTable from 'material-table'
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from "@material-ui/core/Chip";
import { defaultLocalization, defaultOption } from "../../../../helpers/material-table";
import { PAGE_NAME as PAGE_HOME } from "../../Home";
import YesNoAlert from "../../../modal/YesNoAlert";
import { authorityName } from '../../../../helpers/auth';
import { Paper } from '@material-ui/core';
import { downloadUserCSV, isAdmin } from '../../../../helpers/common';
import { Form } from 'react-final-form'
import { TextField, Checkboxes } from 'mui-rff'
import Button from '@material-ui/core/Button'
import { Select } from 'mui-rff';
import { MenuItem } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveAltIcon from '@material-ui/icons/SaveAlt';


export const PAGE_NAME = 'ユーザー管理';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '70%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(1),
  },
  formPaper: {
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      deleteId: null,
      sort_num: 1,
    }
  }

  componentDidMount() {
    this.storeOnClick = this.storeOnClick.bind(this);
    this.editOnClick = this.editOnClick.bind(this);
    this.materialTableData = this.materialTableData.bind(this);
    this.deleteOnClick = this.deleteOnClick.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSearch = this.onSearch.bind(this)
    this.addSearch = this.addSearch.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this.onSearch(this.props.users.search);
    defaultOption.search = false
    defaultOption.sorting = false
    defaultOption.draggable = false
  }

  storeOnClick() {
    this.props.history.push(PATH_ADMIN_USER_STORE);
  }

  editOnClick(event, rowData) {
    this.props.setEditTarget(rowData);
    this.props.history.push(getUpdatePath(rowData.id, PATH_ADMIN_USER_UPDATE));
  }

  deleteOnClick(event, rowData) {
    this.setState({ open: true, deleteId: rowData.id })
  }

  onDelete(result) {
    if (result) this.props.deleteTarget(this.state.deleteId);
    this.setState({ open: false, deleteId: null })
  }

  materialTableData() {
    return this.props.users.items
  }

  renderAuth(rowData) {
    return (
      <Chip size="small" label={authorityName(rowData.authority)} color={isAdmin(rowData) ? 'primary' : 'default'} />
    )
  }

  renderFrozen(rowData) {
    const isFrozen = rowData.frozen_at !== ''
    return (
      <Typography component="div" color={isFrozen ? "error" : "inherit"}>
        {isFrozen ? '凍結済み' : '未凍結'}
      </Typography>
    )
  }

  handleDownload() {
    downloadUserCSV(this.makeSearchParam(this.props.users.search))
  }

  addSearch() {
    this.setState({ sort_num: this.state.sort_num + 1 })
  }

  makeSearchParam(values) {
    const _values = _.cloneDeep(values)
    this.props.setSearch(values)
    const keys = [
      'authority', 'email', 'last_name', 'first_name',
    ]
    for (const key of keys) {
      if (!_values[key]) delete _values[key]
    }
    if (_values['authority'] === '2') {
      delete _values['authority']
    }
    if (_.isEmpty(_values['sort'])) {
      delete _values['sort']
    } else {
      let s = []
      for (const sort of _values['sort']) {
        if (sort && 'order' in sort && 'key' in sort) {
          s.push(`${sort.key}=${sort.order}`)
        }
      }
      if (s.length) _values['sort'] = s.join(',')
      else delete _values['sort']
    }
    _values['frozen_at'] = _values['frozen_at'] ? 1 : 0
    return _values
  }

  onSearch(values) {
    this.props.getTargets(this.makeSearchParam(values))
  }

  render() {
    const { classes, users: { search } } = this.props;
    const { open, sort_num } = this.state;
    const reset = () => {
      this.props.setSearch(null)
      this.setState({ sort_num: 1 })
    }
    const actions = [
      {
        icon: 'edit',
        tooltip: '編集',
        onClick: this.editOnClick
      },
      {
        icon: 'add',
        tooltip: 'ユーザー追加',
        isFreeAction: true,
        onClick: this.storeOnClick
      },
      {
        icon: () => <SaveAltIcon />,
        tooltip: 'CSVダウンロード',
        isFreeAction: true,
        onClick: this.handleDownload
      },
      {
        icon: () => (<DeleteIcon color='error' />),
        tooltip: '削除',
        onClick: this.deleteOnClick
      },
    ]
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Grid container justify="center" direction="column" className={classes.root}>
          <Grid item xs>
            <Form
              onSubmit={this.onSearch}
              initialValues={search}
              validate={validate}
              render={formRender(classes, reset, sort_num, this.addSearch)}
            />
          </Grid>
        </Grid>
        <MaterialTable
          columns={[
            { title: 'ID', field: 'id' },
            { title: 'メールアドレス', field: 'email' },
            { title: 'メールアドレス確認日時', field: 'email_verified_at' },
            { title: '姓', field: 'last_name' },
            { title: '名', field: 'first_name' },
            { title: '権限', render: this.renderAuth },
            { title: '凍結', render: this.renderFrozen },
          ]}
          components={{
            Container: props => {
              return (
                <Paper {...props} elevation={0} variant="outlined" />
              )
            }
          }}
          data={this.materialTableData()}
          title='ユーザー一覧'
          actions={actions}
          options={defaultOption}
          localization={defaultLocalization}
        />
        <YesNoAlert
          open={open}
          title={'確認'}
          message={'本当に削除しますか？'}
          handleClose={this.onDelete}
        />
      </React.Fragment>
    );
  }
}

const formRender = (classes, reset, sort_num, add) => ({ handleSubmit, submitting, invalid }) => {
  return (
    <Paper className={classes.formPaper} elevation={0} variant="outlined">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>検索条件</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form method="post" className={classes.form} onSubmit={handleSubmit}>
            <Grid item container direction="column" spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="email"
                    label="メールアドレス（部分一致）"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="last_name"
                    label="姓（部分一致）"
                    name="last_name"
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="first_name"
                    label="名（部分一致）"
                    name="first_name"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs>
                  <Select name="authority" label="権限" formControlProps={{ margin: 'dense', variant: "outlined" }}>
                    <MenuItem value="2">両方</MenuItem>
                    <MenuItem value="1">管理者のみ</MenuItem>
                    <MenuItem value="0">一般のみ</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs>
                  <div className={classes.radioRoot}>
                    <Checkboxes
                      label="凍結"
                      name="frozen_at"
                      formGroupProps={{
                        row: true
                      }}
                      data={[
                        { label: '凍結済みを含む', value: 1 },
                      ]}
                    />
                  </div>
                </Grid>
                <Grid item xs></Grid>
              </Grid>
              {
                _.map([...Array(sort_num).keys()].map(i => ++i), item => {
                  return (
                    <Grid item container spacing={2} key={`sort${item}`} alignItems="center">
                      <Grid item xs>
                        <Select name={`sort[${item}][key]`} label={`ソート条件${item}:列`} formControlProps={{ margin: 'dense', variant: "outlined" }}>
                          {
                            [{ name: 'ユーザーID', val: 'id' },
                            { name: 'メールアドレス', val: 'email' },
                            { name: 'メールドレスを確認した日時', val: 'email_verified_at' },
                            { name: '姓', val: 'last_name' },
                            { name: '名', val: 'first_name' },
                            { name: '権限', val: 'authority' },].map((row, i) => (
                              <MenuItem value={row.val} key={`col${item}${i}`}>{row.name}</MenuItem>
                            ))
                          }
                        </Select>
                      </Grid>
                      <Grid item xs>
                        <Select name={`sort[${item}][order]`} label={`ソート条件${item}:並び順`} formControlProps={{ margin: 'dense', variant: "outlined" }}>
                          <MenuItem value="asc">昇順</MenuItem>
                          <MenuItem value="desc">降順</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs>
                        {
                          (item === 1 && sort_num < 5) &&
                          <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            onClick={add}
                          >ソート追加</Button>
                        }
                      </Grid>
                    </Grid>
                  )
                })
              }
              <Grid item container justify="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={submitting}
                    onClick={reset}
                  >
                    リセット
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={submitting || invalid}
                  >
                    検索
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}

const validate = values => {
  const errors = {}
  const [email, password] = [true, true]
  const inputs = [
    { name: 'email', email },
    { name: 'password', password },
    { name: 'first_name', max: 100 },
    { name: 'last_name', max: 100 },
    { name: 'authority' },
    { name: 'frozen_at' },
  ]

  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      default:
        break
    }
  }

  return errors
}


const mapStateToProps = state => ({ users: state.user, auth: state.auth });
const mapDispatchToProps = ({ getTargets, setEditTarget, deleteTarget, setSearch });

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withStyles(styles)(Index)
);
