import React from 'react';
import store from '../../store';
import { Route, Redirect } from 'react-router-dom';
import AppLayoutRoute from './AppLayoutRoute';
import { setIntendedUrl } from '../../helpers/auth';
import PropTypes from 'prop-types';
import { PATH_HOME, PATH_LOGIN } from './Path';
import { connect } from "react-redux";
import { setError } from "../../actions/error";
import { isAdmin } from '../../helpers/common';

const propTypes = {
  rest: PropTypes.object,
  location: PropTypes.object
};

class AdminRoute extends React.Component {
  componentDidMount() {
    this.props.setError(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.setError(null);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          const { auth: { authenticated, user } } = store.getState();

          // 未認証
          if (!authenticated) {
            // 遷移先を保存
            setIntendedUrl(props.location.pathname);
          }
          if (authenticated) {
            if (isAdmin(user)) {
              return (<AppLayoutRoute component={Component} {...props} />)
            }
            else {
              return (<Redirect
                to={{
                  pathname: PATH_HOME
                }}
              />)
            }
          }
          else {
            return (
              <Redirect
                to={{
                  pathname: PATH_LOGIN,
                  state: { from: props.location }
                }}
              />
            )
          }
        }}
      />
    );
  }
}

AdminRoute.propTypes = propTypes;
AdminRoute.displayName = 'Admin Route';

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = ({ setError });

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoute);
