import React from 'react'
import { updateTarget, getTarget } from "../../../actions/lp"
import { getDictionary } from "../../../actions/lpmaster"
import { getDictionary as getUserDictionary } from "../../../actions/user"
import {
  getTargets as getStripeList,
  setEditTarget as setEditStripe,
} from "../../../actions/stripe"
import {
  getTargets as getPayjpList,
  setEditTarget as setEditPayjp,
} from "../../../actions/payjp"
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import _ from 'lodash'
import { connect } from 'react-redux'
import { isAdmin, sleep } from "../../../helpers/common"
import { PAGE_NAME as PAGE_HOME } from "../Home"
import { PAGE_NAME as PAGE_PARENT } from "./Index"
import { validate, formRender, noneCheck } from "./Store"
import { PATH_LP, PATH_HOME } from '../../router/Path'
import { Breadcrumbs } from '@material-ui/core'
import LinkRouter from '../../router/LinkRouter'

const PAGE_NAME = '更新'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
})

class Update extends React.Component {
  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
    this.props.getDictionary({ include_public_of_other_users: 1 })
    const { id } = this.props.match.params
    const { auth: { user } } = this.props
    if (isAdmin(user)) {
      this.props.getUserDictionary()
    }
    if (id) {
      this.props.getTarget(id)
    }
    this.load = this.load.bind(this)
    this.load()
  }

  async load() {
    await this.props.getStripeList()
    await this.props.getPayjpList()
    const { stripe: { items: stripe }, payjp: { items: payjp } } = this.props
    if (!_.isEmpty(stripe)) {
      this.props.setEditStripe(stripe[0])
    }
    if (!_.isEmpty(payjp)) {
      this.props.setEditPayjp(payjp[0])
    }
  }

  async onSubmit(values) {
    const { id } = this.props.match.params
    const _values = {
      name: '',
      myasp_url: '',
      line_at_url: '',
      other_url: '',
      paypal_note: '',
      stripe_note: '',
      payjp_note: '',
      ...values
    }
    await this.props.updateTarget(id, _values, () => { this.props.history.push(PATH_LP) })
    await sleep(1000)
  }

  render() {
    const { classes, targets: { item }, dictionary, auth: { user }, stripe, payjp } = this.props
    const _item = _.cloneDeep(item)
    const stripeIsNone = noneCheck(stripe.items)
    const payjpIsNone = noneCheck(payjp.items)
    if (!_.isEmpty(_item)) {
      _item['lp_master_id'] = _item.lp_master.id
      _item['user_id'] = _item.user.id
      _item['payment_service_link_flag'] += ''
      _item['paid_notice_email_notice_mode'] = '0'
      if (!_.isEmpty(_item.paypal_product)) {
        _item['paypal_payment_url'] = _item.paypal_product.payment_url
        _item['paypal_note'] = _item.paypal_product.note
      }
      if (!_.isEmpty(_item.stripe_product)) {
        _item['stripe_api_id'] = _item.stripe_product.api_id
        _item['stripe_note'] = _item.stripe_product.note
        _item['stripe_payment_mode'] = _item.stripe_product.payment_mode + ''
      }
      if (!_.isEmpty(_item.payjp_product)) {
        _item['payjp_api_id'] = _item.payjp_product.api_id
        _item['payjp_note'] = _item.payjp_product.note
        _item['payjp_payment_mode'] = _item.payjp_product.payment_mode + ''
      }
      if (!_.isEmpty(_item.paid_notice_email)) {
        _item['paid_notice_email_notice_mode'] = _item.paid_notice_email.notice_mode + ''
        _item['paid_notice_email_from_name'] = _item.paid_notice_email.from_name
        _item['paid_notice_email_from_address'] = _item.paid_notice_email.from_address
        _item['paid_notice_email_subject'] = _item.paid_notice_email.subject
        _item['paid_notice_email_contents'] = _item.paid_notice_email.contents
      }
    }
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <LinkRouter color="inherit" to={PATH_LP}>
            {PAGE_PARENT(user)}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        {
          !_.isEmpty(dictionary) &&
          <Form
            onSubmit={this.onSubmit}
            validate={validate(stripeIsNone, payjpIsNone, dictionary, isAdmin(user))}
            initialValues={_item}
            render={formRender(classes, dictionary, isAdmin(user), stripeIsNone, payjpIsNone, true)}
          />
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  targets: state.lp, auth: state.auth, dictionary: {
    user: state.user.dictionary,
    lpmaster: state.lpmaster.dictionary,
  },
  stripe: state.stripe, payjp: state.payjp,
})
const mapDispatchToProps = ({
  getTarget, updateTarget, getDictionary, getUserDictionary,
  getStripeList, getPayjpList, setEditStripe, setEditPayjp
})

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(Update))
