
import { API_BASE } from '../constraints/commons'
import { getMyAxios } from '../helpers/auth'

export const GET_LPMASTERS = 'GET_LPMASTERS'
export const GET_LPMASTER = 'GET_LPMASTER'
export const STORE_LPMASTER = 'STORE_LPMASTER'
export const DELETE_LPMASTER = 'DELETE_LPMASTER'
export const UPDATE_LPMASTER = 'UPDATE_LPMASTER'
export const ERROR_LPMASTER = 'ERROR_LPMASTER'
export const SET_EDIT_LPMASTER = 'SET_EDIT_LPMASTER'
export const REMOVE_EDIT_LPMASTER = 'REMOVE_EDIT_LPMASTER'
export const SET_LPMASTER_SEARCH = 'SET_LPMASTER_SEARCH'
export const GET_LPMASTER_DICTIONARY = 'GET_LPMASTER_DICTIONARY'

const _getTargets = async values => {
  return await getMyAxios().get(`${API_BASE}/v1/lp/master`, { params: values })
}

export const setEditTarget = item => {
  return { type: SET_EDIT_LPMASTER, item }
}

export const removeEditTarget = () => {
  return { type: REMOVE_EDIT_LPMASTER }
}

export const getTargets = values => async dispatch => {
  try {
    const response = await _getTargets(values)
    const { data: { status, info: items, ...paginate } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_LPMASTER, error: `LPマスター一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_LPMASTERS, items, paginate })
        break
      default:
        dispatch({ type: ERROR_LPMASTER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LPMASTER, error })
  }
}

export const getTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().get(`${API_BASE}/v1/lp/master/${id}`)
    const { data: { status, info: item } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_LPMASTER, error: `LPマスター情報の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_LPMASTER, item })
        break
      default:
        dispatch({ type: ERROR_LPMASTER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LPMASTER, error })
  }
}

export const storeTarget = (values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/lp/master`, values, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 102:
        dispatch({ type: ERROR_LPMASTER, error: `LPマスターの作成に失敗しました。[${status.code}]` })
        break
      case 101:
        dispatch({ type: ERROR_LPMASTER, error: `同じLPマスター名が既に存在します。[${status.code}]` })
        break
      case 103:
        dispatch({ type: ERROR_LPMASTER, error: `Zipファイルの復号に失敗しました。[${status.code}]` })
        break
      case 104:
        dispatch({ type: ERROR_LPMASTER, error: `指定されたフォルダ及びファイル構成ではありません。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_LPMASTER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LPMASTER, error })
  }
}

export const updateTarget = (id, values, callback = () => { }) => async dispatch => {
  try {
    const response = await getMyAxios().post(`${API_BASE}/v1/lp/master/${id}`, values, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 102:
        dispatch({ type: ERROR_LPMASTER, error: `LPマスターの情報更新に失敗しました。[${status.code}]` })
        break
      case 101:
        dispatch({ type: ERROR_LPMASTER, error: `同じLPマスター名が既に存在します。[${status.code}]` })
        break
      case 103:
        dispatch({ type: ERROR_LPMASTER, error: `Zipファイルの復号に失敗しました。[${status.code}]` })
        break
      case 104:
        dispatch({ type: ERROR_LPMASTER, error: `指定されたフォルダ及びファイル構成ではありません。[${status.code}]` })
        break
      case 0:
        callback()
        break
      default:
        dispatch({ type: ERROR_LPMASTER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LPMASTER, error })
  }
}

export const deleteTarget = id => async dispatch => {
  try {
    const response = await getMyAxios().delete(`${API_BASE}/v1/lp/master/${id}`)
    const { data: { status } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 101:
        dispatch({ type: ERROR_LPMASTER, error: `LPマスターの削除に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: DELETE_LPMASTER, id })
        break
      default:
        dispatch({ type: ERROR_LPMASTER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LPMASTER, error })
  }
}

export const setSearch = (values) => async dispatch => {
  dispatch({ type: SET_LPMASTER_SEARCH, search: values })
}

export const getDictionary = (values) => async dispatch => {
  try {
    const response = await _getTargets(values)
    const { data: { status, info: items } } = response
    switch (status.code) {
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch({ type: ERROR_LPMASTER, error: `LPマスター一覧の取得に失敗しました。[${status.code}]` })
        break
      case 0:
        dispatch({ type: GET_LPMASTER_DICTIONARY, items })
        break
      default:
        dispatch({ type: ERROR_LPMASTER, error: `予期せぬエラーが発生しました。` })
        break
    }
  } catch (error) {
    dispatch({ type: ERROR_LPMASTER, error })
  }
}