import {
  GET_ANALYTICS,
  SET_ANALYTICS_SEARCH,
} from '../actions/analytics'

import moment from 'moment'

const defaultState = {
  search: {
    sort: [],
    lp_id: [],
    user_id: [],
    lp_master_id: [],
    device_type: [],
    date_from: moment().startOf('month'),
    date_to: moment().endOf('month'),
    date_unit: 'day',
  },
  paginate: {
    limit: 100,
    offset: 0,
    count: 0,
    date_unit: '',
  },
  items: [],
  total: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ANALYTICS_SEARCH:
      const { search: _search } = action
      const search = _search ?? defaultState.search
      return { ...state, search }
    case GET_ANALYTICS:
      return { ...state, paginate: action.paginate, items: action.items, total: action.total };
    default:
      return state;
  }
}