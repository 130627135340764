import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestSignOut } from '../actions/auth';
import { setOpen } from '../actions/navi';
import {
  PATH_LPMASTER,
  PATH_ADMIN_USER,
  PATH_ANALYTICS,
  PATH_HOME,
  PATH_LOGIN,
  PATH_LP,
  PATH_USER_UPDATE,
  PATH_ADMIN_OEM,
  PATH_BANK_UPDATE,
  PATH_SETTLEMENT_UPDATE,
  PATH_MAIL_TEMPLATE,
} from './router/Path';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import WebIcon from '@material-ui/icons/Web';

import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { PAGE_NAME as PAGE_HOME } from "./pages/Home";
import { PAGE_NAME as PAGE_LP } from "./pages/lp/Index";
import { PAGE_NAME as PAGE_ADMIN_USER } from "./pages/admin/user/Index";
import { PAGE_NAME as PAGE_LP_MASTER } from "./pages/lp/management/Index";
import { PAGE_NAME as PAGE_ANALYTICS } from "./pages/analytics/Index";
import { PAGE_NAME as PAGE_ADMIN_OEM } from "./pages/admin/oem/Index";
import { PAGE_NAME as PAGE_MAIL_TEMPLATE } from "./pages/mailtemplate/Index";

import ListSubheader from "@material-ui/core/ListSubheader";
import { APP_NAME } from '../constraints/commons';
import { isAdmin, isOemAdmin } from '../helpers/common';
import { Menu, MenuItem } from '@material-ui/core';

const propTypes = {
  auth: PropTypes.object.isRequired,
  requestSignOut: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export const drawerWidth = 240;
const styles = theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'break-spaces',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '0px',
  },
});

class AuthNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  componentDidMount() {
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.mainListItems = this.mainListItems.bind(this);
    this.handleMenu = this.handleMenu.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.handleEditUser = this.handleEditUser.bind(this)
    this.handleEditBank = this.handleEditBank.bind(this)
    this.handleEditSettlement = this.handleEditSettlement.bind(this)
  }

  handleLogout() {
    this.props.requestSignOut(() => this.props.history.push(PATH_LOGIN));
  }

  async handleDrawerOpen() {
    this.setState({ open: true })
    await this.props.setOpen(true)
  }

  async handleDrawerClose() {
    this.setState({ open: false })
    await this.props.setOpen(false)
  }

  mainListItems() {
    const { auth: { user } } = this.props;
    return (
      <React.Fragment>
        <List subheader={<ListSubheader>メニュー</ListSubheader>}>
          <ListItem button onClick={() => this.props.history.push(PATH_HOME)}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={PAGE_HOME} />
          </ListItem>
          <ListItem button onClick={() => this.props.history.push(PATH_LP)}>
            <ListItemIcon>
              <WebIcon />
            </ListItemIcon>
            <ListItemText primary={PAGE_LP(user)} />
          </ListItem>
          <ListItem button onClick={() => this.props.history.push(PATH_ANALYTICS)}>
            <ListItemIcon>
              <WebIcon />
            </ListItemIcon>
            <ListItemText primary={PAGE_ANALYTICS} />
          </ListItem>
        </List>
        {
          isAdmin(user) &&
          <React.Fragment>
            <List subheader={<ListSubheader>管理者メニュー</ListSubheader>}>
              <ListItem button onClick={() => this.props.history.push(PATH_ADMIN_USER)}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={PAGE_ADMIN_USER} />
              </ListItem>
              <ListItem button onClick={() => this.props.history.push(PATH_LPMASTER)}>
                <ListItemIcon>
                  <WebIcon />
                </ListItemIcon>
                <ListItemText primary={PAGE_LP_MASTER} />
              </ListItem>
              <ListItem button onClick={() => this.props.history.push(PATH_MAIL_TEMPLATE)}>
                <ListItemIcon>
                  <WebIcon />
                </ListItemIcon>
                <ListItemText primary={PAGE_MAIL_TEMPLATE} />
              </ListItem>
            </List>
          </React.Fragment>
        }
        {
          (isAdmin(user) && isOemAdmin(user)) &&
          <List subheader={<ListSubheader>OEM管理者メニュー</ListSubheader>}>
            <ListItem button onClick={() => this.props.history.push(PATH_ADMIN_OEM)}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary={PAGE_ADMIN_OEM} />
            </ListItem>
          </List>
        }
      </React.Fragment>
    );
  }

  handleMenu(e) {
    this.setState({ anchorEl: e.currentTarget })
  }

  handleMenuClose() {
    this.setState({ anchorEl: null })
  }

  handleEditUser() {
    this.handleMenuClose()
    this.props.history.push(PATH_USER_UPDATE)
  }

  handleEditBank() {
    this.handleMenuClose()
    this.props.history.push(PATH_BANK_UPDATE)
  }

  handleEditSettlement() {
    this.handleMenuClose()
    this.props.history.push(PATH_SETTLEMENT_UPDATE)
  }

  render() {
    const { classes, auth: { user } } = this.props;
    const { open, anchorEl } = this.state;
    return (
      <React.Fragment>
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              {APP_NAME}
            </Typography>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={this.handleMenuClose}
              >
                <MenuItem onClick={this.handleEditUser}>ユーザー情報変更</MenuItem>
                <MenuItem onClick={this.handleEditBank}>口座情報</MenuItem>
                {
                  isAdmin(user) &&
                  <MenuItem onClick={this.handleEditSettlement}>決済サービスAPI設定</MenuItem>
                }
                <MenuItem onClick={this.handleLogout}>ログアウト</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {this.mainListItems()}
        </Drawer>
      </React.Fragment>
    );
  }
}

AuthNav.propTypes = propTypes;

const mapDispatchToProps = { requestSignOut, setOpen };
const mapStateToProps = ({ auth }) => ({ auth });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(withRouter(withStyles(styles)(AuthNav)));
