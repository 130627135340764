import React from 'react'
import { storeTarget } from "../../../actions/mailtemplate"
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import { connect } from 'react-redux'
import { PAGE_NAME as PAGE_HOME } from "../Home"
import { PAGE_NAME as PAGE_PARENT } from "./Index"
import { PATH_HOME, PATH_MAIL_TEMPLATE } from '../../router/Path'
import { Breadcrumbs, Grid } from '@material-ui/core'
import LinkRouter from '../../router/LinkRouter'
import { REGEX_EMAIL } from '../../../constraints/commons'

const PAGE_NAME = '登録'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
})

class Store extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stored: false
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit(values) {
    await this.props.storeTarget(values, () => { this.props.history.push(PATH_MAIL_TEMPLATE) })
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <LinkRouter color="inherit" to={PATH_MAIL_TEMPLATE}>
            {PAGE_PARENT}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Form
          onSubmit={this.onSubmit}
          validate={validate()}
          render={formRender(classes)}
        />
      </React.Fragment>
    )
  }
}

export const formRender = (classes, update = false) => ({
  form, handleSubmit, pristine, submitting, invalid }) => {
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <Grid container justify="center" className={classes.root} spacing={2}>
        <Grid item container direction="column" xs md={8} spacing={1}>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label={'管理名'}
              name="name"
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="from_name"
              label="送信者名"
              id="from_name"
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="from_address"
              label="送信者のメールアドレス"
              id="from_address"
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="subject"
              label="件名"
              id="subject"
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              required
              multiline
              rows={10}
              fullWidth
              name="contents"
              label="本文"
              id="contents"
            />
          </Grid>

          <Grid item container justify="flex-end" className={classes.btnRoot} spacing={2}>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                fullWidth
                disabled={pristine || submitting}
                onClick={form.reset}
              >
                リセット
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={pristine || submitting || invalid}
              >
                登録
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export const validate = () => values => {
  const errors = {}
  const [required, email] = [true, true]
  const inputs = [
    { name: 'name', required, max: 255 },
    { name: 'from_name', max: 255 },
    { name: 'from_address', max: 255, email },
    { name: 'subject', required, max: 250 },
    { name: 'contents', required },
  ]

  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      case input.email && val && !REGEX_EMAIL.test(val):
        errors[key] = `メールアドレスを入力して下さい`
        break
      default:
        break
    }
  }

  return errors
}

const mapStateToProps = state => ({
  auth: state.auth
})
const mapDispatchToProps = ({ storeTarget })

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(Store))
