import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { requestSignOut } from "../actions/auth";
import _ from "lodash"
import store from '../store';
import { withSnackbar } from 'notistack';
import { setError } from '../actions/error';

class ErrorMessage extends Component {
  componentDidMount() {
    this.unsbscribe = store.subscribe(() => {
      const { error: { error, timeout } } = store.getState()
      const option = { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' }, autoHideDuration: 4000 }
      if (_.isString(error) && !timeout) this.props.enqueueSnackbar(error, option)
      if (timeout) this.props.enqueueSnackbar('セッションが切断されました。再度ログインしてください。', option)
      if (error) this.props.setError(null)
    })
  }

  componentWillUnmount() {
    if (this.unsbscribe) this.unsbscribe()
  }

  render() {
    return (
      <React.Fragment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ auth: state.auth, err: state.error });
const mapDispatchToProps = { requestSignOut, setError };

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withSnackbar(withRouter(ErrorMessage))
);