import {
  GET_BANKS as GET_ITEMS,
  GET_BANK as GET_ITEM,
  SET_EDIT_BANK as SET_EDIT,
  DELETE_BANK as DELETE,
  REMOVE_EDIT_BANK as REMOVE_EDIT,
  SET_BANK_SEARCH as SET_SEARCH,
  STORE_BANK as STORE,
  UPDATE_BANK as UPDATE,
} from '../actions/bank'

const defaultState = {
  search: {
    sort: [],
    user_id: [],
    bank_account_id: [],
  },
  paginate: {
    limit: 100,
    offset: 0,
    count: 0,
  },
  items: [],
  item: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EDIT:
      return { ...state, item: action.item };
    case REMOVE_EDIT:
      return { ...state, item: null };
    case GET_ITEMS:
      return { ...state, paginate: action.paginate, items: action.items };
    case GET_ITEM:
      return { ...state, item: action.item };
    case SET_SEARCH:
      const { search: _search } = action
      const search = _search ?? defaultState.search
      return { ...state, search: { ...state.search, ...search } }
    case DELETE:
      return { ...state, items: state.items.filter(v => v.id !== action.id) };
    case STORE:
    case UPDATE:
    default:
      return state;
  }
}