import React from 'react'
import { storeTarget } from "../../../../actions/lpmaster"

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Field, Form } from 'react-final-form'
import { TextField, Radios } from 'mui-rff'

import { connect } from 'react-redux'
import { PAGE_NAME as PAGE_HOME } from "../../Home"
import { PAGE_NAME as PAGE_PARENT } from "./Index"
import { PATH_LPMASTER, PATH_HOME } from '../../../router/Path'
import { Breadcrumbs, Grid } from '@material-ui/core'
import renderFile from '../../../renderFile'
import LinkRouter from '../../../router/LinkRouter'
import { isAdmin } from '../../../../helpers/common';
import Backdrop from '../../Backdrop';

const PAGE_NAME = '登録'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
  leftSpace: {
    paddingLeft: '1em'
  },
})

class Store extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      backdrop: {
        open: false
      }
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit(values) {
    const { auth: { user } } = this.props
    const formData = new FormData()
    let keys = [
      'logical_name', 'file'
    ]
    if (isAdmin(user)) {
      keys.push('scope')
    }
    for (const key of keys) {
      formData.append(key, values[key])
    }
    this.setState({ backdrop: { open: true } })
    await this.props.storeTarget(formData, () => { this.props.history.push(PATH_LPMASTER) })
  }

  render() {
    const { classes, auth: { user } } = this.props
    const { backdrop: { open } } = this.state
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <LinkRouter color="inherit" to={PATH_LPMASTER}>
            {PAGE_PARENT}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Form
          onSubmit={this.onSubmit}
          validate={validate(user)}
          render={formRender(classes, user)}
        />
        <Backdrop open={open} />
      </React.Fragment>
    )
  }
}

export const formRender = (classes, user, update=false) => ({ form, handleSubmit, pristine, submitting, invalid }) => {
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <Grid container justify="center" className={classes.root} spacing={2}>
        <Grid item container direction="column" xs md={8}>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="logical_name"
              label="LPマスター名"
              name="logical_name"
            />
          </Grid>
          {
            isAdmin(user) &&
            <Grid item xs>
              <div className={classes.radioRoot}>
                <Radios
                  label="公開範囲"
                  name="scope"
                  required
                  disabled={update}
                  radioGroupProps={{ row: true }}
                  data={[
                    { label: '自身のみ', value: '0' },
                    { label: '全体公開', value: '1' }
                  ]}
                />
              </div>
            </Grid>
          }
          <Grid item xs>
            <div className={classes.radioRoot}>
              <Field
                label="Zipファイル"
                id="file"
                name="file"
                accept="application/zip"
                required
                component={renderFile}
              />
              <Typography component="div" variant="body1" style={{ paddingTop: '12px' }}>
                Zipファイルを作成する際の注意点は
                <a href="https://docs.google.com/presentation/d/17TuOasJmBn6gXcziuEmS0FTPUcuVSpFz9d7fY8eebds/edit?usp=sharing"
                 target="_blanck" rel="noopener noreferrer">こちら</a>
              </Typography>
            </div>
          </Grid>
          <Grid item container justify="flex-end" className={classes.btnRoot} spacing={2}>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                fullWidth
                disabled={pristine || submitting}
                onClick={form.reset}
              >
                リセット
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={pristine || submitting || invalid}
              >
                登録
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const validate = user => values => {
  const errors = {}
  const [required,] = [true,]
  const inputs = [
    { name: 'logical_name', required, max: 250 },
    { name: 'file', required },
    { name: 'scope', required: isAdmin(user) },
  ]

  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      default:
        break
    }
  }
  return errors
}

const mapStateToProps = state => ({ err: state.error, auth: state.auth })
const mapDispatchToProps = ({ storeTarget })

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withStyles(styles)(Store))
