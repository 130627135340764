import React from 'react';
import store from '../../store';
import { Route, Redirect } from 'react-router-dom';
import AppLayoutRoute from './AppLayoutRoute';
import { setIntendedUrl } from '../../helpers/auth';
import PropTypes from 'prop-types';
import { PATH_LOGIN } from './Path';
import {setError} from "../../actions/error";
import {connect} from "react-redux";

const propTypes = {
  // component: PropTypes.func.isRequired,
  rest: PropTypes.object,
  location: PropTypes.object
};

class AuthRoute extends React.Component {
  componentDidMount() {
    this.props.setError(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.setError(null);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          const { auth: { authenticated } } = store.getState();

          // 未認証
          if (!authenticated) {
            // 遷移先を保存
            setIntendedUrl(props.location.pathname);
          }

          return authenticated ? (
            <AppLayoutRoute component={Component} {...props} />
          ) : (
            <Redirect
              to={{
                pathname: PATH_LOGIN,
                state: { from: props.location }
              }}
            />
          );
        }
        }
      />
    );
  }
}

AuthRoute.propTypes = propTypes;
AuthRoute.displayName = 'Auth Route';

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = ({ setError });

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
