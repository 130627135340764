import React from 'react'
import { updateTarget, getTarget } from "../../../actions/user"
import { setMe, leaveService } from "../../../actions/auth"
import { withSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import { connect } from 'react-redux'
import { REGEX_EMAIL, REGEX_PASSWORD } from '../../../constraints/commons'
import { sleep } from "../../../helpers/common"
import { PAGE_NAME as PAGE_HOME } from "../Home"
import { PATH_HOME, PATH_LEAVED } from '../../router/Path'
import { Breadcrumbs, Grid } from '@material-ui/core'
import LinkRouter from '../../router/LinkRouter'
import YesNorAlertDialog from '../../modal/YesNoAlert'

const PAGE_NAME = 'ユーザー情報更新'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnRoot: {
    flexGrow: 1
  },
  radioRoot: {
    marginTop: theme.spacing(2),
  },
})

class Update extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  componentDidMount() {
    // 非同期処理に自身をバインド
    this.onSubmit = this.onSubmit.bind(this)
    this.handleLeaveConfirm = this.handleLeaveConfirm.bind(this)
    this.handleLeave = this.handleLeave.bind(this)
  }

  async onSubmit(values) {
    const { auth: { user } } = this.props
    values['authority'] = user.authority
    values['verify_email_send'] = 1
    values['info_email_send'] = 1
    await this.props.updateTarget(user.id, values, () => {
      this.props.setMe(() => {})
      const option = { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } }
      this.props.enqueueSnackbar('更新しました', option)
    })
    await sleep(1000)
  }

  handleLeaveConfirm(leave) {
    if (leave) {
      const { auth: { user: { id } } } = this.props
      const values = { id, email_send: 1 }
      this.props.history.push(PATH_LEAVED)
      this.props.leaveService(values)
    }
    this.setState({ open: false })
  }

  handleLeave() {
    this.setState({ open: true })
  }

  render() {
    const { classes, auth: { user } } = this.props
    let item = {}
    if (user && 'email' in user) {
      item = { email: user['email'], first_name: user['first_name'], last_name: user['last_name'] }
    }
    const { open } = this.state
    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter color="inherit" to={PATH_HOME}>
            {PAGE_HOME}
          </LinkRouter>
          <Typography color="primary">{PAGE_NAME}</Typography>
        </Breadcrumbs>
        <Form
          onSubmit={this.onSubmit}
          validate={validate}
          initialValues={item}
          render={formRender(classes, { leave: this.handleLeave })}
        />
        <YesNorAlertDialog
          open={open}
          title="退会の確認"
          message="本当に退会しますか？"
          handleClose={this.handleLeaveConfirm}
        />
      </React.Fragment>
    )
  }
}

const formRender = (classes, props) => ({ form, handleSubmit, pristine, submitting, invalid }) => {
  return (
    <form method="post" className={classes.form} onSubmit={handleSubmit}>
      <Grid container justify="center" className={classes.root} spacing={2}>
        <Grid item container direction="column" xs md={8}>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="メールアドレス"
              name="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="last_name"
                label="姓"
                name="last_name"
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="first_name"
                label="名"
                name="first_name"
              />
            </Grid>
          </Grid>
          <Grid item container justify="space-around" className={classes.btnRoot} spacing={2}>
            <Grid item container justify="flex-start" spacing={2} xs>
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  onClick={props.leave}
                >
                  退会する
                </Button>
              </Grid>
            </Grid>
            <Grid item container justify="flex-end" spacing={2} xs>
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={pristine || submitting}
                  onClick={form.reset}
                >
                  リセット
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={pristine || submitting || invalid}
                >
                  保存
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const validate = values => {
  const errors = {}
  const [email, password] = [true, true]
  const inputs = [
    { name: 'email', email },
    { name: 'password', password },
    { name: 'first_name', max: 100 },
    { name: 'last_name', max: 100 },
  ]
  for (const input of inputs) {
    const key = input.name
    const val = values[key]
    switch (true) {
      case input.required && !val:
        errors[key] = '必須項目を入力して下さい'
        break
      case input.email && val && !REGEX_EMAIL.test(val):
        errors[key] = '不正なメールアドレスです'
        break
      case input.password && val && !REGEX_PASSWORD.test(val):
        errors[key] = '8文字以上の英数字で記号は、アンダースコア(_)、ハイフン(-)、ドット(.)、アットマーク(@)のみ使用できます'
        break
      case input.max && val && val.length > input.max:
        errors[key] = `${input.max}文字以下で入力して下さい`
        break
      default:
        break
    }
  }

  return errors
}

const mapStateToProps = state => ({ auth: state.auth, err: state.error })
const mapDispatchToProps = ({ getTarget, updateTarget, setMe, leaveService })

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withSnackbar(withStyles(styles)(Update))
)
