import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Grid } from '@material-ui/core';

const styles = theme => ({
  input: {
    display: 'none',
  },
  button: {
    marginTop: 10,
  },
});

const renderFile = props => {
  const {
    input: { value, name, onChange },
    label,
    meta: { error },
    classes,
    onFieldChange,
    buttonLabel,
    accept = '*',
    required = false,
    rootClass = '',
  } = props;
  return (
    <FormControl classes={{ root: rootClass }} required={required} component='fieldset' error={!!error}>
      <FormLabel component='legend'>
        {label}
      </FormLabel>
      <Grid container alignItems="center" spacing={3}>
        <Grid item>
          <label htmlFor={name}>
            <Button classes={{ root: classes.button }} variant='outlined' component='span'>
              {buttonLabel || 'ファイルを選択'}
            </Button>
          </label>
        </Grid>
        <Grid item>
          <div className={classes.button}>
            {value && value.name}
          </div>
        </Grid>
      </Grid>
      {!!error && <FormHelperText>{error}</FormHelperText>}
      <input
        accept={accept}
        className={classes.input}
        id={name}
        type='file'
        onChange={e => {
          e.preventDefault()
          onChange(e.target.files[0])
          onFieldChange && onFieldChange(e.target.files[0])
        }}
        onBlur={() => { }}
      />
    </FormControl>
  );
};

renderFile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(renderFile);
