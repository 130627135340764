import React from 'react';
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/ja";

moment.locale('ja')

class ExtendedUtils extends MomentUtils {
  getCalendarHeaderText(date) {
    return date.format("YYYY年MMM");
  }
  getDatePickerHeaderText(date) {
    return date.format("MMMDo");
  }
}

export default props => {
  const { input, meta: { touched, error }, dateFormat, ...custom } = props;
  return (
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale="ja" libInstance={moment}>
      <DatePicker
        {...input}
        {...custom}
        error={!!(touched && error)}
        helperText={!!(touched && error) && error}
        format={dateFormat}
      />
    </MuiPickersUtilsProvider>
  );
};

